import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { AuthService } from '../services/auth.service';
import { UserManagerService, CompaniesService } from '../services';
import { take } from 'rxjs/operators';
import { StorageService } from '../services';
import { environment } from '../../../environments/environment';

@Injectable()
export class IsAuthenticatedGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
    private userManagerService: UserManagerService,
    private storage: StorageService,
    private companiesService: CompaniesService,
  ) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.authService.isAuthenticated() && !!this.storage.getUser() && !!this.storage.getCompany()) {
      this.userManagerService.getMe()
        .pipe(take(1))
        .subscribe(user => {
          const mokUrl: string = '/privateFeedback/vacancies/5e710e038721fa0e787b5482/list-candidates/5e7236e60c2fab08dfe839b7/filters/5e7cd9cb0664cd15e8b535fa/feedback-by-step?token=47C17F9OOSGARQNCHB52E8CBCD27637720WPFAE6CE10A1953FA6A8A659994635';
          if (this.storage.getUser().isSantanderFeedBack && String(this.router.url) !== String(mokUrl)) {
            window.location.replace(`${environment.web_link}${mokUrl}`);
          }

          if (environment.production && (<any>window).Conpass) {
            (<any>window).Conpass.init({
              name: `${user.firstName} ${user.lastName}`,
              email: user.email,
              custom_fields: {
                role: user.role,
                company: this.storage.getCompany().fantasyName,
              }
            });
          }
        });
      return true;
    }

    this.router.navigate(['/auth/signin']);
    return false;
  }
}
