import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Company } from '../../core/models';

@Injectable({
  providedIn: 'root'
})
export class ShowcaseService {

  constructor () {}
  private showcase = new BehaviorSubject<any>({});
  showcase$ = this.showcase.asObservable();
  private showInvites = new BehaviorSubject<boolean>(true);
  showInvites$  = this.showInvites.asObservable();

  setShowcase(showcase) {
    return this.showcase.next(showcase);
  }

  showInvitesStatus(enable = true) {
    return this.showInvites.next(enable);
  }
}
