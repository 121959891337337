export class UserManager {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  gender: string;
  company: any;
  area: string;
  groupVacancyAccess: any;
  isTalentBase?: boolean;
  location: string;
  responsibility: string;
  active: boolean;
  isSantanderFeedBack: boolean;
  role: string;
  invitation: {
    by: UserManager;
    date: Date;
    pending: boolean;
    token: string
  };
  resetPassword: boolean;

  // Taqe-Lite
  primaryPhone: string;
  cpf: String;
  // --
  
  get status(): string {
    if (this.active) {
      return 'active';
    }

    if (!this.invitation) {
      return 'not_invited';
    }

    if (this.invitation && this.invitation.pending) {
      return 'pending';
    }

    return 'inactive';
  }
}
