import { MapsAPILoader } from '@agm/core';
import { Component, ElementRef, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxHotjarService } from 'ngx-hotjar';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, mergeMap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { Step } from '../../../core/models';
import { CleanObjectService, CompaniesService, StorageService } from '../../../core/services';
import { VacanciesService } from '../../vacancies.service';
import { VacancyCandidatesService } from '../../vacancy-candidates.service';
import { spaceValidator } from '../create-event/create-event.component';

@Component({
  selector: 'app-create-event-send-invitation-modal',
  templateUrl: './create-event-send-invitation-modal.component.html',
  styleUrls: ['./create-event-send-invitation-modal.component.scss']
})
export class CreateEventSendInvitationModalComponent implements OnInit {

  @ViewChild('searchLocation') public searchElement: ElementRef;
  @Input() vacancyId;
  @Input() showcaseSchedulingStep: Step;
  @Input() previousStepId;
  @Input() nextStepId;
  @Input() vacancyCandidateIds;
  @Input() filters;

  public form: FormGroup;
  public userManager: any;
  public eventObj: any;
  public currentDate = new Date();
  public locationInformationByGoogle: google.maps.places.PlaceResult;
  public locationImgPreviewUrl: string;
  public longitude: number = null;
  public latitude: number = null;
  public zoom: number;
  public listenChanges: Subscription;
  public dateObject: any = {};
  public submitted: boolean = false;

  private isCreatingOrUpdating: boolean = false;

  quantityWhatsappNotificationsAvailable: number = 0;
  canSendWhatsappNotifications: boolean = false;

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private storage: StorageService,
    protected $hotjar: NgxHotjarService,
    private router: Router,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private toastrService: ToastrService,
    private vacanciesService: VacanciesService,
    private vacancyCandidatesService: VacancyCandidatesService,
    private companiesService: CompaniesService,
    private clearService: CleanObjectService,
  ) { }

  ngOnInit() {
    this.$hotjar.virtualPageView(`${environment.web_link}${this.router.url}`);

    this.userManager = this.storage.getUser();
    this.initForm();
    this.setStepId();
    this.filterScheduleCreateByCompany();
    this.changeRequiredStatus('online');

    this.verifyWppNotification();
  }

  private initForm() {
    const baseFields: any = {
      title: [null, [Validators.required, spaceValidator]],
      location: this.formBuilder.group({
        name: ['', Validators.nullValidator],
        address: this.formBuilder.group({
          formatted_address: [null, Validators.nullValidator],
          geometry: this.formBuilder.group({
            lat: [null, Validators.nullValidator],
            lng: [null, Validators.nullValidator],
          }),
        }),
      }),
      scheduleBaseId: [null, Validators.nullValidator],
      stepId: [null, Validators.required],
      responsible: [null, [Validators.required, spaceValidator]],
      responsibleEmail: [null, [Validators.compose([Validators.required, Validators.email]), spaceValidator]],
      maxCandidatesCount: [1, Validators.nullValidator],
      date: [null, Validators.required],
      start: [null, Validators.required],
      end: [null, Validators.required],
      interviewType: ['individualInterview', Validators.required],
      linkInvite: [null, Validators.nullValidator],
      type: ['online', Validators.required],
      description: [null, Validators.nullValidator],
      company: [this.userManager.company, Validators.required],
      sendWhatsappNotification: [{value: true, disabled: false}, Validators.nullValidator]
    };

    // Etapa: Cria o formulário.
    this.form = this.formBuilder.group(baseFields);
  }

  private setStepId() {
    if (this.showcaseSchedulingStep && this.showcaseSchedulingStep._id) {
      this.form.get('stepId').setValue(this.showcaseSchedulingStep._id);
    }
  }

  private filterScheduleCreateByCompany() {
    if (this.showcaseSchedulingStep.schedules && this.showcaseSchedulingStep.schedules.length) {
      this.showcaseSchedulingStep.schedules = this.showcaseSchedulingStep.schedules
        .filter((schedule: any) => String(schedule.active) && String(schedule.company) === String(this.userManager.company));
    }
  }

  private fillForm(): void {
    this.form.patchValue(this.eventObj.extendedProps);
    this.changeRequiredStatus(this.form.get('type').value);

    this.form.get('title').patchValue(this.eventObj.title);
    if (this.form.get('type').value === 'presencial') {
      this.latitude = this.form.get('location.address.geometry.lat').value ? this.form.get('location.address.geometry.lat').value : this.eventObj.extendedProps.location.address.geometry.lat;
      this.longitude = this.form.get('location.address.geometry.lng').value ? this.form.get('location.address.geometry.lng').value : this.eventObj.extendedProps.location.address.geometry.lng;
    }

    this.form.get('start').patchValue({
      hour: new Date(this.eventObj.start).getHours(),
      minute: new Date(this.eventObj.start).getMinutes()
    });

    this.form.get('end').patchValue({
      hour: new Date(this.eventObj.end).getHours(),
      minute: new Date(this.eventObj.end).getMinutes()
    });

    this.form.get('date').patchValue(this.eventObj.start);

    this.dateObject = {
      year: new Date(this.eventObj.start).getUTCFullYear(),
      month: new Date(this.eventObj.start).getUTCMonth() + 1,
      day: new Date(this.eventObj.start).getUTCDate()
    };

    this.dateChange({ value: this.eventObj.start });
  }

  public dateChange(e): void {
    this.dateObject = {
      year: new Date(e.value).getUTCFullYear(),
      month: new Date(e.value).getUTCMonth() + 1,
      day: new Date(e.value).getUTCDate()
    };
  }

  private removeControl(params): void {
    Array.prototype.forEach.call(arguments, argument => {
      if (this.form.get(argument)) {
        this.form.removeControl(argument);
      }
    });
  }

  public changeRequiredStatus(event: string): void {
    if (event === 'online') {
      this.removeControl('location');
    } else {
      this.form.addControl('location', this.formBuilder.group({
        name: ['', Validators.nullValidator],
        address: this.formBuilder.group({
          formatted_address: [null, Validators.required],
          geometry: this.formBuilder.group({
            lat: [null, Validators.required],
            lng: [null, Validators.required],
          }),
        }),
      }));

      this.form.get('location.address.formatted_address').updateValueAndValidity();
      this.form.get('location.address.geometry.lat').updateValueAndValidity();
      this.form.get('location.address.geometry.lng').updateValueAndValidity();

      setTimeout(() => {
        this.loadMap();
      }, 1000);
    }
  }

  private loadMap(): void {
    if (!this.searchElement || !this.searchElement.nativeElement) {
      return;
    }

    this.mapsAPILoader.load()
      .then(() => {
        const autocomplete = new google.maps.places.Autocomplete(this.searchElement.nativeElement, {
          componentRestrictions: {
            country: 'br'
          }
        });

        autocomplete.addListener('place_changed', () => {
          this.ngZone.run(() => {
            this.locationInformationByGoogle = autocomplete.getPlace();
            this.locationImgPreviewUrl = 'https://maps.googleapis.com/maps/api/staticmap?zoom=12&size=600x300&markers=color:red|' +
              this.locationInformationByGoogle.formatted_address + '&key=' + environment.MAPS_API_KEY;
            this.form.get('location.address.formatted_address').patchValue(autocomplete.getPlace().formatted_address);

            this.latitude = autocomplete.getPlace().geometry.location.lat();
            this.longitude = autocomplete.getPlace().geometry.location.lng();
            this.zoom = 12;

            if (!autocomplete.getPlace().geometry) {
              this.toastrService.clear();
              this.toastrService.warning('Este endereço não possui uma geolocalização bem definida no Google Maps, o que pode confundir o candidato no dia do agendamento.', 'Atenção');
            }

            this.form.get('location.address.geometry.lat').patchValue(this.latitude);
            this.form.get('location.address.geometry.lng').patchValue(this.longitude);
          });
        });
      });
  }

  public close(): void {
    this.activeModal.close();
  }

  public useScheduleBase(scheduleId: String): void {
    const schedule = JSON.parse(JSON.stringify(this.showcaseSchedulingStep.schedules.find((schedule: any) => String(schedule._id) === String(scheduleId))));
    delete schedule._id;
    delete schedule.scheduleBaseId;

    this.eventObj = JSON.parse(JSON.stringify(schedule));
    this.eventObj.newEvent = true;
    this.eventObj.extendedProps = this.eventObj;
    this.fillForm();
  };

  public clearLocation(): void {
    delete this.locationInformationByGoogle;
    delete this.locationImgPreviewUrl;

    if (!this.form.get('location.address.formatted_address').value) {
      this.form.get('location.address.geometry.lat').patchValue(null);
      this.form.get('location.address.geometry.lng').patchValue(null);
    }
  }

  public addEventAndSendInvite() {
    if (this.form.valid) {
      if (this.form.get('interviewType').value == 'individualInterview') {
        this.form.get('maxCandidatesCount').patchValue(1);
      }

      let scheduleForm = this.form.value;
      scheduleForm.date = this.dateObject;

      if (this.isCreatingOrUpdating) {
        return;
      }

      this.isCreatingOrUpdating = true;

      this.vacanciesService.createSchedule(this.vacancyId, this.form.value.stepId, { schedule: this.clearService.getCleanedAttribute(scheduleForm) })
        .pipe(
          mergeMap(result => {
            if (result && result._id) {
              const params = {
                previousStepId: this.previousStepId,
                nextStepId: this.nextStepId,
                observations: '',
                notificate: true,
                vacancyCandidateIds: this.vacancyCandidateIds,
                filters: this.filters,
                vacancyId: this.vacancyId,
                scheduleId: result._id,
                sendWhatsappNotification: this.form.get('sendWhatsappNotification').value,
              };
              return this.vacancyCandidatesService.bulkApproveVacancyCandidateNextStep(params);
            }
          })
        )
        .subscribe(response => {
          if (response) {
            this.toastrService.clear();
            this.toastrService.success('Candidato foi convidado para a próxima etapa', 'Candidato convidado com sucesso!');
            this.activeModal.close(response);
          }
        }, error => {
          this.toastrService.clear();
          this.toastrService.error('Não foi possível convidar o candidato', 'Ocorreu um erro!');
          this.close();
        });
    } else {
      this.submitted = true;
      this.toastrService.clear();
      this.toastrService.error('Erro no formulário!', 'Preencha os campos obrigatórios!');
    }
  }

  private verifyWppNotification() {
    const showcase = this.storage.getShowCase();
    this.companiesService.getCompanyWhatsappNotificationsQuantity({
      showcase: showcase._id
    }).subscribe(company => {
      const value = company.whatsappNotifications.total;
      this.canSendWhatsappNotifications = company.canSendWhatsappNotifications || false;
      this.quantityWhatsappNotificationsAvailable = company.canSendWhatsappNotifications ? value : 0;
      if(!(this.vacancyCandidateIds && this.vacancyCandidateIds.length <= value) || this.quantityWhatsappNotificationsAvailable === 0) {
        this.form.get('sendWhatsappNotification').disable();
        this.form.get('sendWhatsappNotification').setValue(false);
      }
    });
  }

}
