import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class HeadingService {

  constructor(private route: ActivatedRoute,) { }

  get(setHeading: string) {
    return this.route.data
      .subscribe((data: { heading: any }) => {
        // data.heading = {
        //   title: 'Vagas | ' + title
        // }
      });
  }

  // get setHeading(title: string){
  //   return this.route.data
  //   .subscribe((data: { heading: any }) => {
      // data.heading = {
      //   title: 'Vagas | ' + title
      // }
  //   });
  // }
}
