import { Component, ElementRef, NgZone, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { StorageService } from '../services/storage.service';
import { UserManager } from '../models/user-manager';
import { Company } from '../models/company';
import { environment } from '../../../environments/environment';
import { ShowcaseService } from '../../showcase/services/showcase.service';

const SMALL_WIDTH_BREAKPOINT = 991;

export interface Options {
  heading?: Record<string, string>;
  removeFooter?: boolean;
}

@Component({
  selector: 'app-admin',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss']
})
export class AdminLayoutComponent implements OnInit, OnDestroy {
  public cards: any = [];
  private managerType: string = environment.manager_type;

  private _router: Subscription;
  private mediaMatcher: MediaQueryList = matchMedia(`(max-width: ${SMALL_WIDTH_BREAKPOINT}px)`);
  private previousUrl: String = '';
  routeOptions: Options;

  options = {
    lang: 'pt-br',
    theme: 'winter',
    settings: false,
    docked: true,
    boxed: false,
    opened: false
  };

  currentLang = 'pt-br';
  user: UserManager;
  company: Company;

  public colors: any = {
    mainColor: '#5128e2',
    secondaryColor: '#00ffa2'
  };
  logo: string = '';

  @ViewChild('sidebar') sidebar;
  @ViewChild('sidemenu') sidemenu;

  constructor(
    private _element: ElementRef,
    private router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService,
    private titleService: Title,
    private storage: StorageService,
    private showcaseService: ShowcaseService
  ) {
  }

  ngOnInit(): void {
    this._router = this.router.events.
      pipe(
        filter(event => event instanceof NavigationEnd)
      )
      .subscribe((event: NavigationEnd) => {

        const navigatingToSameURL = this.previousUrl.split('?')[0] === event.urlAfterRedirects.split('?')[0];
        if (!navigatingToSameURL) {
          // Scroll to top on view load
          document.querySelector('.main-content').scrollTop = 0;
        }

        this.previousUrl = event.urlAfterRedirects;

        if (this.isOver()) {
          this.sidemenu.close();
        }
        this.runOnRouteChange();
      });

    this.runOnRouteChange();
    this.loadUser();
    this.loadCompany();
  }

  ngAfterContentInit() {
    this.showcaseService.showcase$.subscribe(showcase => {
      if (showcase && showcase.vitrinePage) {
        if (showcase.vitrinePage.colors) {
          this.colors.mainColor = showcase.vitrinePage.colors.mainColor || '#5128e2';
          this.colors.secondaryColor = showcase.vitrinePage.colors.secondaryColor || '#00ffa2';
        }

        if (showcase.vitrinePage.logo) {
          this.logo = showcase.vitrinePage.logo;
        } else if (showcase.logo) {
          this.logo = showcase.logo;
        } else {
          this.logo = '';
        }
      } else {
        this.colors.mainColor = '#5128e2';
        this.colors.secondaryColor = '#00ffa2';
        this.logo = '';
      }
    });
  }

  ngOnDestroy() {
    this._router.unsubscribe();
  }

  runOnRouteChange(): void {
    this.route.children.forEach((route: ActivatedRoute) => {
      let activeRoute: ActivatedRoute = route;
      while (activeRoute.firstChild) {
        activeRoute = activeRoute.firstChild;
      }
      this.routeOptions = activeRoute.snapshot ? activeRoute.snapshot.data : {};
    });

    if (this.routeOptions.hasOwnProperty('heading')) {
      this.setTitle(this.routeOptions.heading.title);
    }
  }

  setTitle(newTitle: string): void {
    this.titleService.setTitle(`${this.managerType.toUpperCase()} - Vitrine | ${newTitle}`);
  }

  isOver(): boolean {
    return this.mediaMatcher.matches;
  }

  receiveMessage($event) {
    this.options = $event;
  }

  toggleFullscreen(): void {
    const elem = this._element.nativeElement.querySelector('.main-content');
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullScreen) {
      elem.webkitRequestFullScreen();
    } else if (elem.mozRequestFullScreen) {
      elem.mozRequestFullScreen();
    } else if (elem.msRequestFullScreen) {
      elem.msRequestFullScreen();
    }
  }

  loadUser() {
    this.user = this.storage.getUser();
  }

  loadCompany() {
    this.company = this.storage.getCompany();
  }
}
