import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { StorageService, UserManagerService } from '../services';
import { ShowcaseService } from '../../showcase/services/showcase.service';
import { HeaderLiteService } from '../../core/header-lite/header-lite.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  @Input() secondaryColor: string;

  public manager_type: string = environment.manager_type as string;
  public role: string = '' as string;
  public testModuleActive: boolean = false as boolean;
  public companyId: string = '' as string;
  public canCreateEducationCourse: boolean = false as boolean;
  public canCreateShowcase: boolean = false as boolean;
  public canCreateCertifications: boolean = false as boolean;
  public token: string = '' as string;

  public expandedConfig: boolean = false;

  constructor(
    private storage: StorageService,
    private userManagerService: UserManagerService,
    private router: Router,
    private showcaseService: ShowcaseService,
    private headerLiteService: HeaderLiteService,
  ) { }

  ngOnInit() {
    this.userManagerService.getMe()
      .subscribe(responseUser => {
        this.role = responseUser.role;
      });
  }

  goToProcess(process: string): void {
    this.showcaseService.setShowcase('');
    this.storage.setShowCase('');
    this.headerLiteService.setResourceBase('');
    this.router.navigate([`/${process}`]);
  }
}
