import { Pipe, PipeTransform } from '@angular/core';

import CONSTANTS from '../constants';

@Pipe({
  name: 'hearingLossEnum'
})
export class HearingLossEnumPipe implements PipeTransform {
  HEARING_LOSS_ENUM = CONSTANTS.HEARING_LOSS_ENUM;

  transform(hearingLossEnum: String): any {
    if (!hearingLossEnum) {
      return hearingLossEnum;
    }

    return this.HEARING_LOSS_ENUM.find(element => element.key === hearingLossEnum).value;
  }
}
