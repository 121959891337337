export class Unit {
    _id: string;
    name: string;
    address: {
        street: string;
        number?: string;
        complement?: string;
        district: string;
        city: string;
        state: string;
        zipCode: string;
        country: string;
        referencePoint?: string;
    };
    location: [number];
    company: string;
}
