import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CleanObjectService {

  getCleanedAttribute(data) {
    function clearRecursively(object) {
      Object.keys(object).forEach(key => {
        if (object[key] instanceof Object) {
          object[key] = clearRecursively(object[key]);

          if (!Object.keys(object[key]).length) {
            delete object[key];
            return;
          }
        }

        if (!object[key]) {
          if (key === 'workHere' && object[key] === false)
            return;
          delete object[key];
          return;
        }
      });
      return object;
    }

    data = JSON.parse(JSON.stringify(data));
    return clearRecursively(data);
  }
}
