import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { Vacancy, VacancyCandidate } from '../../core/models';
import CustomEncoder from '../../core/helpers/custom-encoder';

@Injectable()
export class PublicService {
  url: string = environment.url;

  constructor(
    private http: HttpClient
  ) { }

  getCandidatesBySchedule(vacancyId, scheduleId, token): Observable<any> {
    let params = {
      token,
    }

    let httpParams = new HttpParams({ encoder: new CustomEncoder() });

    for (const key of Object.keys(params)) {
      httpParams = httpParams.append(key, params[key] instanceof Object ? JSON.stringify(params[key]) : params[key]);
    }

    // const httpParams = new HttpParams().append('token', token);
    return this.http.get<any>(`${this.url}/public/vacancies/${vacancyId}/schedules/${scheduleId}/candidates`, { params: httpParams });
  }

  getSchedule(vacancyId, scheduleId, token): Observable<any> {
    const httpParams = new HttpParams().append('token', token);
    return this.http.get<any>(`${this.url}/public/vacancies/${vacancyId}/schedules/${scheduleId}`, { params: httpParams });
  }

  setScheduleCandidateFeedback(vacancyId, scheduleId, candidateId, params = {}, token) {
    return this.http.post<any>(`${this.url}/public/vacancies/${vacancyId}/schedules/${scheduleId}/candidates/${candidateId}?token=${token}`,
      params);
  }

  getVacancyById(vacancyId, token): Observable<Vacancy> {
    const httpParams = new HttpParams().append('token', token);
    return this.http.get<Vacancy>(`${this.url}/public/vacancies/${vacancyId}`, { params: httpParams });
  }

  getVacancyCandidate(vacancyCandidateId, token): Observable<VacancyCandidate> {
    const httpParams = new HttpParams().append('token', token);
    return this.http.get<VacancyCandidate>(`${this.url}/public/vacancy-candidates/${vacancyCandidateId}`, { params: httpParams });
  }

  resetPassword(params = {}, token): Observable<any> {
    return this.http.post<any>(`${this.url}/public/user-managers/reset-password?token=${token}`, params);
  }

  getVacancyCandidatesFeedbackTests(vacancyId, testId, stepId, pagination, token) {
    let params = {
      pagination
    }

    let httpParams = new HttpParams({ encoder: new CustomEncoder() })
      .append('token', token);

    for (const key of Object.keys(params)) {
      httpParams = httpParams.append(key, params[key] instanceof Object ? JSON.stringify(params[key]) : params[key]);
    }

    return this.http.get<any>(`${this.url}/public/vacancies/${vacancyId}/feedback/tests/${stepId}/${testId}`, {
      params: httpParams
    });
  }

  // Feedback para candidatos.
  getVacancyCandidates(vacancyId, stepId, filterId, token, filter, candidates, pagination): Observable<any> {
    let params = {
      token: token,
      feedbackFilters: filter,
      pagination: pagination,
      candidates: candidates
    }

    let httpParams = new HttpParams({ encoder: new CustomEncoder() });

    for (const key of Object.keys(params)) {
      httpParams = httpParams.append(key, params[key] instanceof Object ? JSON.stringify(params[key]) : params[key]);
    }

    // httpParams = HttpParams().append('filter', filter);
    // httpParams = HttpParams().append('pagination', pagination);
    return this.http.get<any>(`${this.url}/public/vacancies/${vacancyId}/steps/${stepId}/filters/${filterId}/vacancy-candidates`, { params: httpParams});
  }

  postVacancyCandidateFeedback(vacancyId, stepId, filterId, candidateId, params = {}, token) {
    return this.http.post<any>(`${this.url}/public/vacancies/${vacancyId}/steps/${stepId}/filters/${filterId}/vacancy-candidates/${candidateId}?token=${token}`, params);
  }

  getVacancyCandidateRIStatus(vacancyId, userId, vacancyCandidateId) {
    return this.http.get<any>(`${this.url}/public/vacancy-candidate/internal-recruitment/${vacancyId}/${userId}/${vacancyCandidateId}/`);
  }

  postVacancyCandidateRIStatus(vacancyId, userId, vacancyCandidateId, status, params = {}) {
    return this.http.post<any>(`${this.url}/public/vacancy-candidate/internal-recruitment/${vacancyId}/${userId}/${vacancyCandidateId}/${status}`, params);
  }
}
