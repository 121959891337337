import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'translateNotification'
})
export class TranslateNotificationPipe implements PipeTransform {

  translatedNotifications = {
    sendInvitation: 'Enviar Convite',
    sendAlertContact: 'Enviar alerta de contato',
    dispenseCandidate: 'Dispensar Candidato',
    returnCandidateToProcess: 'Retornar candidato ao processo',
    intermediate: 'Etapa Aprovada - Intermediário',
    documentation: 'Etapa Aprovada - Documentação',
    scheduling: 'Etapa Aprovada - Agendamento',
    redacaoOnline: 'Etapa Aprovada - Redação',
    video: 'Etapa Aprovada - Vídeo',
    approved: 'Etapa Aprovada -  Aprovação',
    comeBackToPreviousStep: 'Voltar para etapa anteior',
    essayFeedback: 'Redação Corrigida',
    closeVacancy: 'Processo Seletivo Encerrado',
    cancelVacancy: 'Cancelamento de Vaga'
  };


  translatedNotificationsType = {
    aplication: 'Aplicativo',
    email: 'E-mail',
    sms: 'Sms'
  };

  transform(value: string): string {
    if (!this.translatedNotifications[value]) {
      return this.translatedNotificationsType[value];
    }
    return this.translatedNotifications[value];
  }

}
