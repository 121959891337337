import { Pipe, PipeTransform } from '@angular/core';
import { Vacancy } from '../../core/models';

@Pipe({
  name: 'candidateStep'
})
export class CandidateStepPipe implements PipeTransform {

  transform(steps: any, stepId: string): any {
    return steps.find(step => step._id === stepId).name;
  }
}
