import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from "@angular/router";
import { NgxHotjarService } from 'ngx-hotjar';
import { NgxRolesService } from "ngx-permissions";
import { ToastrService } from "ngx-toastr";
import { Subject, Subscription } from 'rxjs';
import { take } from "rxjs/operators";
import { environment } from '../../../environments/environment';
import { PublicService } from "../../public/services/public.service";
import { Company } from "../models/company";
import { AuthService, CompaniesService, StorageService, UserManagerService } from "../services";
import { HeaderLiteService } from "./header-lite.service";
import { ShowcaseService } from '../../showcase/services/showcase.service';


declare function hidden_button(): any;
@Component({
  selector: "app-header-lite",
  templateUrl: "./header-lite.component.html",
  styleUrls: ["./header-lite.component.scss"]
})

export class HeaderLiteComponent implements OnInit, OnDestroy, OnChanges {

  constructor(
    private router: Router,
    private authService: AuthService,
    private storageService: StorageService,
    private headerLiteService: HeaderLiteService,
    protected $hotjar: NgxHotjarService,
    private showcaseService: ShowcaseService,
  ) { }

  @Input() user: any;
  @Input() company: Company;
  @Input() cards: [];
  @Input() heading: any = { title: '', subtitle: '' };
  plans: any = [];
  @Output() toggleSidenav = new EventEmitter<void>();
  urlImg: string;
  logo$: Subscription;
  // cards$: Subscription;
  resourceBase$: Subscription;
  userManager$: Subscription;
  // plan$: Subscription;
  // currentPlan: any = null;
  public destroyed = new Subject<any>();
  public token: String = 'f86f4d231c23c842b1faeb5a031298b66bf5a065cf4307f94fbe30c7562f8486eb27a30ce1f45a19793356d494adc8f222370a289f618c1eb0f27d251536176e90ab727dbab086249cc179c3489d561a8da427664191856a235bdca1491d825e0933adbf';

  textColor: string = '#96A6B4';
  colors = {
    mainColor: '#5128e2',
    secondaryColor: '#00ffa2'
  };
  viewInvite: boolean = false;

  ngOnInit() {
    this.$hotjar.virtualPageView(`${environment.web_link}${this.router.url}`);

    this.resourceBase$ = this.headerLiteService.onResourceBase()
      .subscribe(resourceBase => {
        this.company.resourceBase = resourceBase;

        if (!resourceBase) {
          this.viewInvite = false;
        } else {
          this.viewInvite = true;
        }
      });

    this.showcaseService.showInvites$.subscribe(status => {
      this.viewInvite = status;
    });

    this.userManager$ = this.headerLiteService.onUser()
      .subscribe(user => {
        this.user = user;
      });

    // this.plan$ = this.headerLiteService.onPlan()
    //   .subscribe(plan => {
    //     this.currentPlan = plan;
    //   });

    // this.cards$ = this.headerLiteService.onCards()
    //   .subscribe(cards => {
    //     this.cards = cards;
    //   });

    this.loadIcon();

    this.logo$ = this.headerLiteService.onChangeLogo()
      .subscribe(logo => {
        this.company.logo = logo;
      });
  }

  ngAfterContentInit() {
    this.showcaseService.showcase$.subscribe(showcase => {
      if (Object.keys(showcase).length === 0) {
        showcase = this.storageService.getShowCase();
      }
      if (showcase && showcase.vitrinePage) {
        if (showcase.vitrinePage.colors) {
          if (showcase.vitrinePage.colors.mainColor) {
            this.colors.mainColor = showcase.vitrinePage.colors.mainColor;
          }
          if (showcase.vitrinePage.colors.secondaryColor) {
            this.colors.secondaryColor = showcase.vitrinePage.colors.secondaryColor;
          }
        }
      }
    });
  }

  ngOnChanges() {
  }

  ngOnDestroy() {
    this.logo$.unsubscribe();

    if (this.resourceBase$) {
      this.resourceBase$.unsubscribe();
      delete this.resourceBase$;
    }

    // if (this.cards$) {
    //   this.cards$.unsubscribe();
    //   delete this.cards$;
    // }

    this.destroyed.next();
    this.destroyed.complete();
  }

  loadIcon() {
    const baseUrl = './../../core/menu/icons/';
    this.urlImg = baseUrl + 'empresa';
  }

  logout() {
    this.authService
      .logout()
      .subscribe(response => {
        hidden_button();
        this.router.navigate(['/auth/signin']);
      });
  }
}
