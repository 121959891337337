import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-send-email-notification-modal',
  templateUrl: './send-email-notification-modal.component.html',
  styleUrls: ['./send-email-notification-modal.component.scss']
})
export class SendEmailNotificationModalComponent implements OnInit {
  @Output() confirmClicked = new EventEmitter<boolean>();

  colors = {
    mainColor: '#5128e2',
    secondaryColor: '#00ffa2'
  };

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

  closeModal(): void {
    this.activeModal.close();
  }

  sendNotification(): void {
    this.activeModal.close();
    this.confirmClicked.emit(true);
  }
}
