import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, Validators, FormArray, FormBuilder, RequiredValidator } from '@angular/forms';
import CONSTANTS from '../../../core/constants';
import { StandardListService } from '../../../core/services/standard-list.service';
import { take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { NgxHotjarService } from 'ngx-hotjar';
import { environment } from '../../../../environments/environment';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-schooling-modal',
  templateUrl: './schooling-modal.component.html',
  styleUrls: ['./schooling-modal.component.scss']
})

export class SchoolingModalComponent implements OnInit {
  @Input() colors;
  @Input() update;

  schoolingLevels;
  type;
  currentSchoolingLevel;
  schoolingCourses;
  courseLevel;
  institutionLevel;
  STATES = CONSTANTS.STATES;
  SCHOOLING_LEVELS = CONSTANTS.SCHOOLING_LEVELS_MATCHMAKING;

  auxResults: any = {};
  courses: Array<any>;
  filteredCourses;
  coursesArea: any;
  coursesSubArea: any;

  modalForm: FormGroup;
  hasMatchMakingRoute: boolean;
  readyToUpdate: boolean = true;
  filtered: boolean;
  filter: boolean;

  constructor(
    private activeModal: NgbActiveModal,
    private standardListService: StandardListService,
    protected route: Router,
    protected $hotjar: NgxHotjarService,
    public router: Router,
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
  ) { }

  ngOnInit() {
    this.$hotjar.virtualPageView(`${environment.web_link}${this.router.url}`);
    this.setSchoolingLevels();

    this.modalForm = this.formBuilder.group({
      level: new FormControl('', [Validators.required]),
      levelType: new FormControl(''),
      standardSchoolCourse: new FormControl(''),
      standardSchoolCourseArea: new FormControl(''),
      courseSubArea: new FormControl(''),
      standardInstitution: new FormControl(''),
      state: new FormControl(''),
      ead: new FormControl(false),
      presencial: new FormControl(false),
      canBeEad: new FormControl(null),
      finished: new FormGroup({
        since: new FormControl(''),
        until: new FormControl(''),
      }),
      typeCourse: this.formBuilder.array([])
    });

    this.hasMatchMakingRoute = this.route.url.includes('create');
  }

  enableSubareaAndCourse(): boolean {
    return !this.modalForm.get('standardSchoolCourseArea').value;
  }

  messageIfTheAreaHasNotFilled(): void {
    if (this.enableSubareaAndCourse()) {
      this.toastrService.clear();
      this.toastrService.info('Para habilitar este campo, preencha a área do curso.', 'Campo desabilitado!');
    }
  }

  setSchoolingLevels() {
    this.schoolingLevels = this.SCHOOLING_LEVELS.map(level => ({ type: level.value }));
  }

  closeModal() {
    this.activeModal.close();
  }

  selectOnChange(target, clean?) {
    if (clean) {
      this.modalForm.get("finished.since").patchValue(null);
      this.modalForm.get("finished.until").patchValue(null);
      this.modalForm.get("standardSchoolCourseArea").patchValue(null);
      this.modalForm.get("standardSchoolCourse").patchValue(null);
      this.modalForm.get("courseSubArea").patchValue(null);
      this.modalForm.get("levelType").patchValue(null);
      this.modalForm.get("standardInstitution").patchValue(null);
      this.modalForm.get("state").patchValue(null);
      this.modalForm.get("ead").patchValue(false);
      this.modalForm.get("presencial").patchValue(false);
      this.modalForm.get("canBeEad").patchValue(null);
      this.modalForm.get("typeCourse").patchValue([]);
      this.auxResults = {};
    }

    let selectedItem;

    if (target && target.source) {
      selectedItem = target.source.selected.id;
    } else {
      selectedItem = target;
    }

    this.currentSchoolingLevel = selectedItem;

    this.setSearchListsLevels();
    this.setCoursesType();
  }

  setCoursesType(): void {
    this.schoolingCourses = this.schoolingLevels.filter(level => this.currentSchoolingLevel === level.type);
  }

  setSearchListsLevels() {
    const apiTypes = {
      sup: ['higher_education_course', 'higher_education_institution'], // sup = superior
      tec: ['technical_education_course', 'technical_education_institution'] // tec = tecnico
    };

    this.courseLevel = undefined;
    this.institutionLevel = undefined;

    const [supLevel, tecLevel] = Object.keys(apiTypes);
    if (this.currentSchoolingLevel.indexOf(supLevel) >= 0) {
      this.courseLevel = apiTypes[supLevel][0];
      this.institutionLevel = apiTypes[supLevel][1];
    }

    if (this.currentSchoolingLevel.indexOf(tecLevel) >= 0) {
      this.courseLevel = apiTypes[tecLevel][0];
      this.institutionLevel = apiTypes[tecLevel][1];
    }

    if (this.courseLevel) {
      this.standardListService.getList({ type: this.courseLevel })
        .pipe(take(1))
        .subscribe(courses => {

          this.courses = courses;
          this.filteringCourses('area');
        });
    }
  }

  setAreas() {
    this.coursesArea = this.courses
      .filter(element => element.schoolCourse && element.schoolCourse.area && element.schoolCourse.validated)
      .map(element => element.schoolCourse.area);

    this.coursesArea = this.coursesArea.filter((este, i) => {
      return this.coursesArea.indexOf(este) === i && este != null;
    });

    this.coursesSubArea = this.courses
      .filter(element => element.schoolCourse && element.schoolCourse.area && element.schoolCourse.subArea)
      .map(element => this.modalForm.get('standardSchoolCourseArea').value == element.schoolCourse.area ? element.schoolCourse.subArea : null);

    this.coursesSubArea = this.coursesSubArea.filter((este, i) => {
      return this.coursesSubArea.indexOf(este) === i && este != null;
    });
  }

  filteringCourses(type) {

    const control = type === 'area' ? 'standardSchoolCourseArea' : 'courseSubArea';

    if (!this.readyToUpdate) {
      if (type == 'area') {
        this.modalForm.get('courseSubArea').patchValue(null);
        this.modalForm.get('standardSchoolCourse').patchValue(null);
      } else {
        this.modalForm.get('standardSchoolCourse').patchValue(null);
      }
    }

    this.readyToUpdate = false;
    const value = this.modalForm.get(control).value;
    this.setAreas();
    this.filteredCourses = this.courses.filter(course => course.schoolCourse && course.schoolCourse[type] && course.schoolCourse[type] === value);
  }

  addNewSchoolingLevel() {
    for (let x in this.courses) {
      this.filter = this.courses[x].schoolCourse.name === this.modalForm.get('standardSchoolCourse').value;
      if (this.filter) {
        this.filtered = true;
      }
    }

    if (this.modalForm.valid) {
      let result: any = {};
      const schooling = _.cloneDeep(this.modalForm.value);

      if (schooling.level && (schooling.level.includes('sup') || schooling.level.includes('tec-med')) && schooling.standardSchoolCourse) {
        if (this.filtered) {
          schooling.standardSchoolCourse = {
            name: schooling.standardSchoolCourse,
            standardListId: this.auxResults['standardSchoolCourse'],
          };
        } else {
          this.toastrService.clear();
          this.toastrService.warning(
            'Selecione algum curso da lista.',
            'Atenção!'
          );
          return;
        }
      }

      if (schooling.level && (schooling.level.includes('sup') || schooling.level.includes('tec-med')) && schooling.standardInstitution) {
        if (this.auxResults['standardInstitution']) {
          schooling.standardInstitution = {
            name: schooling.standardInstitution,
            standardListId: this.auxResults['standardInstitution'],
          };
        }
      }

      schooling.typeCourse = schooling.typeCourse || [];

      if (schooling.ead) {
        schooling.typeCourse.push('distanceEducation');
        delete schooling.ead;
      }

      if (schooling.presencial) {
        schooling.typeCourse.push('common');
        delete schooling.presencial;
      }

      result = { ...schooling };
      const levelMasked = result.level;

      result = {
        ...result, ...{
          level: this.currentSchoolingLevel,
          type: this.type,
          item: result
        }
      };

      this.activeModal.close({
        value: [levelMasked],
        schooling: result
      });
      return;
    } else {
      this.toastrService.clear();
      this.toastrService.warning(
        'Preencha todos os campos obrigatórios.',
        'Atenção!'
      );
    }
  }

  showInvalid(fieldPath: string) {
    const control = this.modalForm.get(fieldPath);
    return !control.valid && control.touched;
  }

  getListItem(event) {
    const { item, type } = event;
    this.auxResults = this.auxResults || {};

    this.auxResults[type] = item._id;
    let subArea = item.schoolCourse && item.schoolCourse.subArea ? item.schoolCourse.subArea : undefined;
    this.modalForm.get('courseSubArea').setValue(subArea);
  }

  getInstitutionListItem(event) {
    const { item, type } = event;

    this.auxResults = this.auxResults || {};

    this.auxResults[type] = item._id;
  }

  setCheckValue(presencial, ead) {
    if (!presencial.checked && !ead.checked) {
      this.modalForm.get('canBeEad').patchValue(null);
    } else if (presencial.checked && !ead.checked) {
      this.modalForm.get('canBeEad').patchValue(false);
    } else {
      this.modalForm.get('canBeEad').patchValue(true);
    }
  }

  addItem(item) {
    const attribute = this.modalForm.get('typeCourse');
    const index = attribute.value.findIndex(educantionType => String(educantionType) === String(item));

    if (index === -1) {
      attribute.value.push(new FormControl(item));
    } else {
      attribute.value.splice(index, 1);
    }

    attribute.markAsTouched();
    attribute.updateValueAndValidity();
  }
}
