import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxHotjarService } from 'ngx-hotjar';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-alert-changes',
  templateUrl: './alert-changes.component.html',
  styleUrls: ['./alert-changes.component.scss']
})
export class AlertChangesComponent implements OnInit {

  @Input() text = '';
  @Input() title = '';

  constructor(
    private activeModal: NgbActiveModal,
    protected $hotjar: NgxHotjarService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.$hotjar.virtualPageView(`${environment.web_link}${this.router.url}`);
  }

  close(param){
    this.activeModal.close(param);
  }

}
