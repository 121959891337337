import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { SearchListInputComponent, ModalComponent } from "../../core";
import { AgmCoreModule } from "@agm/core";
import { PipesModule } from "../pipes/pipes.module";
import { RouterModule } from "@angular/router";
import { MaterialCustomModule } from "../modules/material.module";
// import { ProfileComponent } from "./profile/profile.component";

@NgModule({
  declarations: [
    SearchListInputComponent,
    ModalComponent,
    // ProfileComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PipesModule,
    AgmCoreModule,
    RouterModule,
    MaterialCustomModule
  ],
  exports: [
    SearchListInputComponent,
    ModalComponent,
    MaterialCustomModule,
    // ProfileComponent
  ]
})
export class CoreModule { }
