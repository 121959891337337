import { Pipe, PipeTransform } from '@angular/core';

import CONSTANTS from '../constants';

@Pipe({
  name: 'feedback'
})
export class FeedbackPipe implements PipeTransform {
  FEEDBACK = CONSTANTS.FEEDBACK;

  transform(feedbackType: String): any {
    if (!feedbackType) {
      return feedbackType;
    }

    return this.FEEDBACK.find(feedback => feedback.key === feedbackType).value;
  }
}
