import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { FormArray, FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Subject, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, merge, filter } from 'rxjs/operators';

import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';
import { SupplementaryQuestion } from '../../../core/models';
import { StorageService } from '../../../core/services';
import CONSTANTS from '../../../core/constants';
import { NgxHotjarService } from 'ngx-hotjar';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-edit-supplementary-questions-modal',
  templateUrl: './edit-supplementary-questions-modal.component.html'
})

export class EditSupplementaryQuestionsModalComponent implements OnInit {
  @ViewChild('instance') instance: NgbTypeahead;
  @Input() update: Boolean;
  @Input() index: number;
  @Input() supplementaryQuestion: SupplementaryQuestion;

  focus = {
    alternatives$: new Subject<string>(),
  };
  click = {
    alternatives$: new Subject<string>(),
  };

  form: FormGroup;
  SUPPLEMENTARY_QUESTION_TYPES = CONSTANTS.SUPPLEMENTARY_QUESTION_TYPES;
  SUPPLEMENTARY_QUESTION_RULES = CONSTANTS.SUPPLEMENTARY_QUESTION_RULES;
  ALTERNATIVES = [];

  constructor(
    public activeModal: NgbActiveModal,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder,
    private storage: StorageService,
    private modalService: NgbModal,
    protected $hotjar: NgxHotjarService,
    public router: Router,
  ) { }

  ngOnInit() {
    this.$hotjar.virtualPageView(`${environment.web_link}${this.router.url}`);
    this.loadFormControls();
    this.loadFormValidators();
    this.changeTypeForm();
    this.changeRuleForm();
  }

  loadFormControls() {
    this.form = this.formBuilder.group({
      // tslint:disable-next-line:max-line-length
      _id: [this.supplementaryQuestion && this.supplementaryQuestion._id ? this.supplementaryQuestion._id : undefined, Validators.nullValidator],
      // tslint:disable-next-line:max-line-length
      type: [this.supplementaryQuestion && this.supplementaryQuestion.type ? this.supplementaryQuestion.type : undefined, Validators.required],
      // tslint:disable-next-line:max-line-length
      rule: [this.supplementaryQuestion && this.supplementaryQuestion.rule ? this.supplementaryQuestion.rule : 'informative', Validators.required],
      // tslint:disable-next-line:max-line-length
      asking: [this.supplementaryQuestion && this.supplementaryQuestion.asking ? this.supplementaryQuestion.asking : undefined, Validators.required],
      // tslint:disable-next-line:max-line-length
      active: [this.supplementaryQuestion && typeof this.supplementaryQuestion.active === 'boolean' ? this.supplementaryQuestion.active : true, Validators.nullValidator],
      alternatives: this.buildArrayAlternatives(),
      alternativesDeleted: this.buildArrayAlternativesDeleted(),
      // tslint:disable-next-line:max-line-length
      inputAlternatives: ['', Validators.nullValidator],
      // tslint:disable-next-line:max-line-length
      description: [this.supplementaryQuestion && this.supplementaryQuestion.description ? this.supplementaryQuestion.description : undefined, Validators.nullValidator],
      // tslint:disable-next-line:max-line-length
      initial: [this.supplementaryQuestion && this.supplementaryQuestion.initial ? this.supplementaryQuestion.initial : 0, Validators.nullValidator],
      // tslint:disable-next-line:max-line-length
      final: [this.supplementaryQuestion && this.supplementaryQuestion.final ? this.supplementaryQuestion.final : 5, Validators.nullValidator],
      // tslint:disable-next-line:max-line-length
      initOption: [this.supplementaryQuestion && this.supplementaryQuestion.initOption ? this.supplementaryQuestion.initOption : undefined, Validators.nullValidator],
      // tslint:disable-next-line:max-line-length
      finalOption: [this.supplementaryQuestion ? this.supplementaryQuestion.finalOption : undefined, Validators.nullValidator],
      // tslint:disable-next-line:max-line-length
      image: [this.supplementaryQuestion && this.supplementaryQuestion.image ? this.supplementaryQuestion.image : undefined, Validators.nullValidator],
      // tslint:disable-next-line:max-line-length
      video: [this.supplementaryQuestion && this.supplementaryQuestion.video ? this.supplementaryQuestion.video : undefined, Validators.nullValidator],
    });

    if (this.form.get('_id').value) {
      this.form.markAsTouched();
    }
  }

  buildArrayAlternatives() {
    let arr;

    if (this.supplementaryQuestion && this.supplementaryQuestion.alternatives && this.supplementaryQuestion.alternatives.length > 0) {
      arr = this.supplementaryQuestion.alternatives.map(alternative => {
        return this.formBuilder.group({
          _id: [alternative._id, Validators.nullValidator],
          text: [alternative.text, Validators.nullValidator],
          rule: [alternative.rule ? true : false, Validators.nullValidator],
          selected: [false, Validators.nullValidator],
        });
      });
    }

    return arr ? this.formBuilder.array(arr) : this.formBuilder.array([]);
  }

  buildArrayAlternativesDeleted() {
    let arr;

    // tslint:disable-next-line:max-line-length
    if (this.supplementaryQuestion && this.supplementaryQuestion.alternativesDeleted && this.supplementaryQuestion.alternativesDeleted.length > 0) {
      arr = this.supplementaryQuestion.alternativesDeleted.map(alternativeDeleted => {
        return this.formBuilder.group({
          _id: [alternativeDeleted._id, Validators.nullValidator],
          text: [alternativeDeleted.text, Validators.nullValidator],
          rule: [alternativeDeleted.rule ? true : false, Validators.nullValidator],
        });
      });
    }

    return arr ? this.formBuilder.array(arr) : this.formBuilder.array([]);
  }

  loadFormValidators() {
    const inputAlternatives = this.form.get('inputAlternatives');
    const initial = this.form.get('initial');
    const final = this.form.get('final');

    if (this.form.get('type').value === 'radio' || this.form.get('type').value === 'check' || this.form.get('type').value === 'dropdown') {
      inputAlternatives.setValidators([Validators.required]);

      if (!this.form.get('alternatives').value || this.form.get('alternatives').value.length === 0) {
        inputAlternatives.setValue(undefined);
      } else {
        inputAlternatives.setValidators([Validators.nullValidator]);
      }
    } else {
      inputAlternatives.setValidators([Validators.nullValidator]);
    }

    if (this.form.get('type').value === 'linear') {
      initial.setValidators([Validators.required]);
      final.setValidators([Validators.required]);
    } else {
      initial.setValidators([Validators.nullValidator]);
      final.setValidators([Validators.nullValidator]);
    }

    initial.updateValueAndValidity();
    final.updateValueAndValidity();
    inputAlternatives.updateValueAndValidity();
  }

  changeTypeForm() {
    const initial = this.form.get('initial');
    const final = this.form.get('final');

    this.form.get('type').valueChanges
      .subscribe(type => {
        if (type === 'linear') {
          if (!this.form.get('initial').value) {
            initial.setValue(0);
          }

          if (!this.form.get('final').value) {
            final.setValue(5);
          }

          initial.updateValueAndValidity();
          final.updateValueAndValidity();
        }
      });
  }

  changeRuleForm() {
    this.form.get('rule').valueChanges
      .subscribe(rule => {
        for (let i = 0; i < this.form.get('alternatives').value.length; i++) {
          (<FormArray>this.form.controls['alternatives']).at(i).patchValue({ rule: false });
        }
      });
  }

  onFormSubmit() {
    this.loadFormValidators();

    if (this.form.valid) {

      if (!this.form.touched) {
        return;
      }

      if (this.update) {
        this.closeModal(true, 'update');
      } else {
        this.closeModal(true, 'create');
      }
    } else {
      this.toastrService.clear();
      this.toastrService.error('Existem campos obrigatórios não preenchidos no formulário!', 'Campos obrigatórios não preenchidos!');
      this.validateAllFormFields(this.form);
    }
  }

  onFormDelete() {
    const modal = this.modalService.open(ConfirmModalComponent, { backdrop: 'static', keyboard: false, windowClass: 'myCustomModalClass' });
    modal.componentInstance.htmlTitle = 'Deletar pergunta complementar';
    modal.componentInstance.htmlMessage = `
    <p>${this.storage.getUser().firstName} tem certeza em deletar esta pergunta complementar?<br>
    Usuários que já tenham respondido esta pergunta não serão listados nos filtros de busca!</p>`;

    modal.result.then(params => {
      if (params) {
        this.closeModal(true, 'delete');
      }
    }).catch(err => {});
  }

  onFormChangeStatus() {
    this.closeModal(true, 'status');
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  showInvalidFeedback(fieldPath: string) {
    const control = this.form.get(fieldPath);
    return !control.valid && control.touched;
  }

  getCleanedAttribute(data) {
    function clearRecursively(object) {
      Object.keys(object).forEach(key => {
        if (object[key] instanceof Object) {

          object[key] = clearRecursively(object[key]);
        }

        if ((typeof object[key] !== 'boolean') && (!object[key] || object[key] === 'undefined') && object[key] !== 0) {
          delete object[key];
          return;
        }
      });

      return object;
    }

    data = JSON.parse(JSON.stringify(data));
    return clearRecursively(data);
  }

  closeModal(submitted: boolean, action: string) {
    if (submitted) {
      this.activeModal.close({
        supplementaryQuestion: this.getCleanedAttribute(this.form.value),
        action: action
      });
    } else {
      this.activeModal.close();
    }
  }

  showDialog(reason) {
    switch (reason) {
      case 'can_not_change_type':
        this.toastrService.clear();
        this.toastrService.info('Uma vez cadastrada a pergunta, o tipo não pode ser alterado!', 'Ação inválida', {
          enableHtml: true
        });
      break;
    }
  }

  searchDropBoxAlternatives = (text$: Observable<string>) =>
    text$
    .pipe(
      debounceTime(200),
      distinctUntilChanged(),
      merge(this.focus.alternatives$),
      merge(this.click.alternatives$.pipe(
        filter(() => !this.instance.isPopupOpen()))
      ),
      map(term => (term === '' ? this.ALTERNATIVES :
        this.ALTERNATIVES.filter(data => data.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 10))
    )

  onSelectDropBoxAlternatives($event, dataInput: HTMLInputElement) {
    $event.preventDefault();
    this.addDropBoxAlternatives($event.item, dataInput);
  }

  addDropBoxAlternatives(data, dataInput: HTMLInputElement) {
    const index = this.ALTERNATIVES.indexOf(data);

    if (index >= 0) {
      this.ALTERNATIVES.splice(index, 1);
    }

    dataInput.value = '';

    const alternatives = this.form.get('alternatives') as FormArray;

    alternatives.push(
      this.formBuilder.group({
        text: data,
        _id: '',
        selected: false,
        rule: false
      })
    );
  }

  removeDropBoxAlternatives(item: FormGroup) {
    const alternatives = this.form.get('alternatives') as FormArray;
    const alternativesDeleted = this.form.get('alternativesDeleted') as FormArray;
    const index = alternatives.controls.indexOf(item);

    alternativesDeleted.push(
      this.formBuilder.group({
        text: alternatives.at(index).value.text,
        rule: alternatives.at(index).value.rule,
        _id: alternatives.at(index).value._id,
      })
    );

    this.ALTERNATIVES.push(item.value.text);
    alternatives.removeAt(index);

    alternativesDeleted.updateValueAndValidity();
  }

  editAlternative(index: number) {
    const alternatives = this.form.get('alternatives') as FormArray;

    alternatives.at(index).patchValue({
      text: alternatives.at(index).value.text,
      _id: alternatives.at(index).value._id,
      rule: alternatives.at(index).value.rule,
      selected: !alternatives.at(index).value.selected
    });

    alternatives.updateValueAndValidity();
  }
}
