import { Pipe, PipeTransform, NgModule } from '@angular/core';

import { environment } from '../../../environments/environment';

@Pipe({
  name: 'prefixeApiUrl'
})
export class PrefixeApiUrlPipe implements PipeTransform {

  transform(value: string, args?: any): any {
    if (!value || value.startsWith('http')) {
      return value;
    }

    return 'https://api.taqe.com.br/v1/' + value;
  }
}
