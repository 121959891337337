import { Routes } from '@angular/router';
import { AdminLayoutComponent, AuthLayoutComponent, PublicLayoutComponent } from './core';
import { IsAuthenticatedGuard } from './core/guards/is-authenticated.guard';
import { NotAuthenticatedGuard } from './core/guards/not-authenticated.guard';
import { UserAgentGuard } from './core/guards/user-agent.guard';
import { PublicPageGuard } from './core/guards/public-page.guard';
import { IncompatibleBrowserComponent } from './incompatible-browser/incompatible-browser.component';
import { CookiesPolicyComponent } from './core/cookies/cookies-policy/cookies-policy.component';
import { PrivacyPolicyComponent } from './core/cookies/privacy-policy/privacy-policy.component';
import { InviteComponent } from './invite/invite.component';

export const AppRoutes: Routes = [
  {
    path: 'auth',
    component: AuthLayoutComponent,
    canActivate: [NotAuthenticatedGuard, UserAgentGuard],
    loadChildren: './auth/auth.module#AuthModule'
  },
  {
    path: 'public',
    canActivate: [UserAgentGuard, PublicPageGuard],
    component: PublicLayoutComponent,
    loadChildren: './public/public.module#PublicModule'
  },
  {
    path: 'privateFeedback',
    canActivate: [IsAuthenticatedGuard, UserAgentGuard, PublicPageGuard],
    loadChildren: './public/public.module#PublicModule',
  },
  {
    path: 'incompatible-browser',
    component: IncompatibleBrowserComponent
  },
  {
    path: 'cookies-policy',
    component: CookiesPolicyComponent
  },
  {
    path: 'invite/:companyKey',
    component: InviteComponent,
    canActivate: [UserAgentGuard],
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent
  },
  {
    path: '',
    component: AdminLayoutComponent,
    canActivate: [IsAuthenticatedGuard, UserAgentGuard],
    children: [{
      path: '',
      loadChildren: './showcase/showcase.module#ShowcaseModule',
    }]
  },
  {
    path: 'error',
    loadChildren: './error/error.module#ErrorModule'
  },
  {
    path: '**',
    redirectTo: 'error/404'
  }
];
