import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import CustomEncoder from '../../core/helpers/custom-encoder';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class CertificationService {
  url = environment.url;
  private apiGatewayUrl: string = environment.api_gateway;

  constructor (
    private http: HttpClient
  ) { }

  getCertification(data: any = {}): Observable<any> {
    let httpParams = new HttpParams({ encoder: new CustomEncoder() });

    for (const key of Object.keys(data)) {
      httpParams = httpParams.append(key, data[key] instanceof Object ? JSON.stringify(data[key]) : data[key]);
    }

    return this.http.get<any>(`${this.url}/certifications`, { params: httpParams });
  }

  getCertificationList(data: any = {}): Observable<any> {
    let httpParams = new HttpParams({ encoder: new CustomEncoder() });

    for (const key of Object.keys(data)) {
      httpParams = httpParams.append(key, data[key] instanceof Object ? JSON.stringify(data[key]) : data[key]);
    }

    return this.http.get<any>(`${this.url}/certifications/list`, { params: httpParams });
  }

  getCertificationFileList(data: any = {}): Observable<any> {
    let httpParams = new HttpParams({ encoder: new CustomEncoder() });

    for (const key of Object.keys(data)) {
      httpParams = httpParams.append(key, data[key] instanceof Object ? JSON.stringify(data[key]) : data[key]);
    }

    return this.http.get<any>(`${this.url}/certifications/files/list`, { params: httpParams });
  }

  getUsersCertification(data: any = {}): Observable<any> {
    let httpParams = new HttpParams({ encoder: new CustomEncoder() });

    for (const key of Object.keys(data)) {
      httpParams = httpParams.append(key, data[key] instanceof Object ? JSON.stringify(data[key]) : data[key]);
    }

    return this.http.get<any>(`${this.url}/certifications/users/list`, { params: httpParams });
  }

  postCertification(data: any): Observable<any> {
    return this.http.post<any>(`${this.url}/certifications`, data);
  }

  postCertificationFile(data: any): Observable<any> {
    return this.http.post<any>(`${this.url}/certifications/files`, data);
  }

  getExportStudentsNewList(certificationFileId: string): Observable<any> {
    return this.http.get<any>(`${this.apiGatewayUrl}/api/v1/private/report/certification/${certificationFileId}`);
  }

  postExportStudentsNewList(certificationFileId: string, params = {}): Observable<any>{
    return this.http.post<any[]>(`${this.apiGatewayUrl}/api/v1/private/report/certification/${certificationFileId}`, params);
  }
}
