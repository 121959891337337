import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class CardService {
  urlGateway = environment.api_gateway;

  constructor(
    private http: HttpClient
  ) { }

  createCard(params = {}): Observable<any> {
    return this.http.post<any>(`${this.urlGateway}/api/users/cards/v1/`, params);
  }

  // getCardByCompany(params = {}): Observable<any> {
  //   return this.http.get<any>(`${this.urlGateway}/api/users/cards/list/bycompany/v1/`, params);
  // }

  deleteCardById(cardId: string): Observable<any> {
    return this.http.delete<any>(`${this.urlGateway}/api/users/cards/delete/v1/${cardId}`);
  }

  setPrimaryCard(cardId: string): Observable<any> {
    return this.http.put<any>(`${this.urlGateway}/api/users/cards/setmain/v1/${cardId}`, {});
  }
}
