import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { VacancyCandidatesService } from '../../vacancy-candidates.service';
import { VacancyCandidate, Vacancy, Status, Process } from '../../../core/models';
import { NgxHotjarService } from 'ngx-hotjar';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
import { StorageService, UserService  } from '../../../core/services';

@Component({
  selector: 'app-change-status-modal',
  templateUrl: './change-status-modal.component.html',
  styleUrls: [ './change-status-modal.component.scss', '../../candidates/candidates.component.scss' ]
})

export class ChangeStatusModalComponent implements OnInit {
  @Input() candidate: VacancyCandidate;
  @Input() process: Process;
  public loading: Boolean = false as Boolean;

  candidateStatus = {};
  candidateSubstatus = {};
  observations: string;
  userManager: any;

  constructor(
    public activeModal: NgbActiveModal,
    private vacancyCandidatesService: VacancyCandidatesService,
    protected $hotjar: NgxHotjarService,
    private router: Router,
    public userService: UserService,
    private storage: StorageService,
  ) { }

  ngOnInit() {
    this.$hotjar.virtualPageView(`${environment.web_link}${this.router.url}`);

    this.setCandidateStatus();
  }

  setCandidateStatus() {
    this.userManager = this.storage.getUser();
    if (this.userManager && this.userManager.role === 'MANAGER') {
      for (const step of this.process.steps) {
        step.status.inProcess = step.status.inProcess.filter(element => element.key === 'pre_approved');
      }

      this.candidateStatus = {};
      this.candidateSubstatus = {};
      const userManagerActionStatus = this.candidate.history
        .filter(element => element.userManager === this.userManager._id && element.action === 'change_status')
        .pop();
      if (userManagerActionStatus && userManagerActionStatus.newStatus) {
        for (const status of userManagerActionStatus.newStatus) {
          this.candidateStatus[status.key] = true;
        };
      }
      this.observations = (userManagerActionStatus && userManagerActionStatus.observations) || '';
    } else {
      this.candidate.status.forEach(status => {
        this.candidateStatus[status.key] = true;
        status.substatus.forEach(substatus => this.candidateSubstatus[substatus.key] = true);
      });

      if (this.candidate.status && this.candidate.status.length) {
        this.observations = this.candidate.status[this.candidate.status.length -1].observations;
      }
    }
  }

  getUserFirstName(user: any) {
    return this.userService.getUserFirstName(user);
  }

  getUserLastName(user: any) {
    return this.userService.getUserLastName(user);
  }

  getVacancyCandidateStepIndex(stepId): number {
    return this.process.steps.findIndex(step => String(step._id) === String(stepId));
  }

  public onSelectStatus(status): void {
    if (!this.candidateStatus[status.key] && status.substatus && status.substatus.length) {
      status.substatus.forEach(substatus => delete this.candidateSubstatus[substatus.key]);
    }
  }

  public onSelectSubstatus(status, substatus): void {
    if (this.candidateSubstatus[substatus.key]) {
      this.candidateStatus[status.key] = true;
    } else {
      delete this.candidateSubstatus[substatus.key];
      if (Object.keys(this.candidateSubstatus).length === 0) {
        this.candidateStatus[status.key] = false;
      }
    }
  }

  changeStatus(): void {
    if (this.loading) {
      return;
    }

    this.loading = true;
    this.candidate.status = [];

    this.process.steps[this.getVacancyCandidateStepIndex(this.candidate.currentProcessStepId)].status.inProcess.forEach(status => {
      if (this.candidateStatus[status.key]) {
        const statusToAdd = {
          key: status.key,
          substatus: [],
          observations: this.observations
        };

        if (status.substatus) {
          status.substatus.forEach(substatus => {
            if (this.candidateSubstatus[substatus.key]) {
              statusToAdd.substatus.push({
                key: substatus.key
              });
            }
          });
        }

        this.candidate.status.push(statusToAdd);
      }
    });

    const params = {
      status: this.candidate.status,
      observations: this.observations
    };

    this.vacancyCandidatesService.updateVacancyCandidate(this.candidate._id, params)
      .subscribe(response => {
        this.activeModal.close(params);
      });
  }
}
