import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import CustomEncoder from '../../core/helpers/custom-encoder';
import { RequestCreditsPayload } from '../../vacancies/nav/models/navbar.model';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  url: string = environment.url;
  gatewayUrl: string = environment.api_gateway;

  constructor(private http: HttpClient) {}

  getVacancyNotification(vacancyId: string, notificationType: string) {
    return this.http.get(`${this.url}/vacancies/${vacancyId}/notification/${notificationType}`);
  }

  getVacancyNotifications(vacancyId: string) {
    return this.http.get(`${this.url}/vacancies/${vacancyId}/notifications`);
  }

  postNotification(vacancyId: string, params = {}): Observable<any> {
    return this.http.post<any>(`${this.url}/vacancies/${vacancyId}/notifications`, params);
  }

  getVacancyNewAllNotificatons(vacancyId: string, params = {}): Observable<any> {
    let httpParams = new HttpParams({ encoder: new CustomEncoder() });

    for (const key of Object.keys(params)) {
      httpParams = httpParams.append(key, params[key] instanceof Object ? JSON.stringify(params[key]) : params[key]);
    }

    return this.http.get<any>(`${this.url}/vacancies/${vacancyId}/all-vacancy-notifications`, { params: httpParams });
  }

  getVacancyNewNotifications(vacancyId: string, params = {}): Observable<any> {
    let httpParams = new HttpParams({ encoder: new CustomEncoder() });

    for (const key of Object.keys(params)) {
      httpParams = httpParams.append(key, params[key] instanceof Object ? JSON.stringify(params[key]) : params[key]);
    }

    return this.http.get<any>(`${this.url}/vacancies/${vacancyId}/vacancy-notifications`, { params: httpParams });
  }

  patchVacancyNewNotifications(vacancyId: string, params = {}): Observable<any> {
    return this.http.patch<any>(`${this.url}/vacancies/${vacancyId}/vacancy-notifications`, params);
  }

  sendCreditsRequestNotification(params: RequestCreditsPayload): Observable<{message: string}> {
    return this.http.post<{message: string}>(`${this.gatewayUrl}/api/v1/private/showcase/request-credits`, params);
  }
}
