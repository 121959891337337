import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import CustomEncoder from '../helpers/custom-encoder';

@Injectable({
  providedIn: 'root'
})

export class PartnerService {
  private urlApiGateway: string = environment.api_gateway;

  constructor (
    private http: HttpClient
  ) { }

  getPartners(params = {}): Observable<any> {
    let httpParams = new HttpParams({ encoder: new CustomEncoder() });

    for (const key of Object.keys(params)) {
      httpParams = httpParams.append(key, params[key] instanceof Object ? JSON.stringify(params[key]) : params[key]);
    }

    return this.http.get<any>(`${this.urlApiGateway}/api/v1/private/company/partners`, { params: httpParams });
  }

  invitePartner(data: any): Observable<any> {
    return this.http.post<any>(`${this.urlApiGateway}/api/v1/private/company/partner/invite`, data);
  }

  invitePartnerOnlyByEmail(data: any): Observable<any> {
    return this.http.post<any>(`${this.urlApiGateway}/api/v1/private/company/partner/inviteOnlyByEmail`, data);
  }

  inviteTestPartner(data: any): Observable<any> {
    return this.http.post<any>(`${this.urlApiGateway}/api/v1/private/company/partner/invite-test-user`, data);
  }

  removePartner(partnerId: string, token: string): Observable<any> {
    return this.http.delete<any>(`${this.urlApiGateway}/api/v1/private/company/partner/${partnerId}?token=${token}`);
  }

  activePartner(token: any): Observable<any> {
    return this.http.post<any>(`${this.urlApiGateway}/api/v1/private/company/partner/active-invite`, token);
  }

  getTestUsers(params = {}): Observable<any> {
    let httpParams = new HttpParams({ encoder: new CustomEncoder() });

    for (const key of Object.keys(params)) {
      httpParams = httpParams.append(key, params[key] instanceof Object ? JSON.stringify(params[key]) : params[key]);
    }

    return this.http.get<any>(`${this.urlApiGateway}/api/v1/private/company/test-users`, { params: httpParams });
  }
}
