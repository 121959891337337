import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class PlanService {
  urlGateway = environment.api_gateway;

  constructor(
    private http: HttpClient
  ) { }

  filterPlans(params = {}): Observable<any> {
    return this.http.post<any>(`${this.urlGateway}/api/company/plans/filter/v1/`, params);
  }

  // getPlans(params = {}): Observable<any> {
  //   return this.http.get<any>(`${this.urlGateway}/api/company/plans/v1/`, params);
  // }

  changePlan(params = {}): Observable<any> {
    return this.http.post<any>(`${this.urlGateway}/api/company/changeplan/v1/`, params);
  }

  // getPlansByType(type): Observable<any> {
  //   return this.http.get<any>(`${this.urlGateway}/api/company/plans/find/bytype/v1/?type=${type}`, {});
  // }

}
