import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { FormArray, FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Subject, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, merge, filter } from 'rxjs/operators';

import { ConfirmModalComponent } from '../../modal/confirm-modal/confirm-modal.component';
import { EditStatusModalComponent } from '../edit-status-modal/edit-status-modal.component';
import { Step, Vacancy } from '../../../core/models/vacancy';
import { StorageService } from '../../../core/services';
import CONSTANTS from '../../../core/constants';
import { VacanciesService } from '../../vacancies.service';
import { NgxHotjarService } from 'ngx-hotjar';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-edit-processes-modal',
  templateUrl: './edit-processes-modal.component.html',
  styleUrls: ['./edit-processes-modal.component.scss']
})

export class EditProcessesModalComponent implements OnInit {
  @ViewChild('instance') instance: NgbTypeahead;
  @Input() update: Boolean;
  @Input() index: number;
  @Input() step: Step;
  @Input() status: any;
  @Input() vacancyId: string;

  focus = {
    htmlOrderedList$: new Subject<string>(),
  };
  click = {
    htmlOrderedList$: new Subject<string>(),
  };

  form: FormGroup;
  STEP_KEYS = CONSTANTS.STEP_KEYS;
  HTML_ORDERED_LIST = [];
  updateArray = {};
  display: string;
  tests: any;

  constructor(
    public activeModal: NgbActiveModal,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder,
    private storage: StorageService,
    private modalService: NgbModal,
    private vacancyService: VacanciesService,
    protected $hotjar: NgxHotjarService,
    public router: Router,
  ) { }

  ngOnInit() {
    this.$hotjar.virtualPageView(`${environment.web_link}${this.router.url}`);
    this.display = 'block';
    const company = this.storage.getCompany()._id;
    this.vacancyService.getVacanciesTest(company, {})
      .subscribe(response => {
        this.tests = response;
      });

    this.loadFormControls();
    this.changeKeyForm();
  }

  loadFormControls() {
    this.form = this.formBuilder.group({
      step: this.formBuilder.group({
        _id: [this.step && this.step._id ? this.step._id : undefined, Validators.nullValidator],
        key: [this.step && this.step.key ? this.step.key : undefined, Validators.required],
        test: [this.step && this.step.test ? this.step.test : undefined, Validators.nullValidator],
        name: [{ value: this.step && this.step.name ? this.step.name : undefined, disabled: this.blockedStages() }, Validators.required],
        // tslint:disable-next-line:max-line-length
        approveAutomatically: [{ value: this.step && this.step.approveAutomatically ? this.step.approveAutomatically : false, disabled: this.disableApproveAutomatically() }, Validators.nullValidator],
        // tslint:disable-next-line:max-line-length
        needScheduling: [{ value: this.step && this.step.needScheduling ? this.step.needScheduling : false, disabled: this.disableNeedScheduling() }, Validators.nullValidator],
        candidatesCount: this.formBuilder.group({
          // tslint:disable-next-line:max-line-length
          inProcess: [this.step && this.step.candidatesCount && this.step.candidatesCount.inProcess ? this.step.candidatesCount.inProcess : 0, Validators.nullValidator],
          // tslint:disable-next-line:max-line-length
          notInProcess: [this.step && this.step.candidatesCount && this.step.candidatesCount.notInProcess ? this.step.candidatesCount.notInProcess : 0, Validators.nullValidator],
        }),
        video: this.formBuilder.group({
          newUploadMethod: [this.step && this.step.video && this.step.video.newUploadMethod ? this.step.video.newUploadMethod : false, Validators.nullValidator],
          instructions: this.formBuilder.group({
            // tslint:disable-next-line:max-line-length
            title: [this.step && this.step.video && this.step.video.instructions && this.step.video.instructions.title ? this.step.video.instructions.title : undefined, Validators.nullValidator],
            htmlOrderedList: this.buildArrayHtmlOrderedList(),
            inputHtmlOrderedList: ['', Validators.nullValidator],
            // tslint:disable-next-line:max-line-length
            htmlHeaderText: [this.step && this.step.video && this.step.video.instructions && this.step.video.instructions.htmlHeaderText ? this.step.video.instructions.htmlHeaderText : undefined, Validators.nullValidator],
            // tslint:disable-next-line:max-line-length
            htmlFooterText: [this.step && this.step.video && this.step.video.instructions && this.step.video.instructions.htmlFooterText ? this.step.video.instructions.htmlFooterText : undefined, Validators.nullValidator],
          }),
          // tslint:disable-next-line:max-line-length
          maxDuration: [this.step && this.step.video && this.step.video.maxDuration ? this.step.video.maxDuration > 0 ? this.step.video.maxDuration / 60 : 0 : undefined, Validators.nullValidator],
        }),
      }),
      status: this.formBuilder.group({
        inProcess: this.buildArrayStatusInProcess(),
        notInProcess: this.buildArrayStatusNotInProcess(),
      }),
    });

    if (this.form.get('step.key').value) {
      this.form.markAsTouched();
    }
  }

  buildArrayStatusInProcess() {

  }

  buildArrayStatusNotInProcess() {
    let arr;

    // tslint:disable-next-line:max-line-length
    if (this.status && this.status.notInProcess && this.status.notInProcess.length > 0) {
      arr = this.status.notInProcess.map(item => {
        return this.formBuilder.group({
          name: [],
          key: [],
          color: [],
          substatus: [],
        });
      });
    }

    return arr ? this.formBuilder.array(arr) : this.formBuilder.array([]);
  }

  formatStep() {
    if (this.form.get('step.key').value === 'video') {
      const maxDuration = this.form.get('step.video.maxDuration') as FormControl;

      maxDuration.setValue(maxDuration.value * 60);
      maxDuration.markAsTouched();
      maxDuration.updateValueAndValidity();
    }
  }

  changeKeyForm() {
    const needScheduling = this.form.get('step.needScheduling') as FormControl;
    const approveAutomatically = this.form.get('step.approveAutomatically') as FormControl;

    this.form.get('step.key').valueChanges.subscribe(key => {
      let change = false;

      if (key === 'scheduling') {
        change = true;
        needScheduling.setValue(true);
        needScheduling.disable();
        approveAutomatically.setValue(false);
        approveAutomatically.disable();
        // reset
      }

      if (key === 'test') {
        change = true;
        needScheduling.setValue(false);
        needScheduling.enable();
        approveAutomatically.setValue(false);
        approveAutomatically.disable();
      }

      if (key === 'video') {
        change = true;
        needScheduling.setValue(false);
        needScheduling.enable();
        approveAutomatically.setValue(false);
        approveAutomatically.disable();
      }

      if (key === 'typing_test') {
        change = true;
        needScheduling.setValue(false);
        needScheduling.enable();
        approveAutomatically.setValue(false);
        approveAutomatically.disable();
      }

      if (key === 'partnership') {
        change = true;
        needScheduling.setValue(false);
        needScheduling.enable();
        approveAutomatically.setValue(false);
        approveAutomatically.disable();
      }

      if (key === 'intermediate') {
        change = true;
        needScheduling.setValue(false);
        needScheduling.enable();
        approveAutomatically.setValue(false);
        approveAutomatically.disable();
      }

      if (key === 'documentation') {
        change = true;
        needScheduling.setValue(false);
        needScheduling.enable();
        approveAutomatically.setValue(false);
        approveAutomatically.disable();
      }

      if (!change) {
        needScheduling.enable();
        approveAutomatically.enable();
      }
    });

    approveAutomatically.updateValueAndValidity();
    needScheduling.updateValueAndValidity();
  }

  blockedStages() {
    // tslint:disable-next-line:max-line-length
    if (this.step && (this.step.key === 'matches' || this.step.key === 'applications' || this.step.key === 'approved' || this.step.key === 'hired')) {
      return true;
    } else {
      return false;
    }
  }

  disableApproveAutomatically() {
    // tslint:disable-next-line:max-line-length
    if (this.step && (this.step.key === 'matches' || this.step.key === 'intermediate' || this.step.key === 'documentation' || this.step.key === 'partnership' || this.step.key === 'test' || this.step.key === 'video' || this.step.key === 'scheduling' || this.step.key === 'approved' || this.step.key === 'hired')) {
      return true;
    } else {
      return false;
    }
  }

  disableNeedScheduling() {
    // tslint:disable-next-line:max-line-length
    if (this.step && (this.step.key === 'matches' || this.step.key === 'applications' || this.step.key === 'scheduling' || this.step.key === 'approved' || this.step.key === 'hired')) {
      return true;
    } else {
      return false;
    }
  }

  loadFormValidators() {
    const inputHtmlOrderedList = this.form.get('step.video.instructions.inputHtmlOrderedList');
    const maxDuration = this.form.get('step.video.maxDuration');
    const test = this.form.get('step.test');

    inputHtmlOrderedList.setValidators([Validators.nullValidator]);
    test.setValidators([Validators.nullValidator]);

    if (this.form.get('step.key').value === 'typing_test') {
      inputHtmlOrderedList.setValidators([Validators.required]);
      maxDuration.setValidators([Validators.required]);

      if (!this.form.get('step.typing_test.text.htmlOrderedList').value) {
        inputHtmlOrderedList.setValue(undefined);
      } else {
        inputHtmlOrderedList.setValidators([Validators.nullValidator])
      }

      if (this.form.get('step.video.maxDuration').value > 0) {
        maxDuration.setValidators([Validators.nullValidator]);
      } else {
        maxDuration.setValue(undefined);
      }
    }

    if (this.form.get('step.key').value === 'video') {
      inputHtmlOrderedList.setValidators([Validators.required]);
      maxDuration.setValidators([Validators.required]);

      // tslint:disable-next-line:max-line-length
      if (!this.form.get('step.video.instructions.htmlOrderedList').value || this.form.get('step.video.instructions.htmlOrderedList').value.length === 0) {
        inputHtmlOrderedList.setValue(undefined);
      } else {
        inputHtmlOrderedList.setValidators([Validators.nullValidator]);
      }

      if (this.form.get('step.video.maxDuration').value > 0) {
        maxDuration.setValidators([Validators.nullValidator]);
      } else {
        maxDuration.setValue(undefined);
      }
    }

    if (this.form.get('step.key').value === 'test') {
      test.setValidators([Validators.required]);
    } else {
      test.setValue(undefined);
    }

    maxDuration.updateValueAndValidity();
    inputHtmlOrderedList.updateValueAndValidity();
    test.updateValueAndValidity();
  }

  onFormSubmit() {
    this.loadFormValidators();

    if (this.form.valid) {
      if (!this.form.touched) {
        return;
      }

      this.formatStep();

      if (this.update) {
        this.closeModal(true, 'update');
      } else {
        this.closeModal(true, 'create');
      }
    } else {
      this.toastrService.clear();
      this.toastrService.error('Existem campos obrigatórios não preenchidos no formulário!', 'Campos obrigatórios não preenchidos!');
      this.validateAllFormFields(this.form);
    }
  }

  onFormDelete(step) {
    if (step._id) {
      const params = {
        step
      }
      this.vacancyService.deleteVacancyProcess(this.vacancyId, params)
        .subscribe(response => {
          this.tests = response;
          this.closeModal(true, 'delete');
        });
    } else {
      this.closeModal(true, 'delete');
    }
  }

  getCleanedAttribute(data) {
    function clearRecursively(object) {
      Object.keys(object).forEach(key => {
        if (object[key] instanceof Object) {

          object[key] = clearRecursively(object[key]);

          if (Object.keys(object[key]).length === 0) {
            delete object[key];
            return;
          }
        }

        if (typeof object[key] !== 'boolean' && (!object[key] || object[key] === 'undefined') && object[key] !== 0) {
          delete object[key];
          return;
        }
      });

      return object;
    }

    data = JSON.parse(JSON.stringify(data));
    return clearRecursively(data);
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  showInvalidFeedback(fieldPath: string) {
    const control = this.form.get(fieldPath);
    return !control.valid && control.touched;
  }

  closeModal(submitted: boolean, action: string) {
    // if (submitted) {
    //   this.activeModal.close({
    //     step: this.getCleanedAttribute(this.form.getRawValue().step),
    //     action: action
    //   });
    // } else {
    //   this.activeModal.close();
    // }
  }

  showDialog(reason: string) {
    switch (reason) {
      case 'can_not_change_key':
        this.toastrService.clear();
        this.toastrService.info('Uma vez cadastrada a etapa, o tipo não pode ser alterado!', 'Ação inválida', {
          enableHtml: true
        });
        break;
      case 'can_not_change_needScheduling':
        this.toastrService.clear();
        this.toastrService.info('Esta etapa já possui agendamentos!', 'Ação inválida', {
          enableHtml: true
        });
        break;
    }
  }

  buildArrayHtmlOrderedList() {
    let arr;

    // tslint:disable-next-line:max-line-length
    if (this.step && this.step.video && this.step.video.instructions && this.step.video.instructions.htmlOrderedList && this.step.video.instructions.htmlOrderedList.length > 0) {
      arr = this.step.video.instructions.htmlOrderedList.map(item => {
        return this.formBuilder.control(item);
      });
    }

    return arr ? this.formBuilder.array(arr) : this.formBuilder.array([]);
  }

  openModalStatus() {
    this.display = 'none';

    const modal = this.modalService.open(EditStatusModalComponent, { backdrop: 'static', keyboard: false });
    modal.componentInstance.update = false;
    modal.componentInstance.status = {};

    modal.result.then(params => {
      this.display = 'block';
    }).catch(err => {
      this.display = 'block';
    });
  }

  searchDropBoxHtmlOrderedList = (text$: Observable<string>) =>
    text$
      .pipe(
        debounceTime(200),
        distinctUntilChanged(),
        merge(this.focus.htmlOrderedList$),
        merge(this.click.htmlOrderedList$.pipe(
          filter(() => !this.instance.isPopupOpen()))
        ),
        map(term => (term === '' ? this.HTML_ORDERED_LIST :
          this.HTML_ORDERED_LIST.filter(data => data.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 10))
      )

  onSelectDropBoxHtmlOrderedList($event, dataInput: HTMLInputElement) {
    $event.preventDefault();
    this.addDropBoxHtmlOrderedList($event.item, dataInput);
  }

  addDropBoxHtmlOrderedList(data, dataInput: HTMLInputElement) {
    const index = this.HTML_ORDERED_LIST.indexOf(data);

    if (index >= 0) {
      this.HTML_ORDERED_LIST.splice(index, 1);
    }

    dataInput.value = '';

    const attribute = this.form.get('step.video.instructions.htmlOrderedList') as FormArray;
    const inputName = this.form.get('step.video.instructions.inputHtmlOrderedList');

    attribute.push(this.formBuilder.control(data));

    inputName.setValidators(Validators.nullValidator);
    inputName.updateValueAndValidity();
    attribute.markAsTouched();
    attribute.updateValueAndValidity();
  }

  editDropBoxItem(index: number) {
    if (!this.updateArray['htmlOrderedList']) {
      this.updateArray['htmlOrderedList'] = [];
    }

    if (!this.updateArray['htmlOrderedList'][index]) {
      this.updateArray['htmlOrderedList'][index] = {};
    }

    // tslint:disable-next-line:max-line-length
    this.updateArray['htmlOrderedList'][index].selected = this.updateArray['htmlOrderedList'][index].selected ? this.updateArray['htmlOrderedList'][index].selected : true;
  }

  saveDropBoxItem(index: number, type: string) {
    const attribute = this.form.get(type) as FormArray;
    const elemento = document.getElementById('htmlOrderedList_' + index) as HTMLInputElement;

    attribute.at(index).setValue(elemento.value);
    attribute.markAsTouched();
    attribute.updateValueAndValidity();
    this.updateArray['htmlOrderedList'][index].selected = false;
  }

  removeDropBoxHtmlOrderedList(item, type) {
    const attribute = this.form.get(type) as FormArray;
    const index = attribute.controls.indexOf(item);

    if (this.updateArray && this.updateArray['htmlOrderedList'] && this.updateArray['htmlOrderedList'][index]) {
      this.updateArray['htmlOrderedList'][index].selected = false;
      this.updateArray['htmlOrderedList'].slice(index, 1);
    }

    this.HTML_ORDERED_LIST.push(item.value);
    attribute.removeAt(index);

    attribute.markAsTouched();
    attribute.updateValueAndValidity();
  }
}
