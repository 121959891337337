import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class ShowcaseService {
  urlGateway = environment.api_gateway;

  constructor (
    private http: HttpClient
  ) { }

  getInvite(token: string = ''): Observable<any> {
    return this.http.get<any>(`${this.urlGateway}/api/v1/public/company/showcase/invite?token=${token}`);
  }
}
