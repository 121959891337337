import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-info-modal',
  templateUrl: './info-modal.component.html',
  styleUrls: ['./info-modal.component.scss']
})
export class InfoModalComponent implements OnInit {

  @Input() title = '';
  @Input() text = '';
  @Input() btnSuccess = '';
  @Input() btnFail = '';
  @Input() timeout = -1;

  constructor(
    private activeModal: NgbActiveModal,
  ) { }

  ngOnInit() {
  }

  ngOnDestroy() {
  }

  close(param) {
    this.activeModal.close(param);
  }

}
