import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

import {Company} from '../models';
import {environment} from '../../../environments/environment';

@Injectable()
export class CompaniesService {
  url: string = environment.url;
  urlGateway = environment.api_gateway;

  constructor(
    private http: HttpClient
  ) {
  }

  getMy(params = {}): Observable<Company> {
    return this.http.get<Company>(`${this.url}/companies/my`, {params: params});
  }

  updateMy(params): Observable<Company> {
    return this.http.patch<Company>(`${this.url}/companies/my`, params);
  }

  getCompanies(params = {}): Observable<Company[]> {
    return this.http.get<Company[]>(`${this.url}/companies`, {params: params});
  }

  getCulturalTestQuestions(params = {}): Observable<any> {
    return this.http.get<any>(`${this.url}/companies/cultural-test`, {params: params});
  }

  createCompany(params): Observable<Company> {
    return this.http.post<Company>(`${this.url}/companies`, params);
  }

  // LandingPage.
  postCompanyLandingPagePreview(params = {}): Observable<Company> {
    return this.http.post<Company>(`${this.url}/companies/my/landingPage/preview`, params);
  }

  putCompanyAllowHiddenVancancy(params = {}): Observable<Company> {
    return this.http.post<Company>(`${this.url}/companies/allow-hidden-vacancies`, params);
  }

  putCompanyAllowInternalRecruitment(params = {}): Observable<Company> {
    return this.http.post<Company>(`${this.url}/companies/allow-internal-recruitment`, params);
  }

  putCompanyAllowTestModule(params = {}): Observable<Company> {
    return this.http.post<Company>(`${this.url}/companies/allow-module-test`, params);
  }

  putCompanyAllowEducationCourse(params = {}): Observable<Company> {
    return this.http.post<Company>(`${this.url}/companies/allow-education-course`, params);
  }

  putCompanyAllowCreateShowcase(params = {}): Observable<Company> {
    return this.http.post<Company>(`${this.url}/companies/allow-create-showcase`, params);
  }

  putCompanyAllowCreateCertifications(params = {}): Observable<Company> {
    return this.http.post<Company>(`${this.url}/companies/allow-create-certifications`, params);
  }

  postCompanyAllowAuthorizeManagers(params = {}): Observable<Company> {
    return this.http.post<Company>(`${this.url}/companies/allow-create-authorize-managers`, params);
  }

  patchCompanyLandingPage(params = {}): Observable<Company> {
    return this.http.patch<Company>(`${this.url}/companies/my/landingPage`, params);
  }

  postCompanyBlacklist(params): Observable<any> {
    return this.http.post<any>(`${this.url}/companies/my/blacklist`, params);
  }

  sendCroppedImageLandingPage(params = {}): Observable<any> {
    return this.http.post<any>(`${this.url}/companies/my/landingPage/photoGallery`, params);
  }


  requestIntegration(companyId: string): Observable<any> {
    return this.http.post<any>(`${this.url}/companies/request-integration/${companyId}`, {});
  }

  // Showcase
  public getShowcaseCompanies(params = {}): Observable<Company[]> {
    return this.http.get<Company[]>(`${this.url}/companies/showcase`, { params: params });
  };

  public getShowcaseCompanyById(companyId: String, params = {}): Observable<Company> {
    return this.http.get<Company>(`${this.url}/companies/showcase/${companyId}`, {params: params});
  }

  public patchShowcaseCompanyById(companyId: String, params = {}): Observable<any> {
    return this.http.patch<any>(`${this.url}/companies/showcase/${companyId}/resourceBase`, params);
  }

  public patchPartnerShowcaseResource(partnerId: String, params = {}): Observable<Company> {
    return this.http.post<any>(`${this.urlGateway}/api/v1/private/company/partner/${partnerId}/resource`, params);
  }

  public getShowcaseDetailsById(partnerCompanyId: String, params = {}): Observable<Company> {
    return this.http.get<Company>(`${this.urlGateway}/api/v1/private/company/showcase/${partnerCompanyId}`, {params: params});
  }
  // Taqe-Lite

  getCompaniesTalentBase(params = {}): Observable<Company[]> {
    return this.http.get<Company[]>(`${this.url}/companies/talent-base`, { params: params });
  }

  patchVitrinePage(params = {}): Observable<Company> {
    return this.http.patch<Company>(`${this.url}/companies/my/vitrinePage`, params);
  }

  // LandingPage.
  setResourceBase(params = {}): Observable<any> {
    return this.http.post<any>(`${this.urlGateway}/api/company/setresources/v1/`, params);
  }

  addVacancie(params = {}): Observable<any> {
    return this.http.post<any>(`${this.urlGateway}/api/company/add/vacancies/v1/`, params);
  }

  setInvitation(params): Observable<any> {
    return this.http.post<any>(`${this.urlGateway}/api/company/set/invitations/v1/`, params);
  }

  getMe(params = {}): Observable<any> {
    return this.http.get<any>(`${this.urlGateway}/api/company/me/v1/`, params);
  }

  getDiscount(document): Observable<any> {
    return this.http.get<any>(`${this.urlGateway}/api/company/document/partners/check/v1/?document=${document}`, {});
  }

  checkDocument(params = {}): Observable<any> {
    return this.http.post<any>(`${this.urlGateway}/api/company/check/document/v1/`, params);
  }

  updateDocumentPartner(params): Observable<any> {
    return this.http.put<any>(`${this.urlGateway}/api/company/document/partners/v1/${params._id}`, params);
  }

  updateCompany(params): Observable<any> {
    return this.http.put<any>(`${this.urlGateway}/api/company/v1/${params._id}`, params);
  }

  archiveCompany(companyId): Observable<any> {
    return this.http.delete<any>(`${this.urlGateway}/api/company/v1/${companyId}`, {});
  }

  deleteCompany(companyId): Observable<any> {
    return this.http.delete<any>(`${this.urlGateway}/api/company/delete/v1/${companyId}`, {});
  }

  filter(params = {}): Observable<any> {
    return this.http.post<any>(`${this.urlGateway}/api/company/filter/v1/`, params);
  }

  getShowcases(params = {}): Observable<any> {
    return this.http.get<Company[]>(`${this.url}/companies/showcases`, { params: params });
  }

  getCompanyWhatsappNotificationsQuantity(params = {}): Observable<Company> {
    return this.http.get<Company>(`${this.url}/companies/amount-whatsapp-notifications-available`, { params });
  }
}

