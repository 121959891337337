export class User {
  _id: string;
  basicInformation?: {
    name?: string;
    lastName?: string;
    fullName?: string;
    socialName?: string;
    birthday?: Date;
    relationship?: string;
    nationality?: string;
    gender?: string;
    levelEducation?: string;
    about?: string
  };
  source?: {
    user_referral?: any;
  };
  certificates?: any;
  curriculums?: any;
  aboutMe: string;
  photo: string;
  lastInteraction?: Date;
  lastAccess: Date;
  address?: {
    street?: string,
    number?: string,
    complement?: string,
    district?: string,
    city?: string,
    state?: string,
    zipCode?: string,
    country?: string
  };
  competencies: any; //
  contact?: {
    email?: {
      address?: string,
      verified?: boolean
    },
    cellphone?: {
      number?: string;
      whatsApp?: boolean,
      verified?: boolean
    },
    tellphone?: string,
    skype?: string
  };
  skills: {
    portuguese: {
      level1: number,
      level2: number,
      level3: number,
      totalAverage: number
    },
    logic: {
      level1: number,
      level2: number,
      level3: number,
      totalAverage: number
    },
    generalKnowledge: {
      level1: number,
      level2: number,
      level3: number,
      totalAverage: number
    },
    english: {
      level1: number,
      level2: number,
      level3: number,
      totalAverage: number
    },
  };
  professionalExperience: any[];
  volunteering: any[];
  languages: any[];
  softwares: any[];
  education?: any[];
  personality?: {
    indicators: any;
    personalityType: any;
    type: string;
  };
  disabilities?: {
    types?: string[],
    details?: {
      description: string,
      useAnyDevice: {
          option: boolean,
          description: string,
      },
      needSpecialResource: {
          option: boolean,
          description: string,
      },
      usePublicTransport: boolean,
      hasAdaptedVehicle: boolean,
      trainingCourses: string,
      hearingLossLevel: string,
      needsAnResponsible: boolean,
      useLibras: boolean,
      doLipReading: boolean,
      readBraille: boolean
    },
    medicalReports?: any;
  };
  presentationVideo: string;
  identificationDocuments: {
    rg: string,
    cpf: string
  };
  concentratedAttentionTest: any;
  contract: {
    details: any,
    activeContract: any,
    typeContractInternship: any,
    description: any,
    contractDate: any,
  }
}
