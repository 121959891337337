import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

@Injectable()
export class UnitService {
  url: string = environment.url;

  constructor(
    private http: HttpClient
  ) { }

  getUnits(params = {}): Observable<any[]> {
    return this.http.get<any[]>(`${this.url}/units`, params);
  }

  postUnit(params = {}): Observable<any> {
    return this.http.post<any>(`${this.url}/units`, params);
  }

  updateUnit(params = {}): Observable<any>{
    return this.http.patch<any>(`${this.url}/units/update`, params);
  }

  deleteUnit(params = {}): Observable<any>{
    return this.http.patch<any>(`${this.url}/units/delete`, params);
  }

  getAddress(cep = ''): Observable<Record<string, string>> {
    return this.http.get<any>(`${this.url}/public/third-party/get-address?cep=${cep}`); 
  }
}
