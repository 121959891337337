import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

@Injectable()
export class StandardListService {
  url: string = environment.url;

  constructor(
    private http: HttpClient
  ) { }

  getList(params): Observable<any> {
    return this.http.get<any>(`${this.url}/standard-list`, {params: params});
  }
}
