import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'descriptionPendingActivities',
})
export class PendingActivities implements PipeTransform {


  translatedDescriptions = {
    no_idealProfile: 'Não possui perfil ideal preenchido ',
    no_culturalTest: 'Não está com o teste de cultura preenchido',
  };

  transform(value: string): string {
    return this.translatedDescriptions[value];
  }
}


