import { Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { StandardListService } from '../../../services';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, take } from 'rxjs/operators';
import CONSTANTS from '../../../constants/index';

@Component({
  selector: 'app-search-list-input',
  templateUrl: './search-list-input.component.html',
  styleUrls: ['./search-list-input.component.scss']
})
export class SearchListInputComponent implements OnInit, OnChanges {
  @Input() inputName: string; // Nome do input
  @Input() readonly: boolean; // Determina se o campo pode ser alterado.
  @Input() formGroup: FormGroup; // Referencia ao form pai
  @Input() formControlName: string; // Nome do formControlName no form do pai
  @Input() standardListType: string; // Nome da lista a ser procurada na API
  @Input() incomingList: Array<Object>;
  @Output() itemChoosen = new EventEmitter();

  list = [];
  filteredList = [];
  resultArray = []
  inputValueKeeped = '';
  subject: Subject<string> = new Subject();

  constructor(private standardListService: StandardListService) { }

  ngOnInit() {
    this.subject.pipe(
      debounceTime(200),
      distinctUntilChanged(),
    ).subscribe(value => this.searchOnList(value));
  }

  ngOnChanges(changes: SimpleChanges) {
    const { standardListType } = changes;
    if (standardListType && standardListType.previousValue && standardListType.previousValue !== standardListType.currentValue) {
      this.resetVariables();
    }
  }

  resetVariables() {
    this.list = [];
    this.filteredList = [];
    this.formGroup.get(this.formControlName).setValue(''); // Deixa o campo em branco
    this.formGroup.get(this.formControlName).markAsPristine(); // Remove o "status" dirty
  }

  getStandardLists() {
    if (!this.incomingList && !this.formGroup.get(this.formControlName).dirty) {
      this.standardListService.getList({ type: this.standardListType })
        .pipe(take(1))
        .subscribe(list => {
          if (!Object.keys(list).length) {
            list = this.getStandardListFromConstant();
          }
          this.list = list;
          this.getEventInputValue();
        });
    } else {
      this.list = this.incomingList && this.incomingList.length ? this.incomingList : this.list;
    }
  }

  getStandardListFromConstant() {
    if (this.standardListType === 'schoolingArea') {
      return CONSTANTS.AREA.map(area => ({ area: { name: area } }));
    }
  }

  getEventInputValue(event: KeyboardEvent = null) {
    if (arguments.length === 0) {
      this.searchOnList(this.inputValueKeeped);
      return;
    }

    if (this.list && this.list.length) {
      this.inputValueKeeped = '';
      const value = (<HTMLInputElement>event.target).value;
      this.subject.next(value); // Fará debounceTime
    } else {
      this.inputValueKeeped += event.key; // Se a lista estiver sendo carregada, guardar o valor digitado para pesquisar logo depois
    }
  }

  searchOnList(value) {
    const defaultObj = [
      {
        schoolCourse: {
          name: 'não encontrado'
        }
      }
    ]

    this.list = this.list || [];

    const regex = new RegExp(`${value}`, 'i');
    this.filteredList = this.list.filter((item: any) => (value.trim() !== '' && item[this.getItemType(item)].name.match(regex)));
    this.filteredList.length === 0 ? this.filteredList = defaultObj : this.filteredList = this.filteredList;
  }

  getItemType(item) {
    if (item.schoolCourse) {
      return 'schoolCourse';
    }
    if (item.institution) {
      return 'institution';
    }
    if (item.specialization) {
      return 'specialization';
    }
    if (item.professionalArea) {
      return 'professionalArea';
    }
    if (item.area) {
      return 'area';
    }
  }

  chooseItem(item) {
    this.formGroup.get(this.formControlName).setValue(item[this.getItemType(item)].name);
    this.resetFilteredList();

    this.checkValue();
    this.itemChoosen.emit({ item, type: this.formControlName });
  }

  resetFilteredList() {
    this.filteredList = [];
  }

  checkValue(input: { [value: string]: string } = {}) {
    this.list = this.list || [];
    const value = this.formGroup.get(this.formControlName).value;
    const hasValueOnList = this.list.some(item => item && item[this.getItemType(item)] && item[this.getItemType(item)].name === value);
    if (!hasValueOnList && !this.filteredList.length) {
      input.value = '';
      this.formGroup.get(this.formControlName).setValue('');
    }
  }
}
