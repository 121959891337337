import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable()
export class MatchmakingService {
  private apiUrl: string = environment.url;
  private apiGatewayUrl: string = environment.api_gateway;

  constructor(private http: HttpClient) {}

  public getMatchmaking(vacancyId, useMicroservice) {
    if (!useMicroservice) {
      return this.apiGetMatchmaking(vacancyId);
    }

    return this.msGetMatchmaking(vacancyId);
  }

  public putMatchMaking(vacancyId, useMicroservice, params) {
    if (!useMicroservice) {
      return this.apiPutMatchMaking(vacancyId, params);
    }

    return this.msPutMatchMaking(vacancyId, params);
  }

  public simulate(vacancyId, useMicroservice, params) {
    if (!useMicroservice) {
      return this.apiSimulate(vacancyId, params);
    }

    return this.msSimulate(vacancyId, params);
  }

  private apiGetMatchmaking(vacancyId) {
    return this.http.get(`${this.apiUrl}/vacancies/${vacancyId}/matchmaking`);
  }

  private apiPutMatchMaking(vacancyId, params) {
    return this.http.put(`${this.apiUrl}/vacancies/${vacancyId}/matchmaking`, params);
  }

  private apiSimulate(vacancyId, params) {
    return this.http.post(`${this.apiUrl}/vacancies/${vacancyId}/matchmaking/simulate`, params);
  }

  private msGetMatchmaking(vacancyId) {
    return this.http.get(`${this.apiGatewayUrl}/api/v1/private/matchmaking/vacancies/${vacancyId}`);
  }

  private msPutMatchMaking(vacancyId, params) {
    return this.http.put(`${this.apiGatewayUrl}/api/v1/private/matchmaking/vacancies/${vacancyId}`, params);
  }

  private msSimulate(vacancyId, params) {
    return this.http.post(`${this.apiGatewayUrl}/api/v1/private/matchmaking/vacancies/${vacancyId}/simulate`, params);
  }
}
