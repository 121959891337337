import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class AdditionalResourcesService {
  urlGateway = environment.api_gateway;

  constructor(
    private http: HttpClient
  ) { }

  filter(params = {}): Observable<any> {
    return this.http.post<any>(`${this.urlGateway}/api/company/addresources/filter/v1/`, params);
  }
}
