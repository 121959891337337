import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import CustomEncoder from '../core/helpers/custom-encoder';
import { IGetProfileCharacteristicResponse } from '../core/interfaces';
import { IVacancyRequestResponse } from '../core/interfaces/http-responses';
import { SupplementaryQuestion } from '../core/models/supplementary-question';
import { EmployeeTest, Process, ScreeningFilter, Step, Vacancy } from '../core/models/vacancy';

@Injectable()
export class VacanciesService {
  public configSource = new BehaviorSubject<any>(null);
  config = this.configSource.asObservable();

  public stateConfigSource = new BehaviorSubject<any>(null);
  stateConfig = this.stateConfigSource.asObservable();

  private url: string = environment.url;
  private apiGatewayUrl: string = environment.api_gateway;

  constructor(
    private http: HttpClient
  ) { }

  setConfig(obj) {
    this.configSource.next(obj);
  }

  setStateConfig(obj) {
    this.stateConfigSource.next(obj);
  }

  /**
   * Retorna apenas as etapas do processo válida para a vitrine.
   * @param steps
   * @returns
   */
  public setStepsValidForShowCase(steps: Step[]): Step[] {
    const showcaseIndex: number = steps.findIndex((step: any) => String(step.key) === 'showcase');

    steps = steps.slice(showcaseIndex, steps.length);
    steps[0].name = 'Base de candidatos';

    return steps;
  }

  getVacancies(params = {}): Observable<any> {
    let httpParams = new HttpParams({ encoder: new CustomEncoder() });

    for (const key of Object.keys(params)) {
      httpParams = httpParams.append(key, params[key] instanceof Object ? JSON.stringify(params[key]) : params[key]);
    }

    return this.http.get<any>(`${this.url}/vacancies`, { params: httpParams });
  }

  getVacanciesAvailableToNominate(params = {}): Observable<any> {
    let httpParams = new HttpParams({ encoder: new CustomEncoder() });

    for (const key of Object.keys(params)) {
      httpParams = httpParams.append(key, params[key] instanceof Object ? JSON.stringify(params[key]) : params[key]);
    }

    return this.http.get<any>(`${this.url}/vacancies/to-nominate`, { params: httpParams });
  }

  getVacancyById(vacancyId, params = {}): Observable<Vacancy> {
    let httpParams = new HttpParams({ encoder: new CustomEncoder() });

    for (const key of Object.keys(params)) {
      httpParams = httpParams.append(key, params[key] instanceof Object ? JSON.stringify(params[key]) : params[key]);
    }

    return this.http.get<Vacancy>(`${this.url}/vacancies/${vacancyId}`, { params: httpParams });
  }

  getVacancyPromote(vacancyId, params = {}): Observable<Vacancy> {
    let httpParams = new HttpParams({ encoder: new CustomEncoder() });

    for (const key of Object.keys(params)) {
      httpParams = httpParams.append(key, params[key] instanceof Object ? JSON.stringify(params[key]) : params[key]);
    }

    return this.http.get<Vacancy>(`${this.url}/vacancies/${vacancyId}/promote`, { params: httpParams });
  }

  patchMatchmakingVacancy(vacancyId, params = {}): Observable<any> {
    return this.http.patch<any>(`${this.url}/vacancies/${vacancyId}/matchmaking`, params);
  }

  postVacancyPreview(params = {}): Observable<any> {
    return this.http.post<any>(`${this.url}/vacancies/preview`, params);
  }

  getUtmParamsByVacancy(vacancyId, params = {}): Observable<any> {
    return this.http.get<any>(`${this.url}/vacancies/${vacancyId}/utm-params`, { params: params });
  }

  getVacancyProcess(vacancyId): Observable<Process> {
    return this.http.get<Process>(`${this.url}/vacancies/${vacancyId}/process`);
  }

  patchVacancyProcess(vacancyId, params): Observable<Process> {
    return this.http.patch<Process>(`${this.url}/vacancies/${vacancyId}/process`, params);
  }
  deleteVacancyProcess(vacancyId, params = {}): Observable<Process> {
    return this.http.patch<Process>(`${this.url}/vacancies/${vacancyId}/delete`, params);
  }

  getResponsableOfProcess(): Observable<any> {
    return this.http.get<any>(`${this.url}/vacancies/responsableOfProcess`);
  }

  getVacancyCandidatesIds(vacancyId, params): Observable<Array<string>> {
    let httpParams = new HttpParams({ encoder: new CustomEncoder() });

    for (const key of Object.keys(params)) {
      httpParams = httpParams.append(key, params[key] instanceof Object ? JSON.stringify(params[key]) : params[key]);
    }

    return this.http.get<Array<string>>(`${this.url}/vacancies/${vacancyId}/candidates-count`, { params: httpParams });
  }

  getVacancyScreeningFilters(vacancyId): Observable<ScreeningFilter[]> {
    return this.http.get<ScreeningFilter[]>(`${this.url}/vacancies/${vacancyId}/screening-filters`);
  }

  getVacancySteps(vacancyId, params = {}): Observable<any> {
    return this.http.get<any>(`${this.url}/vacancies/${vacancyId}/dashboards/vacancy-process-steps`, params);
  }

  getAllReports(vacancyId, params = {}, types): Observable<any> {
    let httpParams = new HttpParams({ encoder: new CustomEncoder() });

    for (const key of Object.keys(params)) {
      httpParams = httpParams.append(key, params[key] instanceof Object ? JSON.stringify(params[key]) : params[key]);
    }

    let typeKeys = [];

    Object.keys(types).forEach(type => {
      typeKeys.push(this.http.get<any>(`${this.url}/vacancies/${vacancyId}/dashboards/vacancy-candidate-${type}-by-step`, { params: httpParams }));
    });

    return forkJoin(typeKeys);
  }

  getSupplementaryQuestionsReports(vacancyId, params = {}, supplementaryQuestionsId): Observable<any> {
    let httpParams = new HttpParams({ encoder: new CustomEncoder() });

    for (const key of Object.keys(params)) {
      httpParams = httpParams.append(key, params[key] instanceof Object ? JSON.stringify(params[key]) : params[key]);
    }

    let typeKeys = [];

    supplementaryQuestionsId.forEach(supplementaryQuestionId => {
      typeKeys.push(this.http.get<any>(`${this.url}/vacancies/${vacancyId}/dashboards/vacancy-candidate-supplementary-questions-new-by-step/unit-supplementary/${supplementaryQuestionId}`, { params: httpParams }));
    });

    return forkJoin(typeKeys);
  }

  getVacancyCandidateTotal(vacancyId, params = {}): Observable<any> {
    let httpParams = new HttpParams({ encoder: new CustomEncoder() });

    for (const key of Object.keys(params)) {
      httpParams = httpParams.append(key, params[key] instanceof Object ? JSON.stringify(params[key]) : params[key]);
    }

    return this.http.get<any>(`${this.url}/vacancies/${vacancyId}/dashboards/vacancy-candidate-total-by-step`, { params: httpParams });
  }

  getVacancyCandidateFeedbackByStep(vacancyId, params = {}): Observable<any> {
    let httpParams = new HttpParams({ encoder: new CustomEncoder() });

    for (const key of Object.keys(params)) {
      httpParams = httpParams.append(key, params[key] instanceof Object ? JSON.stringify(params[key]) : params[key]);
    }

    return this.http.get<any>(`${this.url}/vacancies/${vacancyId}/dashboards/vacancy-candidate-scheduling-feedback-by-step`, { params: httpParams });
  }

  getVacancyCandidateNotesFromTestByStep(vacancyId, params = {}): Observable<any> {
    let httpParams = new HttpParams({ encoder: new CustomEncoder() });

    for (const key of Object.keys(params)) {
      httpParams = httpParams.append(key, params[key] instanceof Object ? JSON.stringify(params[key]) : params[key]);
    }

    return this.http.get<any>(`${this.url}/vacancies/${vacancyId}/dashboards/vacancy-candidate-notes-from-test-by-step`, { params: httpParams });
  }

  getVacancyCandidateStatusFromTestByStep(vacancyId, params = {}): Observable<any> {
    let httpParams = new HttpParams({ encoder: new CustomEncoder() });

    for (const key of Object.keys(params)) {
      httpParams = httpParams.append(key, params[key] instanceof Object ? JSON.stringify(params[key]) : params[key]);
    }

    return this.http.get<any>(`${this.url}/vacancies/${vacancyId}/dashboards/vacancy-candidate-status-of-test-by-step`, { params: httpParams });
  }

  getVacancyCandidateAgesByStep(vacancyId, params = {}): Observable<any> {
    let httpParams = new HttpParams({ encoder: new CustomEncoder() });

    for (const key of Object.keys(params)) {
      httpParams = httpParams.append(key, params[key] instanceof Object ? JSON.stringify(params[key]) : params[key]);
    }

    return this.http.get<any>(`${this.url}/vacancies/${vacancyId}/dashboards/vacancy-candidate-ages-by-step`, { params: httpParams });
  }

  getVacancyGendersByStep(vacancyId, params = {}): Observable<any> {
    let httpParams = new HttpParams({ encoder: new CustomEncoder() });

    for (const key of Object.keys(params)) {
      httpParams = httpParams.append(key, params[key] instanceof Object ? JSON.stringify(params[key]) : params[key]);
    }

    return this.http.get<any>(`${this.url}/vacancies/${vacancyId}/dashboards/vacancy-candidate-genders-by-step`, { params: httpParams });
  }

  getVacancyPersonalityByStep(vacancyId, params = {}): Observable<any> {
    let httpParams = new HttpParams({ encoder: new CustomEncoder() });

    for (const key of Object.keys(params)) {
      httpParams = httpParams.append(key, params[key] instanceof Object ? JSON.stringify(params[key]) : params[key]);
    }

    return this.http.get<any>(`${this.url}/vacancies/${vacancyId}/dashboards/vacancy-candidate-personalities-by-step`, { params: httpParams });
  }

  getVacancySchoolingByStep(vacancyId, params = {}): Observable<any> {
    let httpParams = new HttpParams({ encoder: new CustomEncoder() });

    for (const key of Object.keys(params)) {
      httpParams = httpParams.append(key, params[key] instanceof Object ? JSON.stringify(params[key]) : params[key]);
    }

    return this.http.get<any>(`${this.url}/vacancies/${vacancyId}/dashboards/vacancy-candidate-schooling-levels-by-step`, { params: httpParams });
  }

  getVacancyCandidateTestPortugueseByStep(vacancyId, params = {}): Observable<any> {
    let httpParams = new HttpParams({ encoder: new CustomEncoder() });

    for (const key of Object.keys(params)) {
      httpParams = httpParams.append(key, params[key] instanceof Object ? JSON.stringify(params[key]) : params[key]);
    }

    return this.http.get<any>(`${this.url}/vacancies/${vacancyId}/dashboards/vacancy-candidate-test-portuguese-by-step`, { params: httpParams });
  }

  getVacancyCandidateTestLogicByStep(vacancyId, params = {}): Observable<any> {
    let httpParams = new HttpParams({ encoder: new CustomEncoder() });

    for (const key of Object.keys(params)) {
      httpParams = httpParams.append(key, params[key] instanceof Object ? JSON.stringify(params[key]) : params[key]);
    }

    return this.http.get<any>(`${this.url}/vacancies/${vacancyId}/dashboards/vacancy-candidate-test-logic-by-step`, { params: httpParams });
  }

  getVacancyCandidateTestEnglishByStep(vacancyId, params = {}): Observable<any> {
    let httpParams = new HttpParams({ encoder: new CustomEncoder() });

    for (const key of Object.keys(params)) {
      httpParams = httpParams.append(key, params[key] instanceof Object ? JSON.stringify(params[key]) : params[key]);
    }

    return this.http.get<any>(`${this.url}/vacancies/${vacancyId}/dashboards/vacancy-candidate-test-english-by-step`, { params: httpParams });
  }

  getVacancyCitiesByStep(vacancyId, params = {}): Observable<any> {
    let httpParams = new HttpParams({ encoder: new CustomEncoder() });

    for (const key of Object.keys(params)) {
      httpParams = httpParams.append(key, params[key] instanceof Object ? JSON.stringify(params[key]) : params[key]);
    }

    return this.http.get<any>(`${this.url}/vacancies/${vacancyId}/dashboards/vacancy-candidate-cities-by-step`, { params: httpParams });
  }

  getVacancyNotesFromEssayByStep(vacancyId, params = {}): Observable<any> {
    let httpParams = new HttpParams({ encoder: new CustomEncoder() });

    for (const key of Object.keys(params)) {
      httpParams = httpParams.append(key, params[key] instanceof Object ? JSON.stringify(params[key]) : params[key]);
    }

    return this.http.get<any>(`${this.url}/vacancies/${vacancyId}/dashboards/vacancy-candidate-notes-from-essay-by-step`, { params: httpParams });
  }

  getVacancyCandidateStatusOfEssayByStep(vacancyId, params = {}): Observable<any> {
    let httpParams = new HttpParams({ encoder: new CustomEncoder() });

    for (const key of Object.keys(params)) {
      httpParams = httpParams.append(key, params[key] instanceof Object ? JSON.stringify(params[key]) : params[key]);
    }

    return this.http.get<any>(`${this.url}/vacancies/${vacancyId}/dashboards/vacancy-candidate-status-of-essay-by-step`, { params: httpParams });
  }

  getVacancyCandidateStatusVideosByStep(vacancyId, params = {}): Observable<any> {
    let httpParams = new HttpParams({ encoder: new CustomEncoder() });

    for (const key of Object.keys(params)) {
      httpParams = httpParams.append(key, params[key] instanceof Object ? JSON.stringify(params[key]) : params[key]);
    }

    return this.http.get<any>(`${this.url}/vacancies/${vacancyId}/dashboards/vacancy-candidate-videos-status-by-step`, { params: httpParams });
  }

  getVacancyCandidateDocumentation(vacancyId, params = {}): Observable<any> {
    let httpParams = new HttpParams({ encoder: new CustomEncoder() });

    for (const key of Object.keys(params)) {
      httpParams = httpParams.append(key, params[key] instanceof Object ? JSON.stringify(params[key]) : params[key]);
    }

    return this.http.get<any>(`${this.url}/vacancies/${vacancyId}/dashboards/vacancy-candidate-status-of-documentation-by-step`, { params: httpParams });
  }

  getVacancyCandidatePreApproved(vacancyId, params = {}): Observable<any> {
    let httpParams = new HttpParams({ encoder: new CustomEncoder() });

    for (const key of Object.keys(params)) {
      httpParams = httpParams.append(key, params[key] instanceof Object ? JSON.stringify(params[key]) : params[key]);
    }

    return this.http.get<any>(`${this.url}/vacancies/${vacancyId}/dashboards/vacancy-candidate-pre-approved-by-step`, { params: httpParams });
  }

  getScheduleById({ vacancyId, scheduleId, stepId }) {
    return this.http.get(`${this.url}/vacancies/${vacancyId}/schedule/${stepId}/${scheduleId}`);
  }

  getScheduleCandidates(params: any) {
    let httpParams = new HttpParams({ encoder: new CustomEncoder() });

    for (const key of Object.keys(params)) {
      httpParams = httpParams.append(key, params[key] instanceof Object ? JSON.stringify(params[key]) : params[key]);
    }

    return this.http.get(`${this.url}/vacancies/${params.vacancyId}/schedule/${params.stepId}/${params.scheduleId}/candidates`, { params: httpParams });
  }

  getVacancyCandidateSchedulingFunnel(vacancyId, params = {}): Observable<any> {
    let httpParams = new HttpParams({ encoder: new CustomEncoder() });

    for (const key of Object.keys(params)) {
      httpParams = httpParams.append(key, params[key] instanceof Object ? JSON.stringify(params[key]) : params[key]);
    }

    return this.http.get<any>(`${this.url}/vacancies/${vacancyId}/dashboards/vacancy-candidate-scheduling-funnel-by-step`, { params: httpParams });
  }

  getVacancyCandidateSchedulingStatus(vacancyId, params = {}): Observable<any> {
    let httpParams = new HttpParams({ encoder: new CustomEncoder() });

    for (const key of Object.keys(params)) {
      httpParams = httpParams.append(key, params[key] instanceof Object ? JSON.stringify(params[key]) : params[key]);
    }

    return this.http.get<any>(`${this.url}/vacancies/${vacancyId}/dashboards/vacancy-candidate-scheduling-status-by-step`, { params: httpParams });
  }

  getVacancyCandidateSupplementaryQuestionsByStep(vacancyId, params = {}): Observable<any> {
    let httpParams = new HttpParams({ encoder: new CustomEncoder() });

    for (const key of Object.keys(params)) {
      httpParams = httpParams.append(key, params[key] instanceof Object ? JSON.stringify(params[key]) : params[key]);
    }

    return this.http.get<any>(`${this.url}/vacancies/${vacancyId}/dashboards/vacancy-candidate-supplementary-questions-by-step`, { params: httpParams });
  }

  getVacancyCandidateComparisonByStep(vacancyId, params = {}): Observable<any> {
    let httpParams = new HttpParams({ encoder: new CustomEncoder() });

    for (const key of Object.keys(params)) {
      httpParams = httpParams.append(key, params[key] instanceof Object ? JSON.stringify(params[key]) : params[key]);
    }

    return this.http.get<any>(`${this.url}/vacancies/${vacancyId}/dashboards/vacancy-candidate-comparison-between-applications-by-step`, { params: httpParams });
  }

  postVacancyScreeningFilter(vacancyId, params = {}): Observable<ScreeningFilter> {
    return this.http.post<ScreeningFilter>(`${this.url}/vacancies/${vacancyId}/screening-filters`, params);
  }

  deleteVacancyScreeningFilters(vacancyId, filterId) {
    return this.http.delete(`${this.url}/vacancies/${vacancyId}/screening-filters/${filterId}`);
  }

  getSimilarVacancies(params = {}): Observable<any[]> {
    let httpParams = new HttpParams({ encoder: new CustomEncoder() });

    for (const key of Object.keys(params)) {
      httpParams = httpParams.append(key, params[key] instanceof Object ? JSON.stringify(params[key]) : params[key]);
    }

    return this.http.get<any[]>(`${this.url}/vacancies/similar-vacancies`, { params: httpParams });
  }

  getVacanciesTest(companyId, params = {}): Observable<any[]> {
    return this.http.get<any[]>(`${this.url}/vacancies/test/${companyId}`);
  }

  getVacanciesCreators(params = {}): Observable<any[]> {
    return this.http.get<any[]>(`${this.url}/vacancies/creators`);
  }

  getScheduleCreators(vacancyId, params = {}): Observable<any[]> {
    return this.http.get<any[]>(`${this.url}/vacancies/${vacancyId}/creators/schedule`);
  }

  postVacancy(params: any = {}): Observable<any> {
    //  Etapa: Campos que não podem ser enviados para o back-end.
    delete params.unitsId;
    delete params['type_helper'];

    if (params['vacancyBase'] && params['vacancyBase']['_id']) {
      params.vacancyBaseId = params['vacancyBase']['_id'];

      delete params['vacancyBase'];
    }

    return this.http.post<any>(`${this.url}/vacancies`, params);
  }

  patchVacancyFavorite(vacancyId, params = {}): Observable<any> {
    return this.http.patch<any>(`${this.url}/vacancies/${vacancyId}/favorite`, params);
  }

  patchVacancy(vacancyId, params: any = {}): Observable<any> {
    //  Etapa: Campos que não podem ser enviados para o back-end.
    delete params.unitsId;
    delete params['type_helper'];

    if (params['vacancyBase'] && params['vacancyBase']['_id']) {
      params.vacancyBaseId = params['vacancyBase']['_id'];

      delete params['vacancyBase'];
    }

    return this.http.patch<any>(`${this.url}/vacancies/${vacancyId}`, params);
  }

  changeStatusVacancy(vacancyId, params = {}): Observable<any> {
    return this.http.patch<any>(`${this.url}/vacancies/${vacancyId}/change-status`, params);
  }

  setStatusVacancy(vacancyId, params = {}): Observable<any> {
    return this.http.patch<any>(`${this.url}/vacancies/${vacancyId}/status`, params);
  }

  patchLogoAndCover(vacancyId, params = {}): Observable<any> {
    return this.http.patch<any>(`${this.url}/vacancies/${vacancyId}/logo-and-cover`, params);
  }

  saveOrPublishVacancy(vacancyId, params = {}): Observable<any> {
    return this.http.patch<any>(`${this.url}/vacancies/${vacancyId}/save-or-publish`, params);
  }

  saveVacancyCoverAndLogo(vacancyId, params = {}): Observable<any> {
    return this.http.patch<any>(`${this.url}/vacancies/${vacancyId}/save-cover-logo`, params);
  }

  postCloseVacancy(vacancyId, params = {}): Observable<any> {
    return this.http.post<any>(`${this.url}/vacancies/${vacancyId}/close`, params);
  }

  bulkCloseTheVacancy(vacancyId, params = {}): Observable<any> {
    return this.http.post<any>(`${this.url}/vacancies/${vacancyId}/close/dispense-candidates`, params);
  }

  changeCandidacyStatus(vacancyId, params = {}): Observable<any> {
    return this.http.post<any>(`${this.url}/vacancies/${vacancyId}/change-candidacy-status`, params);
  }

  postCancelVacancy(vacancyId, params = {}): Observable<any> {
    return this.http.post<any>(`${this.url}/vacancies/${vacancyId}/cancel`, params);
  }

  bulkCancelTheVacancy(vacancyId, params = {}): Observable<any> {
    return this.http.post<any>(`${this.url}/vacancies/${vacancyId}/cancel/dispense-candidates`, params);
  }

  createSchedule(vacancyId, stepId, params = {}) {
    return this.http.post<any>(`${this.url}/vacancies/${vacancyId}/steps/${stepId}/schedule`, params);
  }

  updateSchedule(vacancyId, stepId, params = {}) {
    return this.http.patch<any>(`${this.url}/vacancies/${vacancyId}/steps/${stepId}/schedule`, params);
  }

  deleteSchedule(vacancyId, stepId, params = {}) {
    return this.http.patch<any>(`${this.url}/vacancies/${vacancyId}/steps/${stepId}/schedule/delete`, params);
  }

  updateSchedules(vacancyId, params = {}) {
    return this.http.patch<any>(`${this.url}/vacancies/${vacancyId}/schedule`, params);
  }

  getVacanciesSchedules(vacancyId, params = {}): Observable<any> {
    let httpParams = new HttpParams({ encoder: new CustomEncoder() });
    for (const key of Object.keys(params)) {
      httpParams = httpParams.append(key, params[key] instanceof Object ? JSON.stringify(params[key]) : params[key]);
    }
    return this.http.get<any>(`${this.url}/vacancies/${vacancyId}/schedule`, { params: httpParams });
  }

  postSupplementaryQuestion(vacancyId, params = {}): Observable<SupplementaryQuestion[]> {
    return this.http.post<SupplementaryQuestion[]>(`${this.url}/vacancies/${vacancyId}/supplementary-question`, params);
  }

  getCheckUserReasonDidNotMatch(vacancyId, params = {}): Observable<any> {
    return this.http.get<any>(`${this.url}/vacancies/${vacancyId}/check-user-reason-did-not-match`, { params: params });
  }

  getIdealProfile(vacancyId): Observable<any> {
    return this.http.get<any>(`${this.url}/vacancies/${vacancyId}/ideal-profile`);
  }

  createVacancyRequest(params = {}) {
    return this.http.post<any>(`${this.url}/vacancies/create-vacancy-request`, params);
  }

  getVacancyRequest(vacancyRequestId) {
    return this.http.get<any>(`${this.url}/vacancies/${vacancyRequestId}/vacancy-request`);
  }

  updateVacancyRequest(vacancyRequestId, params = {}) {
    return this.http.patch<any>(`${this.url}/vacancies/${vacancyRequestId}/approved-vacancy-request`, params);
  }

  getVacancyRequests(params = {}): Observable<IVacancyRequestResponse> {
    let httpParams = new HttpParams({ encoder: new CustomEncoder() });

    for (const key of Object.keys(params)) {
      httpParams = httpParams.append(key, params[key] instanceof Object ? JSON.stringify(params[key]) : params[key]);
    }

    return this.http.get<IVacancyRequestResponse>(`${this.url}/vacancies/vacancy-request`, { params: httpParams });
  }

  getVacancyCandidateSources(vacancyRequestId, params = {}) {
    return this.http.get<any>(`${this.url}/vacancies/${vacancyRequestId}/disclosures/vacancy-candidate-general-data`, params);
  }

  getVacancyRequestManagersRecruiter(vacancyRequestId) {
    return this.http.get<any>(`${this.url}/vacancies/${vacancyRequestId}/vacancy-request-managers-recruiter`);
  }

  putIdealProfile({ vacancyId, idealProfile, criteriasChanged }): Observable<any> {
    return this.http.put<any>(`${this.url}/vacancies/${vacancyId}/ideal-profile`, { idealProfile, criteriasChanged });
  }
  /* EmployeesTest */
  getVacancyEmployeesTests(vacancyId): Observable<EmployeeTest[]> {
    return this.http.get<EmployeeTest[]>(`${this.url}/vacancies/${vacancyId}/employees-tests`);
  }

  postVacancyEmployeeTest(vacancyId, params): Observable<EmployeeTest> {
    return this.http.post<EmployeeTest>(`${this.url}/vacancies/${vacancyId}/employees-tests`, params);
  }

  resendVacancyEmployeeTestEmail(vacancyId, testId) {
    return this.http.post(`${this.url}/vacancies/${vacancyId}/employees-tests/${testId}/resend-email`, {});
  }

  getEmployeesTestById(employeeTestId, params = {}): Observable<EmployeeTest> {
    return this.http.get<EmployeeTest>(`${this.url}/public/employees-tests/${employeeTestId}`, { params: params });
  }

  getOutreachSites(vacancyId) {
    return this.http.get(`${this.url}/vacancies/${vacancyId}`);
  }

  patchVacancyById(vacancyId, outreachSites) {
    return this.http.patch(`${this.url}/vacancies/${vacancyId}/outreachSites`, outreachSites);
  }

  getVacancyGroupAccess(params = {}): Observable<any> {
    return this.http.get<any>(`${this.url}/vacancy-access`, { params: params });
  }

  postVacancyGroupAccess(params = {}): Observable<any> {
    return this.http.post<any>(`${this.url}/vacancy-access`, { params: params });
  }

  patchVacancyGroupAccess(vacancyGroupAccessId, params = {}): Observable<any> {
    return this.http.patch<any>(`${this.url}/vacancy-access/${vacancyGroupAccessId}`, params);
  }

  patchGroupAccessInUserManager(vacancyGroupAccessId, params = {}): Observable<any> {
    return this.http.patch<any>(`${this.url}/vacancy-access/${vacancyGroupAccessId}/add-users`, params);
  }

  patchVacanciesAccessActive(vacancyGroupAccessId, params = {}): Observable<any> {
    return this.http.patch<any>(`${this.url}/vacancy-access/${vacancyGroupAccessId}/status`, params);
  }

  // TESTE DE CULTURA DA VAGA.
  getVacancyCulturalTest(vacancyId, params = {}): Observable<any> {
    return this.http.get<any>(`${this.url}/vacancies/${vacancyId}/cultural-test`, { params: params });
  }
  getVacancyCulturalTests(vacancyId, params = {}): Observable<any> {
    return this.http.get<any>(`${this.url}/vacancies/${vacancyId}/cultural-test-company`, { params: params });
  }

  patchVacancyCulturalTest(vacancyId, params = {}): Observable<any> {
    return this.http.patch<any>(`${this.url}/vacancies/${vacancyId}/cultural-test`, params);
  }

  getProfileCharacteristics(): Observable<IGetProfileCharacteristicResponse> {
    return this.http.get<IGetProfileCharacteristicResponse>(`assets/http-responses/profile-characteristics.json`).pipe(take(1));
  }

  getCulturalTestQuestions(): Observable<any> {
    return this.http.get<any>(`assets/http-responses/cultural-test.json`).pipe(take(1));
  }

  getVacancyRecommendedPersonalities(vacancyId, params = {}): Observable<any> {
    return this.http.get<any>(`${this.url}/vacancies/${vacancyId}/recommended-personalities`, { params: params });
  }

  getVacanciesProfile(vacancyId, params = {}): Observable<Vacancy> {
    return this.http.get<Vacancy>(`${this.url}/vacancies/${vacancyId}/profile`, { params: params });
  }

  patchVacancyProfile(vacancyId, params): Observable<Vacancy> {
    return this.http.patch<Vacancy>(`${this.url}/vacancies/${vacancyId}/profile`, params);
  }

  patchVacancyExcluded(vacancyId, params): Observable<Vacancy> {
    return this.http.patch<Vacancy>(`${this.url}/vacancies/${vacancyId}/excluded`, params);
  }

  postExportVacanciesNewListOld(companyId, params = {}): Observable<any> {
    return this.http.post<any[]>(`${this.url}/vacancies/${companyId}/export-new-vacancy-list`, { filters: params });
  }

  postExportVacanciesNewList(params = {}): Observable<any> {
    return this.http.post<any[]>(`${this.apiGatewayUrl}/api/v1/private/report/manager/vacancy`, params);
  }

  postExportCandidatesHiredNominee(params = {}): Observable<any> {
    return this.http.post<any[]>(`${this.apiGatewayUrl}/api/v1/private/report/manager/vacancy/candidates-hired-nominees-private-vacancy`, params);
  }

  postExportCandidatesNominee(params = {}): Observable<any> {
    return this.http.post<any[]>(`${this.apiGatewayUrl}/api/v1/private/report/manager/vacancy/candidates-nominees-private-vacancy`, params);
  }

  getEssayThemes(): Observable<any> {
    return this.http.get<any>(`${this.url}/vacancies/essayThemes`);
  }

  getSendEssays(): Observable<any> {
    return this.http.get<any>(`${this.url}/vacancies/sendEssay`);
  }

  getEssayCorrections(): Observable<any> {
    return this.http.get<any>(`${this.url}/vacancies/essayCorrections`);
  }

  getEssayMotivatingTexts(): Observable<any> {
    return this.http.get<any>(`${this.url}/vacancies/essayMotivatingTexts`);
  }

  getEssayCandidates(): Observable<any> {
    return this.http.get<any>(`${this.url}/vacancies/essayCandidates`);
  }

  getEssayClasses(): Observable<any> {
    return this.http.get<any>(`${this.url}/vacancies/essayClasses`);
  }

  getEssayUnits(): Observable<any> {
    return this.http.get<any>(`${this.url}/vacancies/essayUnits`);
  }

  gambiarraParaGreenhouse(companyId: string, vacancyId: string): Observable<any> {
    return this.http.post<any[]>(`${this.url}/integrations/greenhouse/${companyId}/${vacancyId}/test/candidates`, {});
  }

  setAuthorizedManagers(vacancyId: string, params = {}): Observable<any> {
    return this.http.patch<any>(`${this.url}/vacancies/${vacancyId}/authorized-managers/`, params);
  }

  getAuthorizedManagers(vacancyId: string,): Observable<any> {
    return this.http.get<any>(`${this.url}/vacancies/${vacancyId}/authorized-managers/`);
  }

  // Taqe-Lite
  getVacanciesTalentBase(params = {}): Observable<any> {
    let httpParams = new HttpParams({ encoder: new CustomEncoder() });

    for (const key of Object.keys(params)) {
      httpParams = httpParams.append(key, params[key] instanceof Object ? JSON.stringify(params[key]) : params[key]);
    }

    return this.http.get<any>(`${this.url}/vacancies/talent-base`, { params: httpParams });
  }

  getVacanyTalentBaseById(vacancyId, params = {}): Observable<Vacancy> {
    return this.http.get<Vacancy>(`${this.url}/vacancies/talent-base/${vacancyId}`, { params: params });
  }

  getCountAllInProcess(): Observable<any> {
    return this.http.get<any>(`${this.apiGatewayUrl}/api/reports/vacancy/candidates/count/all/inprocess/v1/`);
  }

  getCountAllInScheduleSteps(): Observable<any> {
    return this.http.get<any>(`${this.apiGatewayUrl}/api/reports/vacancy/candidates/count/all/inscheduling/v1/`);
  }

  createAction(params): Observable<any> {
    return this.http.post<any>(`${this.apiGatewayUrl}/api/vacancy/action/v1/`, params);
  }

  getActionHistoryByCompany(params): Observable<any> {
    return this.http.post<any>(`${this.apiGatewayUrl}/api/vacancy/action/history/v1/`, params);
  }

  formatVacancyTitle(vacancy): string {
    return vacancy.type === 'group_private_vacancy' || vacancy.type === 'private_vacancy' ? vacancy.isEducationCourse ? vacancy.office + ' (Curso oculto)' : vacancy.office + ' (Vaga oculta)' : vacancy.type === 'internal_recruitment' ? vacancy.office + ' (Recrutamento Interno)' : vacancy.office;
  }

  setHeading(route, vacancy, root, isTalentBase = false) {
    return route.data.pipe(take(1))
      .subscribe((data: { heading: any; }) => {
        if (!isTalentBase) {
          data.heading.title = `${root === 'courses' ? 'Cursos' : 'Vagas'}`;
          data.heading.subtitle = `${this.formatVacancyTitle(vacancy)}`;
        } else {
          data.heading.subtitle = vacancy.office;
        }
      });
  }
}
