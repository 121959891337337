import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IdleDetectService {
  private idleDetector: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private timeoutDetector: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private isSessionExpired = false;
  private isTimeout = false;
  private idleTimer = null;
  private timeoutTimer = null;
  private idleTimeInMilliSeconds = 0;
  private timeoutInMilliSeconds = 0;
  private stopWatch = false;

  constructor(

  ) { }

  setTimer(idleTimeInSeconds: number, timeoutTimeInSeconds: number) {
    this.idleTimeInMilliSeconds = idleTimeInSeconds * 1000;
    this.timeoutInMilliSeconds = timeoutTimeInSeconds * 1000;
  }

  startTimer() {
    this.stopWatch = false;
    this.isSessionExpired = false;

    this.idleTimer = setTimeout(() => {
      this.isSessionExpired = true;
      this.idleDetector.next(this.isSessionExpired);
    }, (this.idleTimeInMilliSeconds));
  }

  onTimeout() {
    this.stopWatch = false;
    this.isTimeout = false;

    this.timeoutTimer = setTimeout(() => {
      this.isTimeout = true;
      this.timeoutDetector.next(this.isTimeout);
    }, (this.timeoutInMilliSeconds));
  }

  resetTimer() {
    if (!this.stopWatch && !this.isSessionExpired) {
      this.isSessionExpired = false;
      clearTimeout(this.idleTimer);
      this.startTimer();
    }
  }

  resetTimeout() {
    if (!this.stopWatch && !this.isTimeout) {
      this.isSessionExpired = false;
      this.isTimeout = false;
      clearTimeout(this.timeoutTimer);
    }
  }

  stopTimer() {
    this.stopWatch = true;
    clearTimeout(this.idleTimer);
    clearTimeout(this.timeoutTimer);
  }

  watcherIdle() {
    return this.idleDetector.asObservable();
  }

  watcherTimeout() {
    return this.timeoutDetector.asObservable();
  }
}
