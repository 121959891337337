import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'candidateStatusPipe'
})
export class CandidateStatusPipe implements PipeTransform {

  transform(candidate: any, args?: any): any {
    if(!candidate.inMatch) {
      return 'Não deu match'
    }
    if(candidate.inProcess && candidate.applied && !candidate.dispensed) {
      return 'No processo'
    }
    if(candidate.dispensed) {
      return 'Fora do processo' 
    }
    if(!candidate.applied && candidate.inMatch) {
      return 'Match'
    }
    return null;
  }

}
