import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatName'
})
export class FormatNamePipe implements PipeTransform {

  transform(value: any, ...args): any {
    if(!value) return 'Não informado'
    const fullName = args.reduce((acc, atual) => acc += `${value[atual]} `, '');
    return fullName;
  }

}
