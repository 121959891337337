import { Pipe, PipeTransform } from '@angular/core';

import CONSTANTS from '../../core/constants';

@Pipe({
  name: 'personality'
})
export class PersonalityPipe implements PipeTransform {
  PERSONALITIES = CONSTANTS.PERSONALITIES;

  transform(personalityType: String): any {
    if (!personalityType) {
      return personalityType;
    }

    return this.PERSONALITIES.find(personality => personality.type === personalityType).title;
  }
}
