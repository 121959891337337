import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import CustomEncoder from '../helpers/custom-encoder';

// Model's
import { EmployeeResearchQuestions } from '../models/employee-research';

@Injectable()
export class EmployeesTestsService {
  url: string = environment.url;

  constructor(
    private http: HttpClient
  ) { }

  getCollaboratorTests(employeeTestId: string, testType: string, params = {}): Observable<EmployeeResearchQuestions> {
    let httpParams = new HttpParams({ encoder: new CustomEncoder() });

    for (const key of Object.keys(params)) {
      httpParams = httpParams.append(key, params[key] instanceof Object ? JSON.stringify(params[key]) : params[key]);
    }

    // tslint:disable-next-line: max-line-length
    return this.http.get<EmployeeResearchQuestions>(`${this.url}/public/employees-tests/${employeeTestId}/test/${testType}`, { params: httpParams });
  }

  patchCollaboratorTests(employeeTestId: string, params = {}): Observable<any> {
    return this.http.patch<any>(`${this.url}/public/employees-tests/${employeeTestId}/test`, params);
  }
}
