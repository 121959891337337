export const environment = {
  production: false,
  url: 'https://api.sandbox-taqe.com/v2',
  api_gateway: 'https://api-gateway.sandbox-taqe.com',
  landinpage: 'https://vagas.sandbox-taqe.com',
  web_link: 'https://vitrine.sandbox-taqe.com',
  ws_url: 'https://ms-socket.sandbox-taqe.com',
  manager_type: 'taqe',
  url_redirect: {
    web_web_v2: 'https://gerenciador.sandbox-taqe.com',
    web_web_v2_lite: 'https://vitrine.sandbox-taqe.com',
  },
  hotjar_id: '1665051',
  pwa_url: 'https://app.sandbox-taqe.com',
  ip_url: 'https://app-ip.sandbox-taqe.com',
  mock_vacancy_step_id: '5a1109341f8773001',
  MAPS_API_KEY: 'AIzaSyBGU9uctwX3wHeiWlN3_fvht30dJ1qsq58',
};
