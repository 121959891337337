import { Pipe, PipeTransform } from '@angular/core';
import CONSTANTS from '../constants';

@Pipe({
  name: 'schoolingLevel'
})
export class SchoolingLevelPipe implements PipeTransform {
  SCHOOLING_LEVELS = CONSTANTS.SCHOOLING_LEVELS;

  transform(level: string): string {
    return this.SCHOOLING_LEVELS.find(constLevel => constLevel.value === level).name;
  }
}
