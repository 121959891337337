import { Pipe, PipeTransform } from '@angular/core';
import { SupplementaryQuestion } from '../models';

@Pipe({
  name: 'onlyCheckableQuestions'
})
export class OnlyCheckableQuestionsPipe implements PipeTransform {
  transform(supplementaryQuestions: SupplementaryQuestion[]): any {
    if (!supplementaryQuestions) {
      return supplementaryQuestions;
    }

    // tslint:disable-next-line:max-line-length
    return supplementaryQuestions.filter(question => question.type === 'radio' || question.type === 'check' || question.type === 'dropdown');
  }
}
