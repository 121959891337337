import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replace'
})
export class ReplacePipePipe implements PipeTransform {

  transform(item: any, replace, replacement): any {
    if(item === null) return "";
    item = item.replace(',', '.');
    const index = item.lastIndexOf('.');

    let format = item.split('');
    format.splice(index, 1, ',');
    return format.join('');
  }

}
