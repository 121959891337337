import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { AuthService } from '../services/auth.service';
import { StorageService } from '../services';

@Injectable()
export class NotAuthenticatedGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
    private storage: StorageService,
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    if (this.authService.isAuthenticated() && !!this.storage.getUser() && !!this.storage.getCompany()) {
      if(this.storage.getUser().isSantanderFeedBack){
        this.router.navigate(
          ['privateFeedback/vacancies/5cd07ed77ebed60023b56603/list-candidates/5d151569a9b6e4001a4b03b0/filters/5e7237c56504fe0019c2f9f4/feedback-by-step'],
          {
            queryParams: {token: '47C17F9OOSGARQNCHB52E8CBCD27637720WPFAE6CE10A1953FA6A8A659994635'}
          }
        );
      }else{
        this.router.navigate(['/']);
      }
      return false;
    }

    return true;
  }
}
