import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterAction'
})
export class FiltersActionPipe implements PipeTransform {

  filtersAction = {
    move_candidate: 'Mover',
    favorite_candidate: 'Favoritar',
    status_candidate: 'Gerenciar status',
    dispense_candidate: 'Dispensar'
  };

  transform(value: string): string {
    return this.filtersAction[value];
  }
}
