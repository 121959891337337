import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from './../../../environments/environment';
import { Deeplink } from '../models/deeplink';

@Injectable()
export class DeeplinkService {
  url: string = environment.url;

  constructor(
    private http: HttpClient
  ) { }

  getVacancyDeeplink(params): Observable<Deeplink[]> {
    return this.http.get<Deeplink[]>(`${this.url}/deeplinks`, {params: params});
  }

  postVacancyDeeplink(vacancyId, params = {}): Observable<Deeplink[]> {
    return this.http.post<Deeplink[]>(`${this.url}/deeplinks/${vacancyId}`, params);
  }
}
