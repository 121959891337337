import { ConfirmModalComponent } from './../confirm-modal/confirm-modal.component';
import { Component, Input, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Observable, forkJoin } from 'rxjs';

import { VacancyCandidatesService } from '../../vacancy-candidates.service';
import { VacancyCandidate, Process, Step, Vacancy } from '../../../core/models';
import { FilterByParamsPipe } from '../../../core/pipes/filter-by-params.pipe';
import { NotificationsService } from '../../../core/services/notifications.service';
import { VacanciesService } from '../../vacancies.service';
import { take } from 'rxjs/operators';
import { CandidateListService } from '../../candidates-list/candidates-list.service';
import { NgxHotjarService } from 'ngx-hotjar';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
import { UserService } from '../../../core/services';

@Component({
  selector: 'app-approve-candidate-modal',
  templateUrl: './approve-candidate-modal.component.html',
  styleUrls: ['./approve-candidate-modal.component.scss']
})

export class ApproveCandidateModalComponent implements OnInit {
  @Input() candidate: VacancyCandidate;
  @Input() candidates: VacancyCandidate[];
  @Input() filters: Object;
  @Input() vacancyId: Number;
  @Input() previousStepId: Number;
  @Input() selectedCandidatesCount: Number;
  @Input() blockSteps: boolean;
  @ViewChild('select') selectElement: ElementRef;

  allNotifications;
  notification;
  process: Process;
  vacancy: Vacancy;
  finalizedCandidatesCount: number;
  hasCandidatePreApprovedHere: Boolean;
  vacancyCandidateIds: string[];
  loading: String = 'stopped';
  LENGTH: number;
  params: any;

  // loading: boolean;
  nextStep: Step;
  nextStepId: string;

  message: string;
  scheduleId: string;
  notificate = true;
  observations: string;

  loadSteps: boolean = false;

  constructor(
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private vacancyCandidatesService: VacancyCandidatesService,
    private filterByParamsPipe: FilterByParamsPipe,
    private toastrService: ToastrService,
    private notificationsService: NotificationsService,
    private candidateListService: CandidateListService,
    private vacanciesService: VacanciesService,
    private ref: ChangeDetectorRef,
    protected $hotjar: NgxHotjarService,
    private router: Router,
    public userService: UserService
  ) { }

  ngOnInit() {
    this.$hotjar.virtualPageView(`${environment.web_link}${this.router.url}`);
    this.LENGTH = 50;
    this.loading = 'stopped';
    this.finalizedCandidatesCount = 0;

    this.vacanciesService.getVacancyById(this.vacancyId)
      .pipe(take(1))
      .subscribe((res: Vacancy) => {
        this.vacancy = res;

        this.candidateListService.setVacancyProcessSteps(this.vacancy);
        this.vacancy.process.steps = this.vacancy.process.steps.filter((step: any) => String(step.key) !== 'general' && String(step.key) !== 'matches');

        this.vacancy.process.steps = this.vacanciesService.setStepsValidForShowCase(this.vacancy.process.steps);

        this.process = this.vacancy.process;

        if (this.candidates) {
          // tslint:disable-next-line:max-line-length
          this.nextStepId = this.process.steps[this.process.steps.findIndex(step => String(step._id) === String(this.candidates[0].currentProcessStepId)) + 1]._id;

          const vacancyCandidateIds = [];
          for (const candidate of this.candidates) {
            vacancyCandidateIds.push(candidate._id);
          }
          this.vacancyCandidateIds = vacancyCandidateIds;
        }

        // tslint:disable-next-line:max-line-length
        this.nextStep = this.process.steps.find(step => step._id === this.nextStepId);
        if (this.blockSteps) {
          this.process.steps = [this.nextStep];
        }
        this.loadSteps = true;
        this.filterGroupedSchedules();

        this.notificationsService.getVacancyNewAllNotificatons(this.vacancy._id)
          .subscribe((notifications: any) => {
            this.allNotifications = notifications.steps;

            const selectElement = this.selectElement.nativeElement;
            this.setMessagesModalNotifications(selectElement);
          });

        this.ref.markForCheck();
      });
  }

  getVacancyCandidateStepIndex(nextStepId: string): number {
    // tslint:disable-next-line:max-line-length
    return this.process.steps.findIndex(step => String(step._id) === String(nextStepId ? nextStepId : this.candidates[0].currentProcessStepId));
  }

  onNextStepChange(selectElement) {
    this.setMessagesModalNotifications(selectElement);

    delete this.scheduleId;
    // tslint:disable-next-line:max-line-length
    this.nextStep = Object.assign({}, this.process.steps.find(step => String(step._id) === String(this.nextStepId)));
    this.filterGroupedSchedules();
  }

  setMessagesModalNotifications(selectElement) {
    const selectedOption = selectElement.selectedOptions[0];

    const notificationSelected = this.allNotifications.find(element => String(element.stepId) === String(selectedOption.value));

    if (notificationSelected) {
      this.notification = notificationSelected;
    } else {
      this.notification = this.allNotifications.find(element => String(element.key) === String(selectedOption.dataset.step));
    }
  }

  filterGroupedSchedules() {
    if (!this.nextStep.schedules || !this.nextStep.schedules.length) {
      return;
    }

    this.nextStep.groupedSchedules = this.filterByParamsPipe.transform((this.nextStep.groupedSchedules || []), {
      pastDates: false
    });

    this.nextStep.groupedSchedules.forEach(group => {
      group.schedules = this.filterByParamsPipe.transform(group.schedules, {
        isPastDate: false
      });

      if (!group.schedules || group.schedules.length === 0) {
        const groupIndex = this.nextStep.groupedSchedules.findIndex(_group => _group === group);
        this.nextStep.groupedSchedules.splice(groupIndex, 1);
      }
    });
  }

  approveCandidates() {
    if (this.loading === 'running') {
      return;
    }

    if (this.nextStep.key === 'scheduling' && !this.scheduleId) {
      this.toastrService.clear();
      this.toastrService.info('Selecione uma data do agendamento para prosseguir.', 'Agendamento não declarado!');
      return;
    }

    if (this.nextStep.key === 'partnership' && (!this.nextStep.partnerships || !this.nextStep.partnerships.redacaoOnline) && !this.nextStep.partnerships.redacaoOnline.essayThemeId) {
      this.toastrService.clear();
      this.toastrService.info('Não é permitido a mudança do candidato para a etapa de redação!', 'Aguarde a configuração desta etapa.');
      return;
    }

    // if (this.filters) {
    //   const params = {
    //     vacancyId: this.vacancyId,
    //     filters: this.filters
    //   };

    //   this.vacancyCandidatesService.getVacancyCandidatesIds(params)
    //     .subscribe(results => {
    //       this.vacancyCandidateIds = results;
    //       this.repeatApprove();
    //     });
    // } else {
    this.repeatApprove();
    // }
  }

  getUserFullName(user: any) {
    return this.userService.getUserFullName(user);
  }

  getUserFirstName(user: any) {
    return this.userService.getUserFirstName(user);
  }

  getUserLastName(user: any) {
    return this.userService.getUserLastName(user);
  }

  async repeatApprove() {
    const requests: Observable<any>[] = [];
    this.loading = 'running';
    let index = 0;
    do {
      const vacancyCandidateIds = Object.assign([], this.vacancyCandidateIds.splice(0, this.vacancyCandidateIds.length <
        this.LENGTH ?
        this.vacancyCandidateIds.length :
        this.LENGTH)
      );

      let candidatesCount, maxCandidatesCount;
      if (this.nextStep.key === 'scheduling') {
        this.nextStep.groupedSchedules.map(group => group.schedules.map(schedule => {
          if (schedule._id === this.scheduleId) {
            candidatesCount = schedule.candidatesCount;
            maxCandidatesCount = schedule.maxCandidatesCount;
          }
        }));
      }

      this.params = {
        previousStepId: this.previousStepId,
        nextStepId: this.nextStepId,
        observations: this.observations,
        notificate: this.notificate,
        vacancyCandidateIds: vacancyCandidateIds,
        filters: this.filters,
        vacancyId: this.vacancyId,
        scheduleId: this.nextStep.key === 'scheduling' ? this.scheduleId : undefined,
        candidatesCount: candidatesCount,
        maxCandidatesCount: maxCandidatesCount,
      };

      // VALIDAÇÃO DE TESTE NÃO REALIZADO
      const conditions = [
        'test_initialized',
        'test_pending',
        'writing_request_sent',
        'video_request_sended'
      ];

      let keyCandidate = 'no_key';
      for (const key in this.candidates[index].status[0]) {
        keyCandidate = this.candidates[index].status[0].key;
      }

      if (conditions.some(el => el.includes(keyCandidate))) {
        let nameProcess = '';
        this.process.steps.map((step, index) => {
          if (index === this.getVacancyCandidateStepIndex(undefined)) {
            nameProcess = step.name;
          }
        });

        const modal = this.modalService.open(ConfirmModalComponent, { size: 'lg' });
        modal.componentInstance.htmlTitle = 'Atenção!';
        modal.componentInstance.htmlMessage = `Você está movimentando candidatos que ainda não finalizaram a etapa de ${nameProcess}. Deseja continuar mesmo assim?`;
        await modal.result.then(res => {
          if (res == true) {
            requests.push(
              this.approve(this.params)
            );
          }
        }).catch(err => { });
      } else {
        requests.push(
          this.approve(this.params)
        );
      }
      index++;
    } while (this.vacancyCandidateIds.length > 0);

    forkJoin(requests)
      .subscribe(results => {
        this.loading = 'finalized';
        if (this.candidates.length === 1) {
          this.toastrService.clear();
          this.toastrService.success('O candidato <b>' + this.userService.getUserFullName(this.candidates[0].user) + '</b> foi aprovado para a próxima etapa.', 'Candidato aprovados!', {
            enableHtml: true
          });
        } else {
          this.toastrService.clear();
          this.toastrService.success('Os candidatos foram aprovados para a próxima etapa.', 'Candidatos aprovados!');
        }
        this.closeModal();
      });
  }

  approve(params) {
    return Observable.create(observer => {
      this.vacancyCandidatesService.bulkApproveVacancyCandidateNextStep(params)
        .subscribe(response => {
          if (!this.filters) {
            for (const data of response) {
              if (data.response) {
                this.candidates.find(candidate => candidate._id === data._id).currentProcessStepId = this.nextStepId;
              }
            }
          } else {
            this.finalizedCandidatesCount += response.length;
          }

          observer.next();
          observer.complete();
        }, error => {
          if (error.error.code === 7) {
            this.toastrService.error('Número de candidatos excede o limite do agendamento escolhido.');
          } else {
            observer.error(error);
          }
        });
    });
  }

  closeModal() {
    if (this.loading === 'finalized' || this.loading === 'running') {
      this.activeModal.close(this.params);
    } else {
      this.activeModal.close(false);
    }
  }
}
