import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: [ './modal.component.scss' ],
  encapsulation: ViewEncapsulation.None
})

export class ModalComponent implements OnChanges {

  @Input() modalTitle: string;
  @Input() secondaryColor: string;
  @Input() modalResult;
  @Input() buttonText;
  @Input() isButtonEnabled: boolean;
  @Input() isButtonHidden: boolean;
  @Input() closeButton?: boolean = true;
  @Output() submitEvent = new EventEmitter();

  constructor(private modal: NgbActiveModal) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.modalResult && changes.modalResult.currentValue && !changes.modalResult.previousValue) {
      this.closeModal();
    }
  }

  closeModal() {
    // Evita que o erro ViewDestroyedError seja lançado
    setTimeout(() => this.modal.close(this.modalResult), 0);
  }

  emitSubmitEvent() {
    this.submitEvent.emit(this.modalResult);
  }

}
