import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'translateSkill'
})

export class TranslateSkillPipe implements PipeTransform {

  translatedSkills = {
    portuguese: 'Português',
    logic: 'Lógica',
    english: 'Inglês',
    generalKnowledge: 'Conhecimentos Gerais'
  };

  transform(skill: string): string {
    return this.translatedSkills[skill];
  }

}
