import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterByParamsPipe',
  pure: false
})
export class FilterByParamsPipe implements PipeTransform {
  transform(items: any[], params: Object): any {
    if (!items || !params) {
      return items;
    }

    Object.keys(params).forEach(key => {
      items = items.filter(item => item[key] === params[key]);
    });

    return items;
  }
}
