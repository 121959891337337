import { Component, ViewChildren, QueryList, OnChanges, Input } from '@angular/core';
import { Vacancy, VacancyCandidate } from '../../../core/models';
import CONSTANTS from '../../../core/constants/index';
import { StorageService, UserService } from '../../../core/services';
import { environment } from '../../../../environments/environment';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-modal-messages-notification',
  templateUrl: './modal-messages-notification.component.html',
  styleUrls: ['./modal-messages-notification.component.scss']
})

export class ModalMessagesNotificationComponent implements OnChanges {
  public isTalentBase: boolean = true;

  @Input() candidate: VacancyCandidate;
  @Input() candidates: VacancyCandidate[];
  @Input() vacancy: Vacancy;
  @Input() notification;
  @ViewChildren('collapsedInputs') collapsedInputs: QueryList<any>;
  collapsedInputOpen;
  hasNoNotification = false;

  titleEmail: string;
  bodyEmail: string;
  titlePush: string;
  bodyPush: string;
  titleInApp: string;
  imageInAppMessage: string;
  titleInAppMessage: string;
  bodyInAppMessage: string;

  COMPANY_PRIVATE = CONSTANTS.COMPANY_PRIVATE;

  constructor(
    private sanitizer: DomSanitizer,
    private UserService: UserService,
    private localStorage: StorageService,
  ) { }

  ngOnChanges() {
    if (this.notification) {
      if (Object.prototype.toString.call(this.notification) === '[object Array]') {
        return this.hasNoNotification = true;
      }

      const notificationDefault = this.notification.default || {};
      this.hasNoNotification = false;
      let application = this.notification.application || notificationDefault.application;
      let email = this.notification.email || notificationDefault.email;

      if (email && email.activated && email.defaultContent && notificationDefault.email) {
        email = notificationDefault.email;
      }

      if (application && application.activated && application.defaultContent && notificationDefault.application) {
        application = notificationDefault.application;
      }

      this.titleEmail = email && email.activated ? this.replaceTextVariables(email.title) : '';
      this.bodyEmail = email && email.activated ? this.replaceTextVariables(email.body) : '';

      this.titlePush = application && application.activated && application.push ? this.replaceTextVariables(application.push.title) : '';
      this.bodyPush = application && application.activated && application.push ? this.replaceTextVariables(application.push.body) : '';

      this.titleInApp = application && application.activated && application.inApp ? this.replaceTextVariables(application.inApp.title) : '';

      this.imageInAppMessage = application && application.activated && application.inAppMessage && application.inAppMessage.activated ? this.replaceTextVariables(application.inAppMessage.image) : '';
      this.titleInAppMessage = application && application.activated && application.inAppMessage && application.inAppMessage.activated ? this.replaceTextVariables(application.inAppMessage.title) : '';
      this.bodyInAppMessage = application && application.activated && application.inAppMessage && application.inAppMessage.activated ? this.replaceTextVariables(application.inAppMessage.body) : '';
    }
  }

  replaceTextVariables(text: string) {
    let userName, vacancyName, companyName, showcaseCompanyName = 'Empresa empregadora';

    if (this.candidate || (this.candidates && this.candidates.length === 1)) {
      // userName = this.candidate ? this.candidate.user.basicInformation.fullName : this.candidates[0].user.basicInformation.fullName;
      userName = this.candidate ? this.UserService.getUserFirstName(this.candidate.user) : this.UserService.getUserFirstName(this.candidates[0].user);
      vacancyName = this.vacancy.office;
      companyName = this.vacancy.company.fantasyName;

      if (!this.vacancy.visibilityCompany) {
        companyName = this.COMPANY_PRIVATE.FANTASY_NAME;
      }
    }

    if (this.candidates && this.candidates.length > 1) {
      userName = 'NOME DO CANDIDATO';
      vacancyName = 'NOME DA VAGA';
      companyName = 'NOME DA EMPRESA';
    }

    // Etapa: Se o gerenciador estiver no fluxo vitrine, deve exibir o nome da empresa logada no lugar de show case.
    if (this.isTalentBase) {
      showcaseCompanyName = this.localStorage.getCompany().fantasyName;
    }

    return text
      .replace(/{{USER_NAME}}/gi, userName)
      .replace(/{{VACANCY_NAME}}/gi, vacancyName)
      .replace(/{{OPPORTUNITY}}/gi, vacancyName)
      .replace(/{{SHOWCASE_COMPANY_NAME}}/gi, showcaseCompanyName)
      .replace(/{{SCHEDULING_DATA}}/gi, 'Data do agendamento')
      .replace(/{{COMPANY_NAME}}/gi, companyName);
  }

  showCollapsedInput(event) {
    if (event.target.nodeName === 'LI') {
      return;
    }

    const element = this.getCollapsedInput(event.target);
    if (element) {
      if (element === this.collapsedInputOpen) {
        element.classList.toggle('opened');
      } else {
        this.hideCollapsedInputs();
        element.classList.add('opened');
        this.collapsedInputOpen = element;
      }
    }
  }

  getCollapsedInput(element) {
    const notificationsType = ['E-mail', 'Push', 'InApp', 'InAppMessage'];
    const index = notificationsType.indexOf(element.childNodes[0].textContent.trim());
    return this.collapsedInputs.map(input => input)[index].nativeElement;
  }

  hideCollapsedInputs() {
    this.collapsedInputs.forEach(input => {
      const element = input.nativeElement;
      if (element.classList.contains('opened')) {
        element.classList.remove('opened');
      }
    });
  }

  formatValueToParam(value: string) {
    let params = [
      { key: 'Candidato', value: 'Candidato', replace: '{{USER_NAME}}', },
      { key: 'Vaga', value: 'Vaga', replace: '{{VACANCY_NAME}}' },
      { key: 'Empresa', value: 'Empresa', replace: '{{COMPANY_NAME}}' },
      { key: 'Etapa', value: 'Etapa', replace: '{{STEP_NAME}}' },
      { key: 'Nome do APP', value: 'Nome do APP', replace: '{{APP_NAME}}' }
    ];

    if (value) {
      params.map(option => {
        let rep = new RegExp(option.replace, 'g');
        value = value.replace(rep, `@${option.value}`);
      });
    }
    return value;
  }

  convertToSafeHtml(text: string) {
    let safeHtml: SafeHtml;
    if (text !== undefined) {
      safeHtml = this.sanitizer.bypassSecurityTrustHtml(
        this.formatValueToParam(text)
      );
    }

    return safeHtml;
  }
}
