import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss']
})
export class BadgeComponent implements OnInit {
  @Input() info: string;
  @Input() float: unknown;

  constructor() {
  }

  ngOnInit() {
    if(!this.info) this.info = 'taqe';
  }

  setValue(value: string): string {
    const map = {
      'integration': 'integração',
      'external': 'externo'
    };
    return map[value] || value;
  }

}
