import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

import { User } from '../models/user';

import {
  IUserResponse,
  IUserDetailsResponse
} from '../interfaces/http-responses';
import CustomEncoder from '../helpers/custom-encoder';

import CONSTANTS from '../../core/constants';

@Injectable()
export class UserService {
  url: string = environment.url;

  constructor(private http: HttpClient) {}

  getUsers(params = {}): Observable<IUserResponse> {
    let httpParams = new HttpParams({ encoder: new CustomEncoder() });

    for (const key of Object.keys(params)) {
      httpParams = httpParams.append(
        key,
        params[key] instanceof Object
          ? JSON.stringify(params[key])
          : params[key]
      );
    }

    return this.http.get<IUserResponse>(`${this.url}/users`, {
      params: httpParams
    });
  }

  getUserById(userId, params = {}): Observable<IUserDetailsResponse> {
    return this.http.get<IUserDetailsResponse>(`${this.url}/users/${userId}`, {
      params: params
    });
  }

  getUserFirstName(user: User) {
    if (!user || !user.basicInformation) {
      return 'Nome não declarado';
    }

    let name = user.basicInformation.name || 'Nome não declarado';

    if (this.getUserIsOfAnotherGender(user)) {
      name = user.basicInformation.socialName.split(' ')[0];
    }

    if (!name || name === '') {
      name = 'Nome não declarado';
    }

    return name;
  }

  getUserLastName(user: User) {
    if (!user || !user.basicInformation) {
      return 'Nome não declarado';
    }

    let lastName = user.basicInformation.lastName || 'Nome não declarado';

    if (this.getUserIsOfAnotherGender(user)) {
      lastName = user.basicInformation.socialName
        .split(' ')
        .slice(1)
        .join(' ');
    }

    if (!lastName || lastName === '') {
      lastName = 'Nome não declarado';
    }

    return lastName;
  }

  getUserFullName(user: User) {
    if (!user || !user.basicInformation) {
      return 'Nome não declarado';
    }

    let fullName = user.basicInformation.fullName;

    if (this.getUserIsOfAnotherGender(user)) {
      fullName = user.basicInformation.socialName;
    }

    if (!fullName || fullName === '') {
      fullName = 'Nome não declarado';
    }

    return fullName;
  }

  getUserIsOfAnotherGender(user: User): Boolean {
    // return false; // Pois ainda não levantamos quais generos possuiem nome social.

    if (!user || !user.basicInformation) {
      return false;
    }

    if (
      !user.basicInformation.socialName ||
      [' ', '-'].includes(user.basicInformation.socialName)
    ) {
      return false;
    }

    if (
      !CONSTANTS.GENDER.binaryGenders.includes(user.basicInformation.gender)
    ) {
      return true;
    }

    return false;
  }
}
