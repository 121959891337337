import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpEvent, HttpInterceptor, HttpErrorResponse, HttpHandler, HttpRequest, HttpHeaders, HttpResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { StorageService } from '../services/storage.service';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor (
    private router: Router,
    private storage: StorageService,
    private authService: AuthService,
    private toastrService: ToastrService,
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authToken = this.storage.getUserToken();
    if (!authToken) { return next.handle(req); }

    const authReq = req.clone({
      setHeaders: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + authToken.access_token,
        'X-Project-Flow': environment.manager_type
      }
    });

    return next.handle(authReq)
      .pipe(
        tap(void 0, responseError => {

          if (!(responseError instanceof HttpErrorResponse)) {
            return;
          }

          if (responseError.status === 401 && this.authService.isAuthenticated()) {
            this.authService.logout()
              .subscribe(() => {
                this.router.navigate(['/auth/signin']);
              });
          }

          if (responseError.status === 500) {
            this.toastrService.clear();
            this.toastrService.error(
              'Nossa! O servidor encontrou uma condição inesperada e ficou sem saber o que fazer.', 'Erro interno do servidor');
          }

          if (responseError.status === 404) {
            this.toastrService.clear();
            this.toastrService.error(
              'O recurso que você tentou acessar não foi encontrado.', 'Erro 404');
          }

          if (responseError.status === 400) {
            if (responseError.error.errorMessage) {
              return;
            } else if (Object.keys(responseError.error.details).length) {
              let message = responseError.error.details.message || 'Informe ao administrador do sistema.';
              let title = responseError.error.details.title || 'Alguma coisa deu errado!';
              let type = responseError.error.details.type || 'error';

              if (!type || !['error', 'info', 'warning'].includes(type)) {
                type = 'error';
              }

              this.toastrService.clear();
              this.toastrService[type](message, title, {
                enableHtml: true
              });
            } else {
              let message;

              this.toastrService.clear();
              switch (responseError.error.code) {
                case 1:
                  message = 'Alguns parâmetros não foram enviados ao servidor.';
                  break;
                case 2:
                  message = 'Alguns parâmetros não podem ser atualizados.';
                  break;
                case 3:
                  message = 'Os parâmetros enviados não são válidos. Experimente recarregar a página.';
                  break;
                case 5:
                  message = 'As APIs de terceiros não estão funcionando.';
                  break;
                case 6:
                  message = 'Não foi possível criar o que foi solicitado.';
                  break;
              }

              this.toastrService.error(message || 'Informe ao administrador do sistema.', 'Alguma coisa deu errado!');
            }
          }

          if (responseError.status === 403) {
            let message;
            switch (responseError.error.code) {
              case 4:
                message = 'Apenas super administradores podem executar esta ação!';
                this.router.navigate(['/']);
                break;

            }

            this.toastrService.clear();
            this.toastrService.error(message || 'Informe ao administrador do sistema.', 'Você não tem permissão!');
          }

          if (responseError.status === 0) {
            this.toastrService.clear();
            this.toastrService.error(
              'Não foi possível completar a requisição. Pode ser que o TAQE esteja em manutenção ou você esteja sem conexão.',
              'Servidor inalcançável');
          }
        })
      );
  }
}
