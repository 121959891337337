import { Component, OnInit, OnDestroy} from '@angular/core';
import { NgcCookieConsentService, NgcInitializeEvent, NgcNoCookieLawEvent, NgcStatusChangeEvent } from 'ngx-cookieconsent';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-notice-cookies',
  templateUrl: './notice-cookies.component.html',
  styleUrls: ['./notice-cookies.component.scss']
})
export class NoticeCookiesComponent implements OnInit, OnDestroy {
  private popupOpenSubscription: Subscription;
  private popupCloseSubscription: Subscription;
  private initializeSubscription: Subscription;
  private statusChangeSubscription: Subscription;
  private noCookieLawSubscription: Subscription;

  constructor(private cookieService: NgcCookieConsentService) { }

  ngOnInit() {
    this.cookieService.close(false);
    this.popupOpenSubscription = this.cookieService.popupOpen$.subscribe(
      () => {
        this.cookieService.getConfig()
        // you can use this.cookieService.getConfig() to do stuff...
      });

      this.popupCloseSubscription = this.cookieService.popupClose$.subscribe(
        () => {
          this.cookieService.getConfig()
          // you can use this.cookieService.getConfig() to do stuff...
        });
  
      this.initializeSubscription = this.cookieService.initialize$.subscribe(
        (event: NgcInitializeEvent) => {
          this.cookieService.getConfig()
          // you can use this.cookieService.getConfig() to do stuff...
        });
  
      this.statusChangeSubscription = this.cookieService.statusChange$.subscribe(
        (event: NgcStatusChangeEvent) => {
          this.cookieService.getConfig()
          // you can use this.cookieService.getConfig() to do stuff...
        });
  
     
  
        this.noCookieLawSubscription = this.cookieService.noCookieLaw$.subscribe(
        (event: NgcNoCookieLawEvent) => {
          this.cookieService.getConfig()
          // you can use this.cookieService.getConfig() to do stuff...
        });
  }

  
  ngOnDestroy() {
    // unsubscribe to cookieconsent observables to prevent memory leaks
    this.popupOpenSubscription.unsubscribe();
    this.popupCloseSubscription.unsubscribe();
    this.initializeSubscription.unsubscribe();
    this.statusChangeSubscription.unsubscribe();
    this.noCookieLawSubscription.unsubscribe();
  }

}
