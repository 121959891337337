import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { ChartsModule } from "ng2-charts";
import { NgSelectModule } from '@ng-select/ng-select';
import { AgmCoreModule } from "@agm/core";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { TextMaskModule } from "angular2-text-mask";
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { NgxPaginationModule } from "ngx-pagination";
import { ClipboardModule } from 'ngx-clipboard';
import { FileUploadModule } from "ng2-file-upload";
import { InlineSVGModule } from "ng-inline-svg";
import { SchoolingModalComponent } from "./schooling-modal/schooling-modal.component";
import { ExperienceModalComponent } from "./experience-modal/experience-modal.component";
import { GeolocationCityModalComponent } from "./geolocation-city-modal/geolocation-city-modal.component";
import { HabilitiesModalComponent } from "./habilities-modal/habilities-modal.component";
import { SupplementaryQuestionsModalComponent } from "./supplementary-questions-modal/supplementary-questions-modal.component";
import { PipesModule } from "../../core/pipes/pipes.module";
import { CoreModule } from "../../core/components/core.module";
import { EditSupplementaryQuestionsModalComponent } from "./edit-supplementary-questions-modal/edit-supplementary-questions-modal.component";
import { EditProcessesModalComponent } from "./edit-processes-modal/edit-processes-modal.component";
import { EditStatusModalComponent } from "./edit-status-modal/edit-status-modal.component";
import { MaterialCustomModule } from "../../core/modules/material.module";
import { UserService } from '../../core/services/users.service';
import { AlertChangesComponent } from "./alert-changes/alert-changes.component";
import { ApproveCandidateModalComponent } from "./approve-candidate-modal/approve-candidate-modal.component";
import { ChangeStatusModalComponent } from "./change-status-modal/change-status-modal.component";
import { ConfirmModalComponent } from "./confirm-modal/confirm-modal.component";
import { CreateEventComponent } from "./create-event/create-event.component";
import { DeleteEventComponent } from "./delete-event/delete-event.component";
import { DetailEventComponent } from "./detail-event/detail-event.component";
import { InvitationCandidateModalComponent } from './invitation-candidate-modal/invitation-candidate-modal.component';
import { LocationsFilterModalComponent } from "./locations-filter-modal/locations-filter-modal.component";
import { ReproveCandidateComponent } from './reprove-candidate-modal/reprove-candidate-modal.component';
import { SchedulingCandidatesModalComponent } from './scheduling-candidates-modal/scheduling-candidates-modal.component';
import { SchedulingCandidatesQuantityConfirmationModal } from "./scheduling-candidates-quantity-confirmation-modal/scheduling-candidates-quantity-confirmation-modal.component";
import { ModalMessagesNotificationComponent } from "./modal-messages-notification/modal-messages-notification.component";
import { CreateEventSendInvitationModalComponent } from './create-event-send-invitation-modal/create-event-send-invitation-modal.component';
import { AlertInviteComponent } from './alert-invite/alert-invite.component';
import { SendEmailNotificationModalComponent } from './send-email-notification-modal/send-email-notification-modal.component';

const modals = [
  AlertChangesComponent,
  SchoolingModalComponent,
  ExperienceModalComponent,
  GeolocationCityModalComponent,
  HabilitiesModalComponent,
  SupplementaryQuestionsModalComponent,
  EditSupplementaryQuestionsModalComponent,
  CreateEventComponent,
  DetailEventComponent,
  EditProcessesModalComponent,
  EditStatusModalComponent,
  ChangeStatusModalComponent,
  DeleteEventComponent,
  ApproveCandidateModalComponent,
  LocationsFilterModalComponent,
  ConfirmModalComponent,
  SchedulingCandidatesQuantityConfirmationModal,
  SchedulingCandidatesModalComponent,
  ReproveCandidateComponent,
  InvitationCandidateModalComponent,
  ModalMessagesNotificationComponent,
  CreateEventSendInvitationModalComponent,
  AlertInviteComponent,
  SendEmailNotificationModalComponent
];

@NgModule({
  declarations: [
    modals,
    SchedulingCandidatesModalComponent,
    // BannerModalComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PipesModule,
    NgxPaginationModule,
    AgmCoreModule,
    CoreModule,
    NgbModule,
    TextMaskModule,
    MaterialCustomModule,
    InlineSVGModule.forRoot(),
    FileUploadModule,
    ClipboardModule,
    AngularMultiSelectModule,
    NgSelectModule,
    ChartsModule
  ],
  exports: [
    AlertChangesComponent
  ],
  entryComponents: modals,
  providers: [
    UserService
  ]
})
export class VacancyModalsModule { }
