import { Component, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService, StorageService } from '../services';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertChangesComponent } from '../../vacancies/modal/alert-changes/alert-changes.component';
import { environment } from '../../../environments/environment';
import { ShowcaseService } from '../../showcase/services/showcase.service';
import { HeaderLiteService } from '../../core/header-lite/header-lite.service';

declare function hidden_button(): any;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {
  public appVersion: string = 'v1.0.7';

  options = {
    lang: 'en',
    theme: 'winter',
    settings: false,
    docked: true,
    boxed: false,
    opened: false
  };

  @Output() messageEvent = new EventEmitter<Object>();
  @Output() toggleFullscreen = new EventEmitter<void>();

  @Input() logo: string;
  @Input() secondaryColor: string;

  constructor(
    public translate: TranslateService,
    private authService: AuthService,
    private router: Router,
    private showcaseService: ShowcaseService,
    private storage: StorageService,
  ) {
    const browserLang: string = translate.getBrowserLang();
    translate.use(browserLang.match(/en|pt\-br/) ? browserLang : 'pt-br');
  }

  sendMessage() {
    this.messageEvent.emit(this.options);
  }

  setTheme(theme) {
    this.options.theme = theme;
    this.sendMessage();
  }

  logout(): void {
    this.authService
      .logout()
      .subscribe(response => {
        hidden_button();
        this.router.navigate(['/auth/signin']);
      });

  }

  GotoProcess(process) {
    this.showcaseService.setShowcase('');
    this.storage.setShowCase('');
    this.router.navigate([`/${process}`]);
  }

  getLogo() {
    if (!this.logo) {
      return '../../assets/images/taqe-logo-white.svg';
    }
    return this.logo;
  }

}
