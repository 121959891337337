import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import CONSTANTS from '../../../core/constants';
import { NgxHotjarService } from 'ngx-hotjar';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
@Component({
  selector: 'app-habilities-modal',
  templateUrl: './habilities-modal.component.html',
  styleUrls: [ './habilities-modal.component.scss' ]
})

export class HabilitiesModalComponent implements OnInit {
  SKILLS = CONSTANTS.SKILLS;
  form: FormGroup;
  formGroupNames: { [key: string]: string } = {};
  modalResult;

  @Input() matchmakingObj;
  @Input() update;

  constructor(
    private formBuilder: FormBuilder,
    protected $hotjar: NgxHotjarService,
    public router: Router,
    private toastrService: ToastrService
  ) { }

  ngOnInit() {
    this.$hotjar.virtualPageView(`${environment.web_link}${this.router.url}`);

    this.form = this.formBuilder.group({
      type: ['', Validators.nullValidator]
    });

    if (this.update) {
      this.updateForm();
    }
  }

  addHability(selectElement: HTMLSelectElement) {
    const { selectedOptions, value } = selectElement;
    this.formGroupNames[value] = selectedOptions[0].textContent;

    this.form.addControl(value, this.formBuilder.group({
      minimumGrade: [5, Validators.compose([Validators.required, Validators.min(1), Validators.max(10)])],
      minimumLevel: ['1', Validators.required]
    }));
  }

  removeHability(control) {
    this.form.removeControl(control.key);
    delete this.formGroupNames[control.key];
  }

  updateForm() {
    for (let i of this.matchmakingObj.skills) {
      this.formGroupNames[i.type] = this.SKILLS.filter(skill => skill.key === i.type)[0].value
      this.form.addControl(i.type, this.formBuilder.group({
        minimumGrade: [i.minimumGrade, Validators.compose([Validators.required, Validators.min(1), Validators.max(10)])],
        minimumLevel: [i.minimumLevel, Validators.required]
      }));
    }
  }

  formSubmit() {
    if (!this.form.valid) {
      this.toastrService.clear();
      this.toastrService.info('Tem campos preenchidos de modo errado. por favor revise as habilidades!', 'Revise as habilidades');
      return;
    }

    const formObjects = this.form.value;

    const result = Object.keys(formObjects)
      .filter(item => (typeof formObjects[item]) === 'object')
      .map(item => {
        return ({
          type: item,
          minimumLevel: +formObjects[item].minimumLevel,
          minimumGrade: +formObjects[item].minimumGrade
        })
      });

    const names = result.map(item => this.SKILLS.filter(skill => skill.key === item.type)[0].value);

    this.modalResult = {
      value: names,
      skills: result
    };
  }
}
