import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CreateEventComponent } from '../create-event/create-event.component';
import { DeleteEventComponent } from '../delete-event/delete-event.component';
import { VacanciesService } from '../../vacancies.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserManagerService, StorageService } from '../../../core/services';
import { NgxHotjarService } from 'ngx-hotjar';
import { environment } from '../../../../environments/environment';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-detail-event',
  templateUrl: './detail-event.component.html',
  styleUrls: ['./detail-event.component.scss']
})
export class DetailEventComponent implements OnInit, OnDestroy {
  @Input() eventContent;
  @Input() steps;
  @Input() colors;

  public duplicateModalOpen: boolean = false;
  public hasAuthorizeManagerEnable: boolean = false;

  getUserManagerRequest$: Subscription;
  step: any;
  role: any;

  token = '47C17F9OOSGARQNCHB52E8CBCD27637720WPFAE6CE10A1953FA6A8A659994635';

  constructor(
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    public vacancyService: VacanciesService,
    public router: Router,
    public route: ActivatedRoute,
    private toastrService: ToastrService,
    private userManagerService: UserManagerService,
    protected $hotjar: NgxHotjarService,
    private storage: StorageService,
  ) { }

  ngOnInit() {
    this.$hotjar.virtualPageView(`${environment.web_link}${this.router.url}`);
    if (this.getUserManagerRequest$) {
      this.getUserManagerRequest$.unsubscribe();
      delete this.getUserManagerRequest$;
    }

    this.getUserManagerRequest$ = this.userManagerService.getMe()
      .subscribe(responseUser => {
        this.role = responseUser.role;
      });

    for (let s of this.steps) {
      if (s._id === this.eventContent.extendedProps.stepId) {
        this.step = s.name;
      }
    }

    this.hasAuthorizeManagerEnable = this.storage.getCompany().canAuthorizeManagers || false;
  }

  ngOnDestroy() {
    if (this.getUserManagerRequest$) {
      this.getUserManagerRequest$.unsubscribe();
      delete this.getUserManagerRequest$;
    }
  }

  updateEventModal(ev) {
    let isNewEvent = { newEvent: false };

    const modal = this.modalService.open(CreateEventComponent, { backdrop: 'static', keyboard: false, size: 'lg' });
    modal.componentInstance.eventObj = Object.assign(ev, isNewEvent);
    modal.componentInstance.steps = this.steps;

    modal.result.then(res => {
      this.activeModal.close(res);
    }).catch(err => { });
  }

  duplicateEventModal(ev) {
    this.duplicateModalOpen = true;
    let isNewEvent = { newEvent: true };

    const modal = this.modalService.open(CreateEventComponent, { backdrop: 'static', keyboard: false, size: 'lg' });
    modal.componentInstance.eventObj = Object.assign(ev, isNewEvent);
    modal.componentInstance.steps = this.steps;

    modal.result.then(res => {
      this.duplicateModalOpen = false;

      if (res) {
        this.activeModal.close(res);
      }
    }).catch(err => {
      this.duplicateModalOpen = false;
    });
  }

  deleteEventModal(ev) {
    const modal = this.modalService.open(DeleteEventComponent, { backdrop: 'static', keyboard: false, size: 'lg' });
    modal.componentInstance.step = ev;

    modal.result.then(res => {
      this.activeModal.close(res);
      this.toastrService.clear();
      if (res == true) {
        this.toastrService.success(
          `O agendamento ${ev.extendedProps.name} foi removido com sucesso!`,
          "Agendamento Removido"
        );
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
      // this.router.navigate([`${this.route.snapshot['_routerState'].url}`]);
    }).catch(err => { });
  }

  close() {
    this.activeModal.close();
  }

  openScheduleCandidates() {
    this.router.navigate([`/vacancies/${this.eventContent.extendedProps.vacancyId}/candidates-list`])
      .then(success => this.activeModal.close(null));
  }
}
