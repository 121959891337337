import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FilterService {

  constructor () {}
  private company = new BehaviorSubject<object>({});
  company$ = this.company.asObservable();

  setCompany(company) {
    return this.company.next(company);
  }
}
