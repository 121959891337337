import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-scheduling-candidates-quantity-confirmation-modal',
  templateUrl: './scheduling-candidates-quantity-confirmation-modal.component.html',
  styleUrls: [ './scheduling-candidates-quantity-confirmation-modal.component.scss' ]
})
export class SchedulingCandidatesQuantityConfirmationModal implements OnInit {

  @Input() candidatesCount: number;
  @Input() availablePlaces: number;
  @Input() comparison: number;
  form: FormGroup;

  constructor(
    private activeModal: NgbActiveModal
  ) {}

  ngOnInit() {
    this.form = new FormGroup({});
  }

  formSubmit() {
    this.activeModal.close(true);
  }

}