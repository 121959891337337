import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html'
})

export class ConfirmModalComponent implements OnInit {
  @Input() htmlTitle: String;
  @Input() htmlMessage: String;

  constructor(
    public activeModal: NgbActiveModal,
  ) { }

  ngOnInit() {

  }

  closeModal(action: boolean) {
    this.activeModal.close(action);
  }
}
