import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'limitAffinity'
})
export class LimitAffinityPipe implements PipeTransform {

  transform(score: number): any {
    if (!score) {
      return 0;
    }

    return parseFloat(score.toString()).toFixed(1);
  }
}
