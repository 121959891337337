import { Component, OnInit, AfterViewInit, ElementRef } from '@angular/core';
import { MatIconRegistry } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-incompatible-browser',
  templateUrl: './incompatible-browser.component.html',
  styleUrls: ['./incompatible-browser.component.scss']
})
export class IncompatibleBrowserComponent implements OnInit, AfterViewInit {

  constructor(private elementRef: ElementRef, iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    iconRegistry.addSvgIcon(
      'chrome',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/chrome.svg')
    );
    iconRegistry.addSvgIcon(
      'firefox',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/firefox.svg')
    );
  }
  ngOnInit() {
  }

  ngAfterViewInit() {
    this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor = 'white';
  }
}
