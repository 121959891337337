export class SupplementaryQuestion {
    _id?: string;
    topic?: string;
    type?: string;
    rule?: string;
    asking?: string;
    alternatives?: Alternatives[];
    alternativesDeleted?: Alternatives[];
    description?: string;
    initial?: number;
    final?: number;
    initOption?: string;
    finalOption?: string;
    image?: string;
    video?: string;
    deleted?: boolean;
    active? = true;
}

interface Alternatives {
    _id?: string;
    position?: number;
    text?: string;
    rule?: boolean;
    checked?: boolean;
}
