import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { AuthService, ShowcaseService } from '../core/services';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-invite',
  templateUrl: './invite.component.html',
  styleUrls: ['./invite.component.scss']
})
export class InviteComponent implements OnInit {

  public token: string;
  public companyKey: string;
  public test: boolean;

  constructor (
    private authService: AuthService,
    private toastrService: ToastrService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private showcaseService: ShowcaseService,
    private loadingBar: LoadingBarService,
  ) { }

  ngOnInit() {
    this.token = this.activatedRoute.snapshot.queryParams.token;
    this.test = this.activatedRoute.snapshot.queryParams.test ? this.activatedRoute.snapshot.queryParams.test : false;
    this.companyKey = this.activatedRoute.snapshot.params.companyKey;
    localStorage.setItem('url', `${environment.web_link}/${this.companyKey}`);
    this.validateInvite();
  }

  validateInvite() {
    this.loadingBar.start();
    this.showcaseService.getInvite(this.token)
      .subscribe(invite => {
        this.loadingBar.complete();
        this.companyKey = invite.from.key;

        if (invite.pending && invite.user.invitation.pending) {

          // Etapa: verifica se o usuário é teste
          if (this.test) {
            this.router.navigate([`auth/signup`], {
              queryParams: { token: this.token }, state: {
                toSignup: true,
                user: invite.user,
                companyKey: this.companyKey
              }
            });
          } else {
            this.router.navigate([`auth/showcase-signup/${this.companyKey}`], {
              queryParams: { token: this.token }, state: {
                toSignup: true,
                user: invite.user,
                validByEmail: invite.validByEmail
              }
            });
          }
        } else {
          if (!this.authService.isAuthenticated()) {
            this.toastrService.clear();
            this.toastrService.info('Faça login para continuar.', 'Convite ativo!');
          }
          this.router.navigateByUrl(`/${this.companyKey}`);
        }

      }, error => {
        this.toastrService.clear();
        this.toastrService.error('O link que você tentou acessar é inválido ou já expirou.', 'O link expirou!');
        this.router.navigateByUrl('/');
      });
  }
}
