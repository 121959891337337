import { Pipe, PipeTransform } from '@angular/core';

import CONSTANTS from '../constants';

@Pipe({
  name: 'stepKeyPipe'
})
export class StepKeyPipe implements PipeTransform {

    STEP_KEYS = CONSTANTS.STEP_KEYS;

    transform(value: string): string {
        // tslint:disable-next-line:max-line-length
        return this.STEP_KEYS.find(step => String(step.key) === String(value)) ? this.STEP_KEYS.find(step => String(step.key) === String(value)).name : value;
    }
}
