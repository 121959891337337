import { Component, Input, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Observable, forkJoin } from 'rxjs';

import { VacancyCandidatesService } from '../../vacancy-candidates.service';
import { VacancyCandidate, Process, Step, Vacancy } from '../../../core/models';
import { FilterByParamsPipe } from '../../../core/pipes/filter-by-params.pipe';
import { NotificationsService } from '../../../core/services/notifications.service';
import { VacanciesService } from '../../vacancies.service';
import { take } from 'rxjs/operators';
import { CandidateListService } from '../../candidates-list/candidates-list.service';
import { NgxHotjarService } from 'ngx-hotjar';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { CompaniesService, StorageService, UserService } from '../../../core/services';
import { CreateEventSendInvitationModalComponent } from '../create-event-send-invitation-modal/create-event-send-invitation-modal.component';
import { AlertInviteComponent } from '../alert-invite/alert-invite.component';

@Component({
  selector: 'app-invitation-candidate-modal',
  templateUrl: './invitation-candidate-modal.component.html',
  styleUrls: ['./invitation-candidate-modal.component.scss']
})

export class InvitationCandidateModalComponent implements OnInit {
  @Input() candidate: VacancyCandidate;
  @Input() candidates: VacancyCandidate[];
  @Input() filters: Object;
  @Input() vacancyId: Number;
  @Input() previousStepId: Number;
  @Input() selectedCandidatesCount: Number;
  @ViewChild('select') selectElement: ElementRef;

  public modalCreateScheduleOpen: boolean = false;
  allNotifications;
  notification;
  process: Process;
  vacancy: Vacancy;
  finalizedCandidatesCount: number;
  hasCandidatePreApprovedHere: Boolean;
  vacancyCandidateIds: string[];
  loading: String = 'stopped';
  LENGTH: number;
  params: any;
  invitation: number = 0;

  // loading: boolean;
  nextStep: Step;
  nextStepId: string;

  message: string;
  scheduleId: string;
  notificate = true;

  form: FormGroup;
  dateObject: any = {};
  private userManager: any;
  public isApproveCandidate: boolean = false;
  private loadInfos: boolean = true;
  public insufficientInvitations: boolean = false;

  sendWhatsappNotification: boolean = false;
  quantityWhatsappNotificationsAvailable: number = 0;
  canSendWhatsappNotifications: boolean = false;

  constructor(
    public activeModal: NgbActiveModal,
    private vacancyCandidatesService: VacancyCandidatesService,
    private filterByParamsPipe: FilterByParamsPipe,
    private toastrService: ToastrService,
    private notificationsService: NotificationsService,
    private candidateListService: CandidateListService,
    private vacanciesService: VacanciesService,
    private ref: ChangeDetectorRef,
    protected $hotjar: NgxHotjarService,
    private router: Router,
    private formBuilder: FormBuilder,
    private storage: StorageService,
    private modalService: NgbModal,
    private userService: UserService,
    private companiesService: CompaniesService,
  ) { }

  ngOnInit() {
    this.$hotjar.virtualPageView(`${environment.web_link}${this.router.url}`);
    this.LENGTH = 50;
    this.loading = 'stopped';
    this.finalizedCandidatesCount = 0;
    this.userManager = this.storage.getUser();
    this.getInvite();

    const showcase = this.storage.getShowCase();
    this.companiesService.getCompanyWhatsappNotificationsQuantity({
      showcase: showcase._id
    }).subscribe(company => {
      const value = company.whatsappNotifications.total;
      this.canSendWhatsappNotifications = company.canSendWhatsappNotifications || false;
      this.quantityWhatsappNotificationsAvailable = company.canSendWhatsappNotifications ? value : 0;
      if(!(this.candidates && this.candidates.length <= value) || this.quantityWhatsappNotificationsAvailable === 0) {
        this.sendWhatsappNotification = false;
      }
    });

    this.vacanciesService.getVacanyTalentBaseById(this.vacancyId)
      .pipe(take(1))
      .subscribe((res: Vacancy) => {
        this.vacancy = res;

        this.candidateListService.setVacancyProcessSteps(this.vacancy);
        this.process = this.vacancy.process;

        // tslint:disable-next-line:max-line-length
        this.nextStep = this.process.steps.find(step => String(step.key) === 'scheduling' && String(step.flow) === 'showcase');
        this.nextStepId = this.nextStep._id;

        this.filterGroupedSchedules();

        if (this.candidates) {
          const vacancyCandidateIds = [];
          for (const candidate of this.candidates) {
            vacancyCandidateIds.push(candidate._id);
          }

          this.vacancyCandidateIds = vacancyCandidateIds;
        }

        this.notificationsService.getVacancyNewAllNotificatons(this.vacancy._id, {
          group: ['custom']
        })
          .subscribe((notification: any) => {
            this.allNotifications = notification.custom;
            const notifications = this.allNotifications.filter(notification => notification.key === 'scheduling_invitations_showcase');

            if (notifications && notifications.length) {
              this.notification = notifications[0];
            }
          });

        this.ref.markForCheck();
        this.loadInfos = false;
      });
  }

  private getInvite() {
    if (this.userManager.company) {
      this.companiesService.getShowcaseCompanyById(String(this.userManager.company))
        .subscribe((result: any) => {
          if (result && result.showcases && result.showcases.length) {
            const showcase = result.showcases
              .find(item => String(item.showcaseCompany) === String(this.vacancy.company._id));

            if (showcase.bronzeInvitation) {
              this.invitation = showcase.bronzeInvitation;
            }
          }
        }, error => error);
    }
  }

  private filterGroupedSchedules(): void {
    if (!this.nextStep.schedules || !this.nextStep.schedules.length) {
      return;
    }

    this.nextStep.groupedSchedules = this.filterByParamsPipe.transform((this.nextStep.groupedSchedules || []), {
      pastDates: false
    });

    this.nextStep.groupedSchedules.forEach(group => {
      group.schedules = this.filterByParamsPipe.transform(group.schedules, {
        isPastDate: false
      });

      if (!group.schedules || group.schedules.length === 0) {
        const groupIndex = this.nextStep.groupedSchedules.findIndex(_group => _group === group);
        this.nextStep.groupedSchedules.splice(groupIndex, 1);
      }

      // Etapa: Se não houver ID de uma empresa atrelada ao agendamento ou se o ID for igual ao do usuário logado, permite visualizar o mesmo.
      group.schedules = group.schedules.filter((element: any) => {
        if ((element.active && String(this.userManager.role) === 'SUPER_ADMIN') || (element.active && !element.company) || (element.active && String(element.company) === String(this.userManager.company))) {
          return element;
        }
      });
    });
  }

  createSchedule(): void {
    if (!this.invitation) {
      const modal = this.modalService.open(AlertInviteComponent, {
        size: 'lg'
      });
      const company: any = JSON.parse(localStorage.getItem('company'));
      let companyName = '';
      if (company.showcases) {
        const showcase = company.showcases
          .find(item => String(item.showcaseCompany._id) === String(this.vacancy.company._id));
        if (showcase && showcase.showcaseCompany && showcase.showcaseCompany.fantasyName) {
          companyName = showcase.showcaseCompany.fantasyName;
        }
      }
      modal.componentInstance.companyName = companyName;

      modal.result.then(result => {
        if (result) {
          this.activeModal.close({ createSchedule: true });
        }

        this.modalCreateScheduleOpen = false;
      }).catch(err => {
        this.modalCreateScheduleOpen = false;
      });
      return;
    }

    if (this.loading === 'running') {
      return;
    }

    this.modalCreateScheduleOpen = true;
    const modal = this.modalService.open(CreateEventSendInvitationModalComponent, {
      size: 'lg'
    });

    modal.componentInstance.vacancyId = this.vacancyId;
    modal.componentInstance.showcaseSchedulingStep = this.process.steps.find((step: any) => String(step.key) === 'scheduling' && String(step.flow) === 'showcase');
    modal.componentInstance.previousStepId = this.previousStepId;
    modal.componentInstance.nextStepId = this.nextStepId;
    modal.componentInstance.filters = this.filters;
    modal.componentInstance.vacancyCandidateIds = this.vacancyCandidateIds;

    modal.result.then(result => {
      if (result) {
        this.activeModal.close(result);
      }

      this.modalCreateScheduleOpen = false;
    }).catch(err => {
      this.modalCreateScheduleOpen = false;
    });
  }

  approveCandidates() {
    // Etapa: Se os convites forem insuficiente.
    if (this.insufficientInvitations) {
      return;
    }

    if (!this.nextStep) {
      return;
    }

    if (this.nextStep.key === 'scheduling' && !this.scheduleId) {
      this.toastrService.clear();
      this.toastrService.info('Selecione uma data do agendamento para prosseguir.', 'Agendamento não declarado!');
      return;
    }

    if (this.isApproveCandidate || this.loadInfos) {
      return;
    }

    this.isApproveCandidate = true;

    this.repeatApprove();
  }

  repeatApprove() {
    const requests: Observable<any>[] = [];
    this.loading = 'running';

    do {
      const vacancyCandidateIds = Object.assign([], this.vacancyCandidateIds.splice(0, this.vacancyCandidateIds.length <
        this.LENGTH ?
        this.vacancyCandidateIds.length :
        this.LENGTH)
      );

      this.params = {
        previousStepId: this.previousStepId,
        nextStepId: this.nextStepId,
        observations: '',
        notificate: this.notificate,
        vacancyCandidateIds: vacancyCandidateIds,
        filters: this.filters,
        vacancyId: this.vacancyId,
        scheduleId: this.scheduleId,
        sendWhatsappNotification: this.sendWhatsappNotification,
      };

      requests.push(
        this.approve(this.params)
      );
    } while (this.vacancyCandidateIds.length > 0);

    forkJoin(requests)
      .subscribe(results => {
        this.loading = 'finalized';

        if (this.candidates.length === 1) {
          this.toastrService.clear();
          this.toastrService.success('O candidato <b>' + this.candidates[0].user.basicInformation.name + '</b> foi convidado para a próxima etapa.', 'Candidato convidado com sucesso!', {
            enableHtml: true
          });
        } else {
          this.toastrService.clear();
          this.toastrService.success('Os candidatos foram convidados para a próxima etapa.', 'Candidatos convidados com sucesso!');
        }
        this.closeModal();
      }, (response: any) => {
        if (response.error && response.error.details && String(response.error.details.message) === 'Convites Insuficientes') {
          this.insufficientInvitations = true;
        }
      });
  }

  approve(params) {
    return Observable.create(observer => {
      this.vacancyCandidatesService.bulkApproveVacancyCandidateNextStep(params)
        .subscribe(response => {
          if (!this.filters) {
            for (const data of response) {
              if (data.response) {
                this.candidates.find(candidate => candidate._id === data._id).currentProcessStepId = this.nextStepId;
              }
            }
          } else {
            this.finalizedCandidatesCount += response.length;
          }

          observer.next();
          observer.complete();
        }, error => observer.error(error));
    });
  }

  closeModal() {
    if (this.loading === 'finalized' || this.loading === 'running') {
      this.activeModal.close(this.params);
    } else {
      this.activeModal.close(false);
    }
  }

  getUserFullName(user: any) {
    const fullName = this.userService.getUserFullName(user);
    return fullName;
  }

}
