import { Component, OnInit, NgZone, QueryList, ViewChildren, AfterViewInit, OnDestroy, Input } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { MapsAPILoader } from '@agm/core';
import CONSTANTS from '../../../core/constants';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { NgxHotjarService } from 'ngx-hotjar';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-geolocation-city-modal',
  templateUrl: './geolocation-city-modal.component.html',
  styleUrls: ['./geolocation-city-modal.component.scss']
})

export class GeolocationCityModalComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChildren('search') searchElement: QueryList<HTMLInputElement>;
  @Input() colors;
  @Input() update;
  currentSearchElement;
  form: FormGroup;
  modalResult;
  currentPlace: any;
  locations = [];
  searchLocationCity: boolean = false;
  addLocationLoading: boolean = false;
  mode: any;
  STATES = CONSTANTS.STATES;
  CITIES = [];
  searchChanges$: Subscription;
  showRemoteOption = false;

  constructor(
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    protected $hotjar: NgxHotjarService,
    public router: Router,
  ) { }

  ngOnInit() {
    this.showRemoteOption = this.router.url.includes('create');
    this.$hotjar.virtualPageView(`${environment.web_link}${this.router.url}`);
    this.form = this.formBuilder.group({
      mode: [null, Validators.required],
      radius: this.formBuilder.group({
        location: ['', Validators.nullValidator],
        kilometers: [null, Validators.nullValidator],
      }),
      state: [null, Validators.nullValidator],
      city: [null, Validators.nullValidator]
    });
  }

  setValidatorsForm(key, state) {
    this.mode = key;

    if (key == 'city') {
      this.form.get('city').setValidators(Validators.required);
      this.form.get('state').setValidators(Validators.required);

      this.form.get('radius.location').setValidators(Validators.nullValidator);
      this.form.get('radius.kilometers').setValidators(Validators.nullValidator);

      this.form.get('radius.location').patchValue(null);
      this.form.get('radius.kilometers').patchValue(null);
    } else if (key == 'state') {
      this.form.get('city').setValidators(Validators.nullValidator);
      this.form.get('state').setValidators(Validators.required);

      this.form.get('radius.location').setValidators(Validators.nullValidator);
      this.form.get('radius.kilometers').setValidators(Validators.nullValidator);


      this.form.get('state').patchValue(null);
      this.form.get('city').patchValue(null);
      this.form.get('radius.location').patchValue(null);
      this.form.get('radius.kilometers').patchValue(null);
    }
    else if (key == 'radius') {
      this.form.get('city').setValidators(Validators.nullValidator);
      this.form.get('state').setValidators(Validators.nullValidator);

      this.form.get('city').patchValue(null);
      this.form.get('state').patchValue(null);

      this.form.get('radius.location').setValidators(Validators.required);
      this.form.get('radius.kilometers').setValidators([Validators.required, Validators.max(150)]);
    } else {
      this.form.get('city').setValidators(Validators.nullValidator);
      this.form.get('state').setValidators(Validators.nullValidator);
      this.form.get('radius.location').setValidators(Validators.nullValidator);
      this.form.get('radius.kilometers').setValidators(Validators.nullValidator);

      this.form.get('city').patchValue(null);
      this.form.get('state').patchValue(null);
      this.form.get('radius.location').patchValue(null);
      this.form.get('radius.kilometers').patchValue(null);
    }

    if (state == 'change') this.locations = [];

    this.form.get('city').updateValueAndValidity();
    this.form.get('state').updateValueAndValidity();
    this.form.get('radius.location').updateValueAndValidity();
    this.form.get('radius.kilometers').updateValueAndValidity();
  }

  ngAfterViewInit() {
    this.searchChanges$ = this.searchElement.changes
      .subscribe(changes => {
        if (changes.first) {
          this.currentSearchElement = changes.first;
          this.setMapsAPIConfigByRadius();
        }
      });
  }

  showInvalidFeedback(fieldPath: string) {
    const control = this.form.get(fieldPath);
    return !control.valid && control.touched;
  }

  ngOnDestroy() {
    this.searchChanges$.unsubscribe();
  }

  centerMap(data) {
    this.currentPlace = {
      lat: data.lat,
      lgn: data.lgn
    };
  }

  setMapsAPIConfigByRadius() {
    this.mapsAPILoader.load()
      .then(() => {
        const autocomplete = new google.maps.places.Autocomplete(this.currentSearchElement.nativeElement, {
          componentRestrictions: {
            country: 'br'
          }
        });

        autocomplete.addListener('place_changed', () => {
          this.ngZone.run(() => {
            const place = autocomplete.getPlace();
            if (place && place.geometry && place.geometry.location) {
              this.currentPlace = {
                lat: place.geometry.location.lat(),
                lgn: place.geometry.location.lng()
              };
            }
          });
        });
      });
  }

  searchLocation() {
    let location = null;
    switch (this.form.get('mode').value) {
      case 'city':
        location = this.form.get('city').value;
        break;
      case 'state':
        location = this.form.get('state').value;
        break;
      default:
        location = this.form.get('radius.location').value;
    }

    if (this.form.get('mode').value === 'city' && !this.form.get('city').value) {
      return;
    }

    this.searchLocationCity = true;
    this.mapsAPILoader.load()
      .then(() => {
        new google.maps.Geocoder().geocode({
          address: location,
          componentRestrictions: {
            country: 'br'
          }
        }, (data: google.maps.GeocoderResult[]) => {
          this.searchLocationCity = false;
          this.currentPlace = {
            lat: data[0].geometry.location.lat(),
            lgn: data[0].geometry.location.lng()
          };

          if (this.addLocationLoading) {
            this.addLocation();
          }
        });
      });
  }

  addLocation(): void {
    this.addLocationLoading = true;

    if (this.searchLocationCity) {
      this.toastrService.clear();
      this.toastrService.info('Aguarde enquanto carregamos a localização...', 'Carregando localização');
      return;
    }

    const mode = this.form.get('mode').value;
    this.mode = mode;
    this.addLocationLoading = false;

    if (this.form.get('mode').value == 'radius' && !this.currentPlace) {
      this.form.get(mode).reset();
      this.currentPlace = this.currentPlace || {};
      this.currentPlace.lat = null;
      this.currentPlace.lgn = null;
      this.toastrService.clear();
      this.toastrService.error('Defina a localização pela lista.', 'Erro de localização');
      return;
    }

    let address = null;
    if (this.currentSearchElement && this.currentSearchElement.nativeElement && this.currentSearchElement.nativeElement.value) {
      address = this.currentSearchElement.nativeElement.value;
    } else if (mode == 'city' && !address) {
      address = this.form.get('city').value;
    } else if (mode == 'state' && !address) {
      address = this.form.get('state').value;
    } else if (mode == "radius" && !address) {
      address = this.form.get('radius.location').value;
    }

    this.locations.push({
      address: address,
      lat: this.currentPlace && this.currentPlace.lat ? +this.currentPlace.lat : 0,
      lgn: this.currentPlace && this.currentPlace.lgn ? +this.currentPlace.lgn : 0,
      radius: mode === 'radius' ? this.form.controls.radius.get('kilometers').value : 0,
      city: this.form.get('city') && this.form.get('city').value ? this.form.get('city').value : '',
      state: this.form.get('state') && this.form.get('state').value ? this.form.get('state').value : ''
    });

    this.form.get(mode).reset();
  }

  deleteGeolocation(locantion) {
    // Verifica se o item e o card são iguais, e guarda na lista os que não foram iguais.
    this.locations = this.locations.filter(item => !_.isEqual(item, locantion));
  }

  formSubmit() {
    const names = this.locations.map(location => location.address);
    this.modalResult = {
      value: names,
      geolocations: {
        type: this.form.get('mode').value,
        locations: this.locations
      }
    };
  }

  modelChanged(state) {
    this.CITIES = this.STATES.filter(states => states.name === state)[0].cities;
  }

  changeRadius() {
    if (Number(this.form.controls.radius.value.kilometers) <= 0) {
      this.form.controls.radius.get('kilometers').patchValue(null);
    }
  }

}
