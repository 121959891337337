import { Pipe, PipeTransform } from '@angular/core';
import CONSTANTS from '../constants';

@Pipe({
  name: 'supplementaryQuestionsType'
})
export class SupplementaryQuestionsTypePipe implements PipeTransform {

    SUPPLEMENTARY_QUESTION_TYPES = CONSTANTS.SUPPLEMENTARY_QUESTION_TYPES;

    transform(value: string): string {
        // tslint:disable-next-line:max-line-length
        return this.SUPPLEMENTARY_QUESTION_TYPES.find(type => String(type.key) === String(value)) ? this.SUPPLEMENTARY_QUESTION_TYPES.find(type => String(type.key) === String(value)).name : value;
    }
}

@Pipe({
  name: 'questionType'
})
export class QuestionTypePipe implements PipeTransform {

  questionsTranslation = {
    elimination: [ 'Eliminatória', 'Eliminatórias' ],
    classification: [ 'Classificatória', 'Classificatórias' ],
    informative: [ 'Informativa', 'Informativas' ]
  };

  transform(question: string, quantity: string): string {
    return quantity === 'plural' ? this.questionsTranslation[question][1] : this.questionsTranslation[question][0];
  }

}

@Pipe({
  name: 'answerType'
})
export class AnswerTypePipe implements PipeTransform {

  answersTranslation = {
    text: 'Texto livre',
    check: 'Múltiplas escolhas',
    radio: 'Única escolha'
  };

  transform(answer: string): string {
    return this.answersTranslation[answer];
  }

}
