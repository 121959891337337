import { Directive, HostListener } from '@angular/core';

import { IdleDetectService } from '../services/idle-detect.service';

@Directive({
  selector: 'div[idleDetect]'
})
export class IdleDetectDirective {

  constructor(
    private idleDetectService: IdleDetectService
  ) { }

  @HostListener('keydown', ['$event'])
  onKeydown(event) {
    this.idleDetectService.resetTimer();
  }

  @HostListener('mousemove', ['$event'])
  onMove(event) {
    this.idleDetectService.resetTimer();
  }

  @HostListener('click', ['$event'])
  onClick(event) {
    this.idleDetectService.resetTimer();
  }

  @HostListener('contextmenu', ['$event'])
  onContextMenuClick(event) {
    this.idleDetectService.resetTimer();
  }

  @HostListener('wheel', ['$event'])
  onWheelClick(event) {
    this.idleDetectService.resetTimer();
  }

}
