import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { FormArray, FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Subject, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, merge, filter } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';
import { Step } from '../../../core/models/vacancy';
import { StorageService } from '../../../core/services';
import CONSTANTS from '../../../core/constants';
import { NgxHotjarService } from 'ngx-hotjar';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-edit-status-modal',
  templateUrl: './edit-status-modal.component.html',
})

export class EditStatusModalComponent implements OnInit {
  @ViewChild('instance') instance: NgbTypeahead;
  @Input() update: Boolean;
  @Input() index: number;
  @Input() step: Step;
  @Input() status: any;

  focus = {
    htmlOrderedList$: new Subject<string>(),
  };
  click = {
    htmlOrderedList$: new Subject<string>(),
  };

  form: FormGroup;
  STEP_KEYS = CONSTANTS.STEP_KEYS;
  HTML_ORDERED_LIST = [];
  updateArray = {};

  constructor(
    public activeModal: NgbActiveModal,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder,
    private storage: StorageService,
    private modalService: NgbModal,
    private translateService: TranslateService,
    protected $hotjar: NgxHotjarService,
    public router: Router,
  ) { }

  ngOnInit() {
    this.$hotjar.virtualPageView(`${environment.web_link}${this.router.url}`);
    this.loadFormControls();
  }

  loadFormControls() {
    this.form = this.formBuilder.group({
      status: this.formBuilder.group({
        type: [this.status && this.status.type ? this.status.type : undefined, Validators.required],
        key: [this.status && this.status.key ? this.status.key : undefined, Validators.nullValidator],
        name: [this.status && this.status.name ? this.status.name : undefined, Validators.required],
        color: [this.status && this.status.color ? this.status.color : undefined, Validators.required],
      }),
    });

    if (this.form.get('status.type').value) {
      this.form.markAsTouched();
    }
  }

  buildArrayStatusInProcess() {

  }

  buildArrayStatusNotInProcess() {
    let arr;

    // tslint:disable-next-line:max-line-length
    if (this.status && this.status.notInProcess && this.status.notInProcess.length > 0) {
      arr = this.status.notInProcess.map(item => {
        return this.formBuilder.group({
          name: [],
          key: [],
          color: [],
          substatus: [],
        });
      });
    }

    return arr ? this.formBuilder.array(arr) : this.formBuilder.array([]);
  }

  loadFormValidators() {
    const inputHtmlOrderedList = this.form.get('step.video.instructions.inputHtmlOrderedList');
    const maxDuration = this.form.get('step.video.maxDuration');

    if (this.form.get('step.key').value === 'video') {
      inputHtmlOrderedList.setValidators([Validators.required]);
      maxDuration.setValidators([Validators.required]);

      // tslint:disable-next-line:max-line-length
      if (!this.form.get('step.video.instructions.htmlOrderedList').value || this.form.get('step.video.instructions.htmlOrderedList').value.length === 0) {
        inputHtmlOrderedList.setValue(undefined);
      } else {
        inputHtmlOrderedList.setValidators([Validators.nullValidator]);
      }

      if (this.form.get('step.video.maxDuration').value > 0) {
        maxDuration.setValidators([Validators.nullValidator]);
      } else {
        maxDuration.setValue(undefined);
      }
    } else {
      inputHtmlOrderedList.setValidators([Validators.nullValidator]);
    }

    maxDuration.updateValueAndValidity();
    inputHtmlOrderedList.updateValueAndValidity();
  }

  onFormSubmit() {
    // this.loadFormValidators();

    if (this.form.valid) {

      if (!this.form.touched) {
        return;
      }

      if (this.update) {
        this.closeModal(true, 'update');
      } else {
        this.closeModal(true, 'create');
      }
    } else {
      this.toastrService.clear();
      this.toastrService.error('Existem campos obrigatórios não preenchidos no formulário!', 'Campos obrigatórios não preenchidos!');
      this.validateAllFormFields(this.form);
    }
  }

  onFormDelete() {
    const inProcess = this.form.get('step.candidatesCount.inProcess').value;
    const notInProcess = this.form.get('step.candidatesCount.notInProcess').value;

    const modal = this.modalService.open(ConfirmModalComponent, { backdrop: 'static', keyboard: false, windowClass: 'myCustomModalClass' });
    modal.componentInstance.htmlTitle = 'Deletar etapa do processo';
    // tslint:disable-next-line:max-line-length
    modal.componentInstance.htmlMessage = `<p>${this.storage.getUser().firstName} tem certeza em deletar esta etapa do processo seletivo?<br><br> - ${inProcess > 0 ? inProcess >= 2 ? inProcess + ' candidatos' : inProcess + ' candidato' : 'Nenhum candidato'} em processo.<br> - ${notInProcess > 0 ? notInProcess >= 2 ? notInProcess + ' candidatos' : notInProcess + ' candidato' : 'Nenhum candidato'} fora do processo.<br><br>${inProcess > 0 || notInProcess > 0 ? `<div class="alert alert-danger" role="alert">Todos os candidatos nesta etapa serão movidos para a etapa anterior do processo!</div>` : ''}</p>`;

    modal.result.then(params => {
      if (params) {
        this.closeModal(true, 'delete');
      }
    }).catch(err => {});
  }

  getCleanedAttribute(data) {
    function clearRecursively(object) {
      Object.keys(object).forEach(key => {
        if (object[key] instanceof Object) {

          object[key] = clearRecursively(object[key]);

          if (Object.keys(object[key]).length === 0) {
            delete object[key];
            return;
          }
        }

        if (typeof object[key] !== 'boolean' && (!object[key] || object[key] === 'undefined') && object[key] !== 0) {
          delete object[key];
          return;
        }
      });

      return object;
    }

    data = JSON.parse(JSON.stringify(data));
    return clearRecursively(data);
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  showInvalidFeedback(fieldPath: string) {
    const control = this.form.get(fieldPath);
    return !control.valid && control.touched;
  }

  closeModal(submitted: boolean, action: string) {
    if (submitted) {
      this.activeModal.close({
        step: this.getCleanedAttribute(this.form.getRawValue().step),
        action: action
      });
    } else {
      this.activeModal.close();
    }
  }

  buildArrayHtmlOrderedList() {
    let arr;

    // tslint:disable-next-line:max-line-length
    if (this.step && this.step.video && this.step.video.instructions && this.step.video.instructions.htmlOrderedList && this.step.video.instructions.htmlOrderedList.length > 0) {
      arr = this.step.video.instructions.htmlOrderedList.map(item => {
        return this.formBuilder.control(item);
      });
    }

    return arr ? this.formBuilder.array(arr) : this.formBuilder.array([]);
  }

  searchDropBoxHtmlOrderedList = (text$: Observable<string>) =>
    text$
    .pipe(
      debounceTime(200),
      distinctUntilChanged(),
      merge(this.focus.htmlOrderedList$),
      merge(this.click.htmlOrderedList$.pipe(
        filter(() => !this.instance.isPopupOpen()))
      ),
      map(term => (term === '' ? this.HTML_ORDERED_LIST :
        this.HTML_ORDERED_LIST.filter(data => data.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 10))
    )

  onSelectDropBoxHtmlOrderedList($event, dataInput: HTMLInputElement) {
    $event.preventDefault();
    this.addDropBoxHtmlOrderedList($event.item, dataInput);
  }

  addDropBoxHtmlOrderedList(data, dataInput: HTMLInputElement) {
    const index = this.HTML_ORDERED_LIST.indexOf(data);

    if (index >= 0) {
      this.HTML_ORDERED_LIST.splice(index, 1);
    }

    dataInput.value = '';

    const attribute = this.form.get('step.video.instructions.htmlOrderedList') as FormArray;
    const inputName = this.form.get('step.video.instructions.inputHtmlOrderedList');

    attribute.push(this.formBuilder.control(data));

    inputName.setValidators(Validators.nullValidator);
    inputName.updateValueAndValidity();
    attribute.markAsTouched();
    attribute.updateValueAndValidity();
  }

  editDropBoxItem(index: number) {
    if (!this.updateArray['htmlOrderedList']) {
      this.updateArray['htmlOrderedList'] = [];
    }

    if (!this.updateArray['htmlOrderedList'][index]) {
      this.updateArray['htmlOrderedList'][index] = {};
    }

    // tslint:disable-next-line:max-line-length
    this.updateArray['htmlOrderedList'][index].selected = this.updateArray['htmlOrderedList'][index].selected ? this.updateArray['htmlOrderedList'][index].selected : true;
  }

  saveDropBoxItem(index: number, type: string) {
    const attribute = this.form.get(type) as FormArray;
    const elemento = document.getElementById('htmlOrderedList_' + index) as HTMLInputElement;

    attribute.at(index).setValue(elemento.value);
    attribute.markAsTouched();
    attribute.updateValueAndValidity();
    this.updateArray['htmlOrderedList'][index].selected = false;
  }

  removeDropBoxHtmlOrderedList(item, type) {
    const attribute = this.form.get(type) as FormArray;
    const index = attribute.controls.indexOf(item);

    if (this.updateArray && this.updateArray['htmlOrderedList'] && this.updateArray['htmlOrderedList'][index]) {
      this.updateArray['htmlOrderedList'][index].selected = false;
      this.updateArray['htmlOrderedList'].slice(index, 1);
    }

    this.HTML_ORDERED_LIST.push(item.value);
    attribute.removeAt(index);

    attribute.markAsTouched();
    attribute.updateValueAndValidity();
  }
}
