import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpParameterCodec, HttpUrlEncodingCodec } from '@angular/common/http';
import { Observable } from 'rxjs';

import { VacancyCandidate } from '../core/models';
import { environment } from '../../environments/environment';

import { IVacancyCandidatesResponse } from '../core/interfaces/http-responses';
import CustomEncoder from '../core/helpers/custom-encoder';

@Injectable()
export class VacancyCandidatesService {
  private url: string = environment.url;
  private apiGatewayUrl: string = environment.api_gateway;

  constructor(
    private http: HttpClient
  ) { }

  getVacancyCandidatesIds(params): Observable<Array<string>> {
    let httpParams = new HttpParams({ encoder: new CustomEncoder() });

    for (const key of Object.keys(params)) {
      httpParams = httpParams.append(key, params[key] instanceof Object ? JSON.stringify(params[key]) : params[key]);
    }

    return this.http.get<Array<string>>(`${this.url}/vacancy-candidates/identifiers`, { params: httpParams });
  }

  // getVacancyCandidatesNewList(params): Observable<IVacancyCandidatesResponse> {
  //   let httpParams = new HttpParams({ encoder: new CustomEncoder() });

  //   for (const key of Object.keys(params)) {
  //     httpParams = httpParams.append(key, params[key] instanceof Object ? JSON.stringify(params[key]) : params[key]);
  //   }

  //   return this.http.get<IVacancyCandidatesResponse>(`${this.url}/vacancy-candidates/new-list`, { params: httpParams });
  // }

  getVacancyCandidates(params): Observable<IVacancyCandidatesResponse> {
    let httpParams = new HttpParams({ encoder: new CustomEncoder() });

    for (const key of Object.keys(params)) {
      httpParams = httpParams.append(key, params[key] instanceof Object ? JSON.stringify(params[key]) : params[key]);
    }

    return this.http.get<IVacancyCandidatesResponse>(`${this.apiGatewayUrl}/api/v1/private/vacancy-candidates/${params.vacancyId}`, { params: httpParams });
  }

  getVacancyCandidate(vacancyId: string, vacancyCandidateId: string): Observable<VacancyCandidate> {
    return this.http.get<VacancyCandidate>(`${this.apiGatewayUrl}/api/v1/private/vacancy-candidates/${vacancyId}/${vacancyCandidateId}`);
  }

  updateVacancyCandidate(vacancyCandidateId, params = {}) {
    return this.http.put<any>(`${this.url}/vacancy-candidates/${vacancyCandidateId}/status`, params);
  }

  approveVacancyCandidateNextStep(vacancyCandidateId, params = {}): Observable<any> {
    return this.http.post<any>(`${this.url}/vacancy-candidates/${vacancyCandidateId}/approve-next-step`, params);
  }

  bulkApproveVacancyCandidateNextStep(params = {}): Observable<any> {
    return this.http.post<any>(`${this.url}/vacancy-candidates/approve-next-step`, params);
  }

  bulkComeBackVacancyCandidatepreviousStep(params = {}): Observable<any> {
    return this.http.post<any>(`${this.url}/vacancy-candidates/come-back-previous-step`, params);
  }

  bulkRescheduleVacancyCandidateNextStep(params): Observable<any> {
    return this.http.put<any>(`${this.url}/vacancy-candidates/reschedule`, params);
  }
}
