import { User } from './user';

export class Candidate {
  _id: string;
  basicInformation: {
    name: string,
    lastName: string,
    fullName: string,
    birthday: Date;
    relationship: string,
    nationality: string,
    gender: string,
    levelEducation: string,
    about: string
  };
  indicatedBy?: any;
  inProcess?: any;
  marked: any;
  aboutMe: string;
  photo: string;
  lastInteraction?: Date;
  lastAccess: Date;
  address: {
    street: string,
    number: string,
    complement: string,
    district: string,
    city: string,
    state: string,
    zipCode: string,
    country: string
  };
  contact: {
    email: {
      address: string,
      verified: boolean
    },
    cellphone: {
      number: string;
      whatsApp: boolean,
      verified: boolean
    },
    tellphone: string,
    skype: string
  };
  skills: {
    portuguese: {
      level1: number,
      level2: number,
      level3: number,
      totalAverage: number
    },
    logic: {
      level1: number,
      level2: number,
      level3: number,
      totalAverage: number
    },
    generalKnowledge: {
      level1: number,
      level2: number,
      level3: number,
      totalAverage: number
    },
    english: {
      level1: number,
      level2: number,
      level3: number,
      totalAverage: number
    },
  };
  professionalExperience: any[];
  languages: any[];
  softwares: any[];
  education: any[];
  personality: {
    indicators: any,
    personalityType: any;
    type: string;
  };
  disabilities: any;
  presentationVideo: string;
  concentratedAttentionTest: any;
}
export class VacancyCandidate {
  _id: string;
  user: User; // todo
  rate: number;
  notInterested: boolean;
  marked: any;
  applied: boolean;
  inMultipleProcesses: boolean;
  invited: boolean;
  inProcess: boolean;
  favorite: boolean;
  appliedDate: Date;
  dispensed: boolean;
  supplementaryQuestions: any[]; // todo
  matching: any; // todo
  currentProcessStepId: string;
  history: any[];
  statusCandidate: string;
  status: {
    key?: string,
    substatus?: {
      key: string
    }[],
    observations?: string
  }[];
  currentSchedule?: {
    scheduleId: string,
    date: Date,
    name: string,
    feedbackBySharedLink?: any,
    candidateSchedulingStatus?: any
  };
  testResults: any;
  partnerships?: any;
  newApiPartnerships?: any;
  uploadedVideosURLs: any;
  typingTest: any;
  locomotion?: {
    address?: {
        origin?: string,
        destination?: string,
        distance?: {
            text: string,
            value: number
        },
    },
    transport?: {
        duration?: {
            text: string,
            value: number
        },
        fare?: {
          currency: string,
          text: string,
          value: number
        },
        mode?: string,
    },
    name: string
  };
  cluster: any;

  selected: boolean; // only in front-end
  hasPreApprovedStatus: boolean; // only in front-end

  concentratedAttentionTest: any;
  onlineEssays: any;
}
