import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';
import { forEach } from '@angular/router/src/utils/collection';
import { NgxHotjarService } from 'ngx-hotjar';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-supplementary-questions-modal',
  templateUrl: './supplementary-questions-modal.component.html',
  styleUrls: [ './supplementary-questions-modal.component.scss' ]
})

export class SupplementaryQuestionsModalComponent implements OnInit {

  @Input() vacancyQuestions: Array<any>;
  selectedQuestions = [];
  modalForm: FormGroup;
  isFormValid = false;

  constructor(
    private modal: NgbActiveModal,
    protected $hotjar: NgxHotjarService,
    public router: Router
  ) {}

  ngOnInit() {
    this.$hotjar.virtualPageView(`${environment.web_link}${this.router.url}`);
    const fields = {};
    this.vacancyQuestions.forEach(question => fields[question._id] = new FormControl(false));

    this.modalForm = new FormGroup(fields);
  }

  selectSupplementaryQuestion(question) {
    const hasQuestion = this.selectedQuestions.find(item => item._id === question._id);
    if (!hasQuestion) {
      this.selectedQuestions.push(question);
    } else {
      this.selectedQuestions = this.selectedQuestions.filter(item => item._id !== question._id);
    }
    this.checkFormValidity();
  }

  checkFormValidity() {
    this.isFormValid = Object.keys(this.modalForm.controls).some(key => this.modalForm.get(key).value);
  }

  closeModal(content = []) {
    this.modal.close(content);
  }

  setQuestionsObject() {
    this.selectedQuestions = this.selectedQuestions.map(question => ({
      supplementaryQuestionId: question._id,
      points: 0,
      asking: question.asking,
      alternatives: question.alternatives.map(alternative => ({
        alternativeId: alternative._id,
        rule: null,
        text: alternative.text
      }))
    }));
  }


  addSupplementaryQuestions() {
    if (this.isFormValid) {
      this.setQuestionsObject();
      this.closeModal(this.selectedQuestions);
    }
  }

}