/// <reference types="@types/googlemaps" />

import { Component, Input, OnInit, ViewChild, ElementRef, NgZone } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { MapsAPILoader } from '@agm/core';
import { NgxHotjarService } from 'ngx-hotjar';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';

interface Geolocation {
  radius: number;
  formattedAddress: string;
  coordinates: {
    lat: number,
    lng: number
  };
}
declare var google: any;

@Component({
  selector: 'app-locations-filter-modal',
  templateUrl: './locations-filter-modal.component.html',
  styleUrls: ['./locations-filter-modal.component.scss']
})

export class LocationsFilterModalComponent implements OnInit {
  @ViewChild('search') public searchElement: ElementRef;
  @Input() currentGeolocations: Geolocation[];

  geolocations: Geolocation[];
  address: string;
  radius: number;
  geolocation: google.maps.places.PlaceResult;
  preview: string;

  constructor(
    public activeModal: NgbActiveModal,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private toastrService: ToastrService,
    protected $hotjar: NgxHotjarService,
    public router: Router,
  ) { }

  ngOnInit() {
    this.$hotjar.virtualPageView(`${environment.web_link}${this.router.url}`);
    this.geolocations = this.currentGeolocations ? this.currentGeolocations.slice(0) : [];

    this.mapsAPILoader.load()
      .then(() => {
        const autocomplete = new google.maps.places.Autocomplete(this.searchElement.nativeElement, {
          componentRestrictions: {
            country: 'br'
          }
        });

        autocomplete.addListener('place_changed', () => {
          this.ngZone.run(() => {
            const place = autocomplete.getPlace();

            if (!place.geometry) {
              delete this.geolocation;
              this.toastrService.clear();
              this.toastrService.error('Este endereço não possui uma geolocalização definida.', 'Endereço inválido');
              return;
            }

            this.geolocation = place;

            // Retirar a KEY daqui
            this.preview = 'https://maps.googleapis.com/maps/api/staticmap?zoom=12&size=600x300&markers=color:red|' +
              this.geolocation.formatted_address + '&key=' + environment.MAPS_API_KEY;
          });
        });
      });
  }

  addLocation() {
    if (!this.geolocation || !this.radius) {
      this.toastrService.clear();
      this.toastrService.error('Endereço e raio precisam ser definidos.', 'Faltam alguns parâmetros');
      return;
    }

    this.geolocations.push({
      radius: this.radius,
      formattedAddress: this.geolocation.formatted_address,
      coordinates: {
        lat: this.geolocation.geometry.location.lat(),
        lng: this.geolocation.geometry.location.lng()
      }
    });

    delete this.geolocation;
    delete this.radius;
    delete this.address;
    delete this.preview;
  }

  removeGeolocation(geolocation: Geolocation) {
    const index = this.geolocations.findIndex(_geolocation => _geolocation.formattedAddress === geolocation.formattedAddress);
    this.geolocations.splice(index, 1);
  }

  applyFilters() {
    if (this.geolocations.length > 0) {
      this.toastrService.clear();
      this.toastrService.warning('O filtro de geolocalização torna a filtragem mais lenta. Use com moderação.', 'Atenção!');
    }

    this.activeModal.close(this.geolocations);
  }

}
