import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HeaderLiteService {

  logoSubject = new Subject<string>();
  resourceBaseSubject = new Subject<any>();
  cardsSubject = new Subject<any>();
  planSubject = new Subject<any>();
  userSubject = new Subject<any>();

  private behaviorSubjectUpdateResource = new BehaviorSubject(false);
  public resource = this.behaviorSubjectUpdateResource.asObservable();

  public changeResource(updateResource: boolean): void {
    this.behaviorSubjectUpdateResource.next(updateResource);
  }

  //RESOURCE BASE
  setResourceBase(object){
    this.resourceBaseSubject.next(object);
  }
  onResourceBase(){
    return this.resourceBaseSubject;
  }

  //CARDS
  setCards(array){
    this.cardsSubject.next(array);
  }
  onCards(){
    return this.cardsSubject;
  }

  // CURRENT USER
  setUser(object){
    this.userSubject.next(object);
  }
  onUser(){
    return this.userSubject;
  }

  // CURRENT PLAN
  setPlan(object){
    this.planSubject.next(object);
  }
  onPlan(){
    return this.planSubject;
  }


  // LOGO
  changeLogo(imgPath) {
    this.logoSubject.next(imgPath);
  }
  onChangeLogo() {
    return this.logoSubject;
  }
}
